/* eslint-disable import/no-default-export */
import React from 'react';
import { graphql } from 'gatsby';

import { ContentfulContent } from '@ripperoni/cms';

import { Seo } from 'src/@packdigital/gatsby-theme-ripperoni-marketing/components/Seo/Seo.jsx';

import seoTitle from '../hooks/seoTitle';

const Page = ({ data: { pageContainer, metaImage }, ...props }) => {
  const seoImage =
    metaImage?.primaryImage?.fluid?.srcWebp?.replace(/\/\//g, 'https://') ||
    pageContainer?.pageContent
      ?.find((content) => content.component === 'hero')
      ?.entries?.find((entry) => entry?.primaryImage)
      ?.primaryImage?.fluid?.srcWebp?.replace(/\/\//g, 'https://');

  const { seoTitleReplacements } = seoTitle();
  const allSeoTitleReplacements = seoTitleReplacements.map((a) => a.title);
  const isSeoTitleReplaced = allSeoTitleReplacements.includes(
    pageContainer.metaTitle
  );
  const seoTitleChange = seoTitleReplacements.filter(
    (a) => a.title === pageContainer.metaTitle
  )[0];
  const replacedSeoTitle = seoTitleChange;
  console.log('this is the actualy metaTitle', pageContainer.metaTitle);
  return (
    <>
      <Seo
        pageTitle={
          isSeoTitleReplaced
            ? Object.values(replacedSeoTitle)[1]
            : pageContainer.metaTitle
        }
        logo={seoImage || '/logo.png'}
      />

      <ContentfulContent {...pageContainer} />
    </>
  );
};

Page.propTypes = {};

export default Page;

export const query = graphql`
  query ($handle: String!) {
    pageContainer: contentfulPageContainer(slug: { eq: $handle }) {
      ...ContentfulPageContainer
      __typename
    }
    metaImage: contentfulAtomImage(metaHandle: { eq: $handle }) {
      primaryImage {
        fluid {
          srcWebp
        }
      }
    }
  }
`;
