/**
 * @jsx jsx
 * @prettier
 */
import { forwardRef } from 'react';
import { jsx } from 'theme-ui';
import { motion } from 'framer-motion';

import { Box, Flex, Link, Svg } from '@ripperoni/components';

import { CartToggle } from '@components/CartToggle';
import { NavigationToggle } from '@components/NavigationMobile';

import Logo from '@assets/svgs/logo.svg';

const AnimatedFlex = motion.custom(Flex);

export const NavigationMobile = forwardRef(({ headings, ...props }, ref) => {
  return (
    <Box
      data-comp={NavigationMobile.displayName}
      ref={ref}
      px={8}
      bg='secondary'
      boxShadow='header'
      position='relative'
      zIndex={4}
      {...props}
    >
      <AnimatedFlex as='nav' height='48px' between middle>
        <NavigationToggle headings={headings} />

        <Link to='/' p={0} aria-label='Homepage'>
          <Svg as={Logo} width='154px' />
        </Link>

        <CartToggle />
      </AnimatedFlex>
    </Box>
  );
});

NavigationMobile.displayName = 'Mobile Navigation';
