// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-packdigital-gatsby-theme-ripperoni-account-src-pages-account-jsx": () => import("./../../../node_modules/@packdigital/gatsby-theme-ripperoni-account/src/pages/account.jsx" /* webpackChunkName: "component---node-modules-packdigital-gatsby-theme-ripperoni-account-src-pages-account-jsx" */),
  "component---node-modules-packdigital-gatsby-theme-ripperoni-account-src-pages-login-jsx": () => import("./../../../node_modules/@packdigital/gatsby-theme-ripperoni-account/src/pages/login.jsx" /* webpackChunkName: "component---node-modules-packdigital-gatsby-theme-ripperoni-account-src-pages-login-jsx" */),
  "component---node-modules-packdigital-gatsby-theme-ripperoni-account-src-pages-recover-jsx": () => import("./../../../node_modules/@packdigital/gatsby-theme-ripperoni-account/src/pages/recover.jsx" /* webpackChunkName: "component---node-modules-packdigital-gatsby-theme-ripperoni-account-src-pages-recover-jsx" */),
  "component---node-modules-packdigital-gatsby-theme-ripperoni-account-src-pages-reset-jsx": () => import("./../../../node_modules/@packdigital/gatsby-theme-ripperoni-account/src/pages/reset.jsx" /* webpackChunkName: "component---node-modules-packdigital-gatsby-theme-ripperoni-account-src-pages-reset-jsx" */),
  "component---node-modules-packdigital-gatsby-theme-ripperoni-account-src-pages-signup-jsx": () => import("./../../../node_modules/@packdigital/gatsby-theme-ripperoni-account/src/pages/signup.jsx" /* webpackChunkName: "component---node-modules-packdigital-gatsby-theme-ripperoni-account-src-pages-signup-jsx" */),
  "component---node-modules-packdigital-gatsby-theme-ripperoni-cart-src-pages-cart-jsx": () => import("./../../../node_modules/@packdigital/gatsby-theme-ripperoni-cart/src/pages/cart.jsx" /* webpackChunkName: "component---node-modules-packdigital-gatsby-theme-ripperoni-cart-src-pages-cart-jsx" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-static-jsx": () => import("./../../../src/pages/about-static.jsx" /* webpackChunkName: "component---src-pages-about-static-jsx" */),
  "component---src-pages-blog-jsx": () => import("./../../../src/pages/blog.jsx" /* webpackChunkName: "component---src-pages-blog-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-pages-compare-pants-jsx": () => import("./../../../src/pages/pages/compare-pants.jsx" /* webpackChunkName: "component---src-pages-pages-compare-pants-jsx" */),
  "component---src-pages-pages-digital-catalog-jsx": () => import("./../../../src/pages/pages/digital-catalog.jsx" /* webpackChunkName: "component---src-pages-pages-digital-catalog-jsx" */),
  "component---src-pages-pages-jason-kipnis-static-jsx": () => import("./../../../src/pages/pages/jason-kipnis-static.jsx" /* webpackChunkName: "component---src-pages-pages-jason-kipnis-static-jsx" */),
  "component---src-pages-pages-made-in-peru-public-rec-jsx": () => import("./../../../src/pages/pages/made-in-peru-public-rec.jsx" /* webpackChunkName: "component---src-pages-pages-made-in-peru-public-rec-jsx" */),
  "component---src-pages-pages-reviews-jsx": () => import("./../../../src/pages/pages/reviews.jsx" /* webpackChunkName: "component---src-pages-pages-reviews-jsx" */),
  "component---src-pages-pages-testimonial-jsx": () => import("./../../../src/pages/pages/testimonial.jsx" /* webpackChunkName: "component---src-pages-pages-testimonial-jsx" */),
  "component---src-pages-retailer-jsx": () => import("./../../../src/pages/retailer.jsx" /* webpackChunkName: "component---src-pages-retailer-jsx" */),
  "component---src-pages-store-locator-jsx": () => import("./../../../src/pages/store-locator.jsx" /* webpackChunkName: "component---src-pages-store-locator-jsx" */),
  "component---src-templates-blog-jsx": () => import("./../../../src/templates/blog.jsx" /* webpackChunkName: "component---src-templates-blog-jsx" */),
  "component---src-templates-collections-jsx": () => import("./../../../src/templates/collections.jsx" /* webpackChunkName: "component---src-templates-collections-jsx" */),
  "component---src-templates-pages-jsx": () => import("./../../../src/templates/pages.jsx" /* webpackChunkName: "component---src-templates-pages-jsx" */),
  "component---src-templates-products-jsx": () => import("./../../../src/templates/products.jsx" /* webpackChunkName: "component---src-templates-products-jsx" */)
}

