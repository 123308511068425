import { graphql, useStaticQuery } from 'gatsby';

export default function seoDescription() {
  const data = useStaticQuery(
    graphql`
      query {
        googleSheet {
          SeoDescription {
            title
            newDescription
          }
        }
      }
    `
  );

  const seoDescReplacements = data.googleSheet.SeoDescription.map((node) => {
    const { title, newDescription } = node;
    return {
      title,
      newDescription,
    };
  });
  return {
    seoDescReplacements,
  };
}
