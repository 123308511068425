import React from 'react';
import PropTypes from 'prop-types';

import { Box, Svg } from '@ripperoni/components';

import ArrowRight from '@assets/svgs/arrow-right.svg';


export const NextArrow = (props) => {
  return (
    <Box
      sx={{
        display: ['none', null, 'block'],
        cursor: 'pointer',
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        zIndex: 1,
        right: [5 * -1, null, null, null, null, 10 * -1]
      }}
      {...props}
    >
      <Svg
        as={ArrowRight}
      />
    </Box>
  );
};

NextArrow.propTypes = {

};
