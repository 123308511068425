export const orders = {
  content: {
    p: 0,
    border: t => t.account.borders.default,
    borderColor: t => t.account.colors.border,
  },
  table: {
    headerRow: {
      py: 7,
      px: 10,
      bg: 'gray.0',
      border: 0,
    },
    row: {
      py: 7,
      px: 10,
      borderTop: 'default',
      borderColor: 'gray.1',
    },
  },
};
