/**
 * @prettier
 */
import React from 'react';
import { Container } from 'theme-ui';

import { Box, CmsContent, Flex, Grid } from '@ripperoni/components';

export const BlogBody = ({
  bodyParagraph,
  bodyParagraph2,
  bodyParagraph3,
  bodyParagraph4,
  bodyImageLeft,
  bodyImageRight,
  ...props
}) => {
  return (
    <Container>
      <Flex>
        <CmsContent content={bodyParagraph} />
        <CmsContent content={bodyParagraph2} />
        <Grid
          {...props}
          position='relative'
          gap={0}
          columns={['1fr', null, null, '1fr 1fr']}
          sx={{ '& *': { height: '100%' } }}
        >
          <Box>{bodyImageLeft}</Box>
          <Box>{bodyImageRight}</Box>
        </Grid>
        <CmsContent content={bodyParagraph3} />
        <CmsContent content={bodyParagraph4} />
      </Flex>
    </Container>
  );
};

BlogBody.propTypes = {};

BlogBody.displayName = 'Blog Body';
