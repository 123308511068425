import { graphql, useStaticQuery } from 'gatsby';

export default function isSeasonal() {
  const data = useStaticQuery(
    graphql`
      query {
        googleSheet {
          Seasonal {
            title
            colors
            callout
          }
        }
      }
    `
  );

  const seasonalProducts = data.googleSheet.Seasonal.map((node) => {
    const { title, colors, callout } = node;
    return {
      title,
      colors,
      callout,
    };
  });
  return {
    seasonalProducts,
  };
}
