/* eslint-disable import/no-default-export */
import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import { Container, Divider, Heading, Link, Text } from '@ripperoni/components';
import { ContentfulContent } from '@ripperoni/cms';

const FourZeroFour = (props) => {
  const { shopCategory } = useStaticQuery(StaticQuery);

  return (
    <Container
      data-comp={FourZeroFour.displayName}
      textAlign='center'
      pb='73px'
      px={8}
    >
      <Heading
        variant='heading.h1'
        textAlign='center'
        fontSize={[7, null, null, 9]}
        mb={9}
        mt={13}
      >
        404 PAGE NOT FOUND
      </Heading>

      <Divider horzontial width='100px' mx='auto' color='black' />

      <Text mt={11} mb={13}>
        The page you were looking for does not exist.{' '}
        <Link to='/'>Click here</Link> to continue shopping.
      </Text>

      {/* <ContentfulContent {...shopCategory} /> */}
    </Container>
  );
};

FourZeroFour.displayName = 'FourZeroFour';

FourZeroFour.propTypes = {};

export default FourZeroFour;

const StaticQuery = graphql`
  {
    shopCategory: contentfulMolecule(metaTitle: { eq: "Shop by Category" }) {
      ...ContentfulMolecule
    }
  }
`;
