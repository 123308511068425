/**
 * @prettier
 */
import React from 'react';
import PropTypes from 'prop-types';

import { CmsContent, Flex, Link } from '@ripperoni/components';

export const FavoriteTile = ({
  image,
  title,
  description,
  link,
  className,
}) => {
  return (
    <Flex.Col center maxWidth='370px' width='100%' className={className}>
      <Link to={link && link[0]?.props?.url} sx={{ textDecoration: 'none' }}>
        <CmsContent mb={8} display='block' width='100%' content={image} />

        <CmsContent
          variant='text.h4'
          textAlign={['left', null, null, 'center']}
          mb={3}
          width='100%'
          content={title}
        />

        <CmsContent
          textAlign={['left', null, null, 'center']}
          mb={1}
          width='100%'
          content={description}
        />
      </Link>
    </Flex.Col>
  );
};

FavoriteTile.propTypes = {};

FavoriteTile.displayName = 'Favorite Tile';

export default FavoriteTile;
