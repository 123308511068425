/* eslint-disable react/prop-types */
/* eslint-disable max-lines */
/** @jsx jsx */
import { useEffect, useState } from 'react';
import { jsx } from 'theme-ui';

import { Box, Flex, Text } from '@ripperoni/components';

export const UnitToggle = ({ option1, option2, onToggle }) => {
  const [active, setActive] = useState(false);

  useEffect(() => {
    if (!active) {
      onToggle(option1);
    } else {
      onToggle(option2);
    }
  }, [active]);

  return (
    <Flex middle minWidth='65px'>
      <Text fontSize={[0, null, null, null, 3]}>{option1}</Text>

      <Box
        as='label'
        mx={2}
        sx={{
          display: 'inline-block',
          position: 'relative',
          width: '30px',
          height: '12px',
        }}
        onClick={() => setActive(!active)}
      >
        <Box
          as='span'
          sx={{
            position: 'absolute',
            cursor: 'pointer',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'gray.2',
            borderRadius: '34px',
            transition: '.4s',
            '::before': {
              position: 'absolute',
              borderRadius: '50%',
              content: '""',
              height: '18px',
              width: '18px',
              backgroundColor: 'black',
              transition: '.4s',
              top: '50%',
              transform: !active ? 'translateY(-50%)' : 'translate(12px, -50%)',
            },
          }}
        />
      </Box>

      <Text fontSize={[0, null, null, null, 3]}>{option2}</Text>
    </Flex>
  );
};
