export const forms = {
  address: {
    py: 10,
    px: [7, null, 9, null, 11],
    header: {
      mb: 10,
    },
    firstName: {
      pr: [null, null, null, 4],
    },
    lastName: {
      pl: [null, null, null, 4],
    },
  },
  login: {
    recoverPassword: {
      alignSelf: 'flex-start',
    },
  }
};
