import { graphql, useStaticQuery } from 'gatsby';

export default function storeLocatorData() {
  const data = useStaticQuery(
    graphql`
      query {
        googleSheet {
          Locations {
            name
            address
            address1
            city
            stateProvince
            stateAbbrv
            latitude
            longitude
            zipCode
            normalHrs
            sundayHrs
            phone
            title
          }
        }
      }
    `
  );

  const locations = data.googleSheet.Locations.map((node) => {
    const {
      name,
      address,
      address1,
      city,
      stateProvince,
      stateAbbrv,
      latitude,
      longitude,
      zipCode,
      normalHrs,
      sundayHrs,
      phone,
      title,
    } = node;
    return {
      name,
      address,
      address1,
      city,
      stateProvince,
      stateAbbrv,
      latitude,
      longitude,
      zipCode,
      normalHrs,
      sundayHrs,
      phone,
      title,
    };
  });
  return {
    locations,
  };
}
