/**
 * @prettier
 */
/** @jsx jsx */
import React, { useState } from 'react';
import { jsx } from 'theme-ui';
import { Box, Container, Flex, Heading, Image, } from '@ripperoni/components';
import { Link } from '@ripperoni/components';

const videoHero = {
  loopId: '377952962',
  mainId: '377952962',
};

export const TestimonialVideo = () => {
  const [video, setVideo] = useState(false);

  return (
    <Container.Full bg='gray.0' px={8}>
      <Container py={[11, null, null, 15]} position='relative'>
        <Box>
          <Box position='relative'>
            <Box
              display={!video ? 'block' : 'none'}
              position='absolute'
              top='50%'
              left='50%'
              width='75px'
              transform='translate(-50%, -50%)'
              zIndex={2}
              sx={{
                cursor: 'pointer',
              }}
              onClick={() => setVideo(true)}
            >
              <svg
                id='Layer_1'
                className='something-play-button js-play-vimeo'
                data-iframe-start='iframe-start-0'
                data-iframe-unload='iframe-unload-0'
                data-name='Layer 1'
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 57 57'
              >
                <title>Play Icon</title>
                <path
                  d='M29.125,57.625a28.5,28.5,0,1,1,28.5-28.5A28.5322,28.5322,0,0,1,29.125,57.625Zm0-55a26.5,26.5,0,1,0,26.5,26.5A26.53,26.53,0,0,0,29.125,2.625Z'
                  transform='translate(-0.625 -0.625)'
                  fill='#fff'
                />
                <polygon
                  points='39.458 28.572 30.486 33.752 21.515 38.932 21.515 28.572 21.515 18.212 30.487 23.393 39.458 28.572'
                  fill='#fff'
                />
              </svg>
            </Box>

            <Box
              className='embed-container'
              sx={{
                position: 'relative',
                pb: '52%',
                // height: 0,
                overflow: 'hidden',
                maxWidth: '100%',
                height: 'auto',
                'iframe, object, embed': {
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                },
              }}
            >
              <iframe
                src={`https://player.vimeo.com/video/${videoHero.mainId}`}
                width='100%'
                height='100%'
                frameBorder='0'
                title='A Video'
                webkitallowfullscreen=''
                mozallowfullscreen=''
                allowFullScreen=''
                className='play-when-visible'
                sx={{ display: video ? 'block' : 'none' }}
              />

              <iframe
                src={`https://player.vimeo.com/video/${videoHero.loopId}?api=1;title=0&amp;byline=0&amp;portrait=0&amp;background=1&amp;loop=1&amp;autoplay=1`}
                // width="640"
                // height="360"
                frameBorder='0'
                title='A Video'
                webkitallowfullscreen=''
                mozallowfullscreen=''
                allowFullScreen=''
                sx={{ display: !video ? 'block' : 'none' }}
              />
            </Box>
          </Box>
        </Box>
      </Container>
    </Container.Full>
  );
};

TestimonialVideo.displayName = 'TestimonialVideo';
