/**
 * @jsx jsx
 * @prettier
 */
import { useCallback, useState } from 'react';
import { Input, Label, jsx } from 'theme-ui';
import { subscribe } from 'klaviyo-subscribe';

import { isBrowser } from '@ripperoni/utilities';
import { Button, Flex, Link, Radio, Text } from '@ripperoni/components';

import InstagramIcon from '@images/instagram.svg';
import TwitterIcon from '@images/twitter.svg';
import FacebookIcon from '@images/facebook.svg';

export const FooterEmailSignup = (props) => {
  const [email, setEmail] = useState('');
  const [formResponse, setformResponse] = useState('');

  const handleSubmit = useCallback(async (event) => {
    event.preventDefault();
    const value = event.target.elements.letter.value;
    const customFields = {
      $fields: ['Men/Women'],
      'Men/Women': value,
    };

    if (isBrowser) {
      subscribe('Q3jisN', email, customFields).then(({ data }) => {
        if (!data.is_subscribed) {
          setEmail('');
          setformResponse('success');
        } else if (email === '') {
          setformResponse('empty');
        } else {
          setformResponse('error');
        }
      });
    }
  });

  return (
    <Flex.Col maxWidth='435px' flex='1' {...props}>
      <Text uppercase mb={[4]} color='secondary' fontWeight='600'>
        SIGN UP &amp; STAY CONNECTED
      </Text>
      {/* <Heading.h5 uppercase mb={[6]} color='secondary'>
        SIGN UP &amp; STAY CONNECTED
      </Heading.h5> */}

      <Flex
        as='form'
        email={email}
        onSubmit={handleSubmit}
        mb={[11, null, null, 10]}
        sx={{
          flexDirection: 'column',
        }}
      >
        <Input
          type='email'
          aria-label='Enter your email'
          onChange={(e) => setEmail(e.target.value)}
          placeholder='Enter your email'
          px={[0]}
          py={3}
          sx={{
            maxWidth: [null, null, null, '340px'],
            background: 'transparent',
            borderTop: 'none',
            borderLeft: 'none',
            borderRight: 'none',
            borderBottom: '1px solid #fff',
            borderRadius: 0,
            color: '#fff',
            '&:focus': {
              outline: '1px dotted #212121',
              outline: '5px auto -webkit-focus-ring-color',
            },
            '&:active:focus': {
              outline: 'none',
            },
            '&:hover,&:focus,&:active': {
              borderBottom: '1px solid #fff',
            },
            '&::placeholder': {
              color: '#979797',
            },
          }}
        />
        <Text color='white' mt={[8]}>
          Tell us what you’re interested in:
        </Text>
        <Flex
          mt={[5]}
          sx={{
            maxWidth: [null, null, null, '340px'],
            justifyContent: 'space-between',
          }}
        >
          <Flex
            sx={{
              flexDirection: 'column',
              '& > label': {
                fontSize: 4,
                lineHeight: 1.75,
                fontWeight: 300,
              },
            }}
          >
            <Label color='white'>
              <Radio name='letter' value='Men' defaultChecked={true} /> Men's
            </Label>
            <Label color='white'>
              <Radio name='letter' value='Women' /> Women's
            </Label>
            <Label color='white'>
              <Radio name='letter' value='Men, Women' /> Both
            </Label>
          </Flex>

          {renderButton(formResponse)}
        </Flex>
      </Flex>

      <Flex>
        <Link
          href='https://www.instagram.com/publicrec/'
          target='_blank'
          aria-label='Instagram (opens in a new tab)'
          mr={[5]}
        >
          <InstagramIcon />
        </Link>

        <Link
          href='https://twitter.com/public_rec'
          target='_blank'
          mr={[5]}
          aria-label='Twitter (opens in a new tab)'
        >
          <TwitterIcon />
        </Link>

        <Link
          href='https://www.facebook.com/PublicRecApparel'
          target='_blank'
          mr={[5]}
          aria-label='Facebook (opens in a new tab)'
        >
          <FacebookIcon />
        </Link>
      </Flex>
    </Flex.Col>
  );
};

const renderButton = (formResponse) => {
  let label = 'Submit';
  let sxChanges = {};

  if (formResponse === 'success') {
    label = 'All Set';
    sxChanges = {
      color: '#1D1D1D',
      bg: '#616161',
      border: 'none',
    };
  }
  if (formResponse === 'empty') {
    label = 'Enter Email';
    sxChanges = {
      color: '#FFFFFF',
      bg: '#800000',
      border: 'none',
    };
  }
  if (formResponse === 'error') {
    label = 'Email Exists';
    sxChanges = {
      color: '#FFFFFF',
      bg: '#800000',
      border: 'none',
    };
  }

  return (
    <Button
      py={[3]}
      px={[7]}
      sx={{
        width: '130px',
        height: 40,
        border: '1px solid #fff',
        borderRadius: 20,
        alignSelf: 'flex-end',
        textTransform: 'uppercase',
        fontSize: '13px',
        '&:focus': {
          outline: '1px dotted #212121',
          outline: '5px auto -webkit-focus-ring-color',
        },
        '&:active:focus': {
          outline: 'none',
        },
        '&:hover': {
          bg: '#fff',
          color: '#000',
        },
        ...sxChanges,
      }}
    >
      {label}
      {formResponse === 'success' && (
        <span style={{ padding: '0 4px' }}>
          <svg
            width='14'
            height='11'
            viewBox='0 0 14 11'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M5.55858 6.84678L5.64697 6.93438L5.73496 6.84639L11.6757 0.905629C11.8744 0.706913 12.1913 0.706913 12.3901 0.905629L13.2812 1.79674C13.4799 1.99546 13.4799 2.33712 13.2812 2.53583L6.00374 9.81326C5.80503 10.012 5.46337 10.012 5.26465 9.81326L1.15563 5.70424C0.956913 5.50552 0.956913 5.16386 1.15563 4.96515L2.04674 4.07403C2.24546 3.87532 2.56236 3.87532 2.76108 4.07403L2.76147 4.07442L5.55858 6.84678Z'
              fill='currentColor'
            />
          </svg>
        </span>
      )}
    </Button>
  );
};
