/** @jsx jsx */
import { jsx } from 'theme-ui';

import { Svg } from '@ripperoni/components';

import ArrowRight from '@assets/svgs/angle-right-m.svg';

export const PrevArrow = (props) => {
  return (
    <Svg
      as={ArrowRight}
      {...props}
      sx={{
        width: ['5px', null, null, '9px'],
        transform: 'rotate(180deg)',
        cursor: 'pointer',
        path: {
          fill: 'white',
        },
        g: {
          stroke: 'white',
        },
      }}
    />
  );
};

PrevArrow.propTypes = {};
