/* eslint-disable max-lines */
import React from 'react';
import PropTypes from 'prop-types';

import { Box, Container, Flex, Text } from '@ripperoni/components';

import Dropdown from '@components/Dropdown';
import {
  useCollectionFilter,
  useCollectionProducts,
} from '@components/CollectionContext/CollectionContext.jsx';

import { Breadcrumb } from './Breadcrumb';

export const CollectionFilterDesktop = ({ breadcrumbs }) => {
  const { filters, activeFilters, setActiveFilter, clearFilter } =
    useCollectionFilter();
  const { productCount } = useCollectionProducts();

  return (
    <Container.Full data-comp='CollectionFilterDesktop'>
      <Flex as={Container} middle between={true} p={8} mx='auto'>
        <Flex middle>
          <Text variant='text.h5'>
            <Breadcrumb breadcrumbs={breadcrumbs} />
          </Text>

          <Text
            color='gray.2'
            variant='text.h5'
            fontSize={4}
            fontWeight='light'
            ml={breadcrumbs ? 13 : 0}
          >
            {productCount} Products
          </Text>
        </Flex>

        <Flex middle>
          {filters.map(({ title, options }, index) => {
            return (
              <Dropdown
                title={activeFilters[title] ? activeFilters[title] : title}
                key={index + Math.random()}
              >
                {options.map((value, index) => {
                  const active = activeFilters[title] === value;

                  return (
                    <Flex
                      key={index + Math.random()}
                      between={true}
                      middle
                      mt={index !== 0 ? 4 : 0}
                      sx={{
                        cursor: 'pointer',
                        ':hover [data-comp="filter-value-text"]': {
                          color: 'black',
                        },
                        ':hover [data-comp="filter-value-radio"]': {
                          borderColor: 'black',
                          bg: 'black',
                        },
                      }}
                      onClick={() => {
                        setActiveFilter(title, value);
                        Dropdown.handleClickOutside();
                      }}
                    >
                      <Text
                        color={active ? 'black' : 'gray.2'}
                        textTransform='capitalize'
                        data-comp='filter-value-text'
                      >
                        {value}
                      </Text>

                      <Box
                        width='12px'
                        height='12px'
                        border='thin solid'
                        borderColor={active ? 'black' : 'gray.2'}
                        position='relative'
                        bg={active ? 'black' : 'white'}
                        data-comp='filter-value-radio'
                        sx={{
                          borderRadius: '50%',
                          '::before': {
                            content: '""',
                            borderRadius: '50%',
                            display: 'block',
                            position: 'absolute',
                            width: '6px',
                            height: '6px',
                            bg: 'white',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                          },
                        }}
                      />
                    </Flex>
                  );
                })}

                {activeFilters[title] && (
                  <Flex
                    as={Text}
                    middle
                    center
                    onClick={() => {
                      clearFilter(title);
                      Dropdown.handleClickOutside();
                    }}
                    textAlign='center'
                    mt={3}
                    color='error'
                    sx={{
                      cursor: 'pointer',
                    }}
                    key={index + Math.random()}
                  >
                    Clear
                    <Text fontSize={8} lineHeight={0} display='inline' ml={1}>
                      &times;
                    </Text>
                  </Flex>
                )}
              </Dropdown>
            );
          })}
        </Flex>
      </Flex>
    </Container.Full>
  );
};

CollectionFilterDesktop.propTypes = {};
