import React from 'react';

import { useUIContext } from '@ripperoni/core';
import { Box, Button, Flex, Heading, Svg } from '@ripperoni/components';

import { useCart } from 'src/@packdigital/gatsby-theme-ripperoni-cart/hooks/useCart.js';
import ProgressBar from '@ramonak/react-progress-bar';
import Arrow from 'src/assets/svgs/cart-arrow.svg';

export const CartHeader = (props) => {
  const { toggleCart } = useUIContext();
  const cart = useCart();
  const shippingThreshold = (75 - cart.subtotalPrice)

  const meterLabel = '$'+cart.subtotalPrice

  return (
    <Flex
      data-comp={CartHeader.displayName}
      flexDirection='column'
      px='22px'
      pb='22px'
      pt='15px'
      {...props}
    >
      <Box bg='#F2F2F2' mb='22px'>
        {cart.subtotalPrice >= 75 ? (
          <Box py={6} py={4}>
            <Heading
              textAlign='center'
              fontSize={['14px', '15px', null]}
              py={1}
              px={1}
            >
              You received Free Shipping!
            </Heading>
            <Box px={5}>
              <ProgressBar
                completed={100}
                bgColor='#000000'
                height='15px'
                width=''
                labelAlignment='center'
                labelColor='#ffffff'
                labelSize='10px'
                maxCompleted={75}
                customLabel='$75 Reached!'
              />
            </Box>
          </Box>
        ) : (
          <Box py={6} py={4}>
            <Heading
              textAlign='center'
              fontSize={['14px', '15px', null]}
              py={1}
              px={1}
            >
              ${shippingThreshold} away from Free Shipping!
            </Heading>
              <Box px={5}>
                {cart.subtotalPrice <= 1 ? (
                  <> </>) : (
                    <ProgressBar
                      completed={cart.subtotalPrice}
                      bgColor='#000000'
                      height='15px'
                      width='auto'
                      labelAlignment='center'
                      labelColor='#ffffff'
                      labelSize='10px'
                      maxCompleted={75}
                      customLabel={meterLabel}
                    />)}
            </Box>
          </Box>
        )}
      </Box>
      <Flex>
        <Button.Plain onClick={toggleCart}>
          <Svg as={Arrow} height='18px' width='18px' />
        </Button.Plain>

        <Heading m='0 auto'>Your Shopping Bag</Heading>
      </Flex>
    </Flex>
  );
};

CartHeader.displayName = 'Cart Header';
