/**
 * @prettier
 */
import React from 'react';

import { Box, Grid } from '@ripperoni/components';

export const WomenswearText = ({
  womensTextLeft,
  womensTextRight,
  ...props
}) => {
  return (
    <Grid
      {...props}
      position='relative'
      gap={[1, null, null, 10]}
      columns={['1fr', null, null, '1fr 1fr']}
      mb={[12, null, null, 10]}
      mt={[2, null, null, 46]}
    >
      <Box position='relative' my={[20, null, null, 32]}>
        <Box
          // bg={'#b4b63e'}
          px={[10, null, null, 2]}
          position='relative'
          bottom={['auto', null, null, -10]}
          left={['auto', null, null, 0]}
        >
          {womensTextLeft}
        </Box>
      </Box>
      <Box mt={[0, null, null, 0]} px={[10, null, null, 0]}>
        {womensTextRight}
      </Box>
    </Grid>
  );
};

WomenswearText.propTypes = {};

WomenswearText.displayName = 'Womenswear Text';
