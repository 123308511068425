/**
 * @prettier
 */
import React from 'react';
import { Container } from 'theme-ui';

import { Box, CmsContent, Flex, Grid, Heading } from '@ripperoni/components';

export const BlogStart = ({
  introParagraph,
  introImageLeft,
  introImageRight,
  firstSection,
  secondSection,
  ...props
}) => {
  return (
    <Container>
      <Flex>
        <Heading>{introParagraph}</Heading>
        <Grid
          {...props}
          position='relative'
          gap={0}
          columns={['1fr', null, null, '1fr 1fr']}
          sx={{ '& *': { height: '100%' } }}
        >
          <Box>{introImageLeft}</Box>
          <Box>{introImageRight}</Box>
        </Grid>
        <CmsContent content={firstSection} />
        <CmsContent content={secondSection} />
      </Flex>
    </Container>
  );
};

BlogStart.propTypes = {};

BlogStart.displayName = 'Blog Start';
