import React, { useMemo } from 'react';
import { Helmet } from 'react-helmet';

import { isBrowser } from '@ripperoni/utilities';

// Refreshes widgets if script is already injected or injects the yotpo
// script (which automatically refreshes widgets on load)
const useYotpo = () => {
  if (!isBrowser) {
    return {
      YotpoLoaded: false,
      MaybeInjectYoptoScript: null,
    };
  }

  return useMemo(() => {
    if (window.yotpo) {
      window.yotpo.userSettings.load_only_widgets_on_page = true;
      if (typeof window.yotpo.refreshWidgets === 'function')
        window.yotpo.refreshWidgets();

      return {
        YotpoLoaded: true,
        MaybeInjectYoptoScript: null,
      };
    } else {
      // Inject script if its not been injected before

      const yotpoScriptEl = document.getElementById('yotpo-script');

      // console.log('yotpoScriptEl', yotpoScriptEl);
      return {
        YotpoLoaded: false,
        MaybeInjectYoptoScript: yotpoScriptEl ? null : (
          <Helmet>
            <script
              id='yotpo-script'
              src='https://staticw2.yotpo.com/JXn5eaLRNGLDdPLgOwIC1ca4Y9v2znghdWiOf8cW/widget.js'
              type='text/javascript'
            />
          </Helmet>
        ),
      };
    }
  }, []);
};

export default useYotpo;

useYotpo.displayName = 'useYotpo';
