/**
 * @prettier
 */
import React from 'react';
import PropTypes from 'prop-types';

import { Box, CmsContent, Flex } from '@ripperoni/components';

export const CategoryTile = ({ title, image, links, className }) => {
  return (
    <Flex.Col
      bg='gray.0'
      px={8}
      py={10}
      justifyContent='space-between'
      className={className}
      displayName={CategoryTile.displayName}
    >
      <CmsContent
        content={title}
        variant='text.h4'
        mb={[5, 8, 11, 13]}
        textAlign='center'
      />

      <CmsContent
        content={image}
        overflow='hidden'
        mb={[9, 10, 11, 12]}
        height={['250px', '280px', '310px', '200px', '250px', '280px']}
        imgProps={{
          style: {
            height: '100%',
          },
          imgStyle: {
            objectFit: 'contain',
            height: '100%',
          },
        }}
      />

      <Flex center mx='auto' width='100%'>
        <Box mr={[9, 10, 8, 6]}>{link && links[0]}</Box>
        <Box>{link && links[1]}</Box>
      </Flex>
    </Flex.Col>
  );
};

CategoryTile.displayName = 'CategoryTile';
CategoryTile.propTypes = {};

export default CategoryTile;
