/**
 * @jsx jsx
 * @prettier
 */
import React, { forwardRef } from 'react';
import { jsx } from 'theme-ui';

import { useUIContext } from '@ripperoni/core';
import {
  Button,
  Container,
  Divider,
  Flex,
  Link,
  Svg,
} from '@ripperoni/components';

import { CartToggle } from '@components/CartToggle';

import Logo from '@assets/svgs/logo.svg';

export const Navigation = forwardRef(({ headings, ...props }, ref) => {
  const { state, showSecondaryNavigation, hideSecondaryNavigation } =
    useUIContext();
  const { secondaryNavigation } = state;

  return (
    <Container.Full>
      <Container maxWidth='1392px'>
        <Flex data-comp='Primary Navigation' ref={ref} as='nav' between px={9}>
          <Flex>
            <Flex middle as={Link} to='/' p={0} mr={9} aria-label='Homepage'>
              <Svg as={Logo} width='146px' height='20px' />
            </Flex>

            {headings.map(({ text, url }, index) => (
              <Link
                variant={'a'}
                to={url}
                px={7}
                pt={9}
                pb='22px'
                fontSize={2}
                key={index}
                onMouseEnter={() => showSecondaryNavigation(text)}
                onMouseLeave={() => hideSecondaryNavigation()}
                onClick={hideSecondaryNavigation}
                sx={{
                  variant: 'links.navigation.primary',
                  borderBottom: '2px solid',
                  transition: 'all .2s ease-in',
                  borderColor:
                    secondaryNavigation === text ? 'primary' : 'transparent',
                }}
              >
                {text}
              </Link>
            ))}

            {/* ------ Archive Sale Navigation ------ */}
            {/* <Link
              to='/collections/the-archive-sale'
              className='discount'
              px={7}
              pt={(9, null, null, '25px')}
              pb='22px'
              fontSize='16px'
              fontWeight='semi'
              sx={{
                '&:hover': {
                  borderBottom: '2px solid',
                },
                color: 'discount',
                transition: 'all .2s ease-in',
                borderColor: 'discount',
              }}
            >
              Archive Sale
            </Link> */}
          </Flex>

          <Flex middle>
            <Link
              to='https://publicrec.kustomer.help/'
              mr={9}
              fontSize={2}
              fontWeight='regular'
              variant='links.navigation.secondary'
              sx={{ variant: 'links.navigation.secondary' }}
            >
              Help
            </Link>
            <Link
              to='/account/'
              mr={7}
              fontSize={2}
              variant='links.navigation.secondary'
              sx={{ variant: 'links.navigation.secondary' }}
            >
              Account
            </Link>

            <CartToggle ml={7} />
          </Flex>
        </Flex>
      </Container>
    </Container.Full>
  );
});

Navigation.displayName = 'Navigation';
