/**
 * @prettier
 */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Swiper from 'react-id-swiper';
import { Autoplay, Swiper as RealSwiper } from 'swiper';

import { Box, Container } from '@ripperoni/components';

export const HeroSlider = ({ children, autoPlay, ...props }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const sliderRef = useRef(null);
  const sliderParams = {
    loop: true,
    slidesPerView: 1,
    centerSlides: true,
    allowSlidePrev: true,
    allowSlideNext: true,
    autoplay: {
      delay: 5000,
      disableOnInteraction: false,
    },
    observeSlideChildren: true,
    // pagination: {
    //   el: '.swiper-pagination',
    //   type: 'bullets',
    // },
  };

  RealSwiper.use(Autoplay);

  useEffect(() => {
    if (sliderRef.current !== null && sliderRef.current.swiper !== null) {
      sliderRef.current.swiper.on('slideChange', () => {
        const index = sliderRef.current.swiper.realIndex;
        setActiveIndex(index);

        // sliderRef.current.swiper.loopDestroy();
        sliderRef.current.swiper.loopCreate();
      });
    }
  }, [sliderRef]);

  useEffect(() => {
    if (sliderRef.current !== null && sliderRef.current.swiper !== null) {
      setTimeout(() => {
        sliderRef.current.swiper.loopCreate();
      }, 500);
    }
  }, []);

  return (
    <Container.Full {...props} position='relative'>
      <Swiper {...sliderParams} ref={sliderRef}>
        {children}
      </Swiper>

      <Box
        position='absolute'
        bottom={[9, null, null, 11]}
        right={['50%', null, null, 0]}
        mx={['auto', null, null, 4]}
        zIndex='1'
        transform={['translateX(50%)', null, null, 'translateX(0%)']}
      >
        {React.Children.toArray(children).map((c, i) => {
          return (
            <Box
              key={i}
              width='12px'
              height='12px'
              bg={activeIndex === i ? 'gray.3' : 'gray.2'}
              display='inline-block'
              mr={4}
              sx={{
                borderRadius: '50%',
                cursor: 'pointer',
              }}
              onClick={() => {
                sliderRef.current.swiper.slideTo(i + 1);
              }}
            />
          );
        })}
      </Box>
    </Container.Full>
  );
};

HeroSlider.propTypes = {};

HeroSlider.displayName = 'Hero Slider';
