import { graphql, useStaticQuery } from 'gatsby';

export default function isNotOnline() {
  const data = useStaticQuery(
    graphql`
      query {
        googleSheet {
          NotOnline {
            sku
          }
        }
      }
    `
  );

  const notOnlineProducts = data.googleSheet.NotOnline.map((node) => {
    const { sku } = node;
    return {
      sku,
    };
  });
  return {
    notOnlineProducts,
  };
}
