/**
 * @prettier
 */

// eslint-disable-next-line import/order
import { components as themeComponents } from '@packdigital/gatsby-theme-ripperoni-cms/src/contentful/components';
import { Markdown } from '@ripperoni/components';

import { AboutSizing } from '@components/AboutSizing';
import { AccordionItem } from '@components/AccordionItem';
import { Banner } from '@components/Banner';
import { CategoryTile } from '@components/CategoryTile';
import { FabricInfo } from '@components/FabricInfo';
import { FabricTile } from '@components/FabricTile';
import { FavoriteTile } from '@components/FavoriteTile';
import { FavoriteTiles } from '@components/FavoriteTiles';
import { IGSlider } from '@components/IGSlider';
import { LinkBlock } from '@components/LinkBlock';
import { PairGallery } from '@components/PairGallery';
import { ProductContent } from '@components/ProductContent';
import { ProductFeatures } from '@components/ProductFeatures';
import { Products } from '@components/Products';
import { ProductsRow } from '@components/ProductsRow';
import { QuoteSlider } from '@components/QuoteSlider';
import { SizeIntro } from '@components/SizeIntro';
import { SizingBox } from '@components/SizingBox';
// import { TestimonialQuoteHalf } from '@components/TestimonialQuoteHalf';
import { TileSlider } from '@components/TileSlider';
import { HeroSlider } from '@components/HeroSlider';
import { HeroSplit } from '@components/HeroSplit';
import { HeroS } from '@components/HeroS';
import { CompareSection } from '@components/CompareSection';
import { ShopFeatureSlider } from '@components/ShopFeatureSlider';
import { CollabFavoriteTile } from '@components/CollabFavoriteTile';
import { FeatureImageProducts } from '@components/FeatureImageProducts';
import { Video } from '@components/Video';
import { VideoPageTiles } from '@components/VideoPageTiles';
import { ImageCompare } from '@components/ImageCompare';
import { NavigationFeatureImage } from '@components/NavigationFeatureImage';
import { FeatureBlock } from '@components/FeatureBlock';

import { HolidayGiftGuide } from 'src/components/HolidayGiftGuide';
import { HggFooter } from 'src/components/HggFooter';
import { HggFooterHer } from 'src/components/HggFooterHer';
import { WomenswearSection } from 'src/components/WomenswearSection';
import { LandingPageFooter } from 'src/components/LandingPageFooter';
import { WomenswearText } from 'src/components/WomenswearText';
import { WomenswearTextSection } from 'src/components/WomenswearTextSection';
import { BlogStart } from 'src/components/BlogStart';
import { BlogBody } from 'src/components/BlogBody';
import { BlogEnd } from 'src/components/BlogEnd';
import { MenswearText } from 'src/components/MenswearText';
import { MenswearTextSection } from 'src/components/MenswearTextSection';

export const components = {
  ...themeComponents,
  ContentfulAtomProducts: Products,
  ContentfulAtomText: Markdown,
  ContentfulAtomVideo: Video,
  'about-sizing': AboutSizing,
  'accordion-item': AccordionItem,
  banner: Banner,
  'category-tile': CategoryTile,
  'collab-favorite-tile': CollabFavoriteTile,
  'compare-section': CompareSection,
  'fabric-info': FabricInfo,
  'fabric-tile': FabricTile,
  'favorite-tile': FavoriteTile,
  'favorite-tiles': FavoriteTiles,
  'feature-image-products': FeatureImageProducts,
  'hero-slider': HeroSlider,
  'hero-split': HeroSplit,
  'hero-s': HeroS,
  'image-compare': ImageCompare,
  'instagram-slider': IGSlider,
  'link-block': LinkBlock,
  'pair-gallery': PairGallery,
  'product-content': ProductContent,
  'product-features': ProductFeatures,
  'products-row': ProductsRow,
  'quote-slider': QuoteSlider,
  'shop-feature-slider': ShopFeatureSlider,
  'size-intro': SizeIntro,
  'sizing-box': SizingBox,
  // 'testimonial-quote-half': TestimonialQuoteHalf,
  'tile-slider': TileSlider,
  'video-page-tiles': VideoPageTiles,
  'navigation-feature-image': NavigationFeatureImage,
  'feature-block': FeatureBlock,
  'holiday-gift-guide': HolidayGiftGuide,
  'hgg-footer': HggFooter,
  'hgg-footer-her': HggFooterHer,
  'womenswear-section': WomenswearSection,
  'landing-page-footer': LandingPageFooter,
  'womenswear-text': WomenswearText,
  'womenswear-text-section': WomenswearTextSection,
  'blog-start': BlogStart,
  'blog-body': BlogBody,
  'blog-end': BlogEnd,
  'menswear-text': MenswearText,
  'menswear-text-section': MenswearTextSection,
};
