import React from 'react';
import PropTypes from 'prop-types';

import { Box, MQ } from '@ripperoni/components';

import { CollectionFilterMobile } from './MobileFilter';
import { CollectionFilterDesktop } from './DesktopFilter';

const CollectionFilter = ({ breadcrumbs }) => {
  return (
    <Box position='relative' zIndex={1}>
      <MQ
        comps={[
          <CollectionFilterMobile
            breadcrumbs={breadcrumbs}
            key='MobileCollectionFilter'
          />,
          null,
          null,
          null, // added extra index at beginning of MQ (tshamz - 9/7/2020)
          <CollectionFilterDesktop
            breadcrumbs={breadcrumbs}
            key='DesktopCollectionFilter'
          />,
        ]}
      />
    </Box>
  );
};

CollectionFilter.propTypes = {};

export default CollectionFilter;
