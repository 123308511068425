import React from 'react'
import PropTypes from 'prop-types'

import { Box, Flex, Svg, Text } from '@ripperoni/components';

import GymIcon from '@assets/svgs/compare-gym.svg';
import HomeIcon from '@assets/svgs/compare-home.svg';
import TravelIcon from '@assets/svgs/compare-travel.svg';
import BarIcon from '@assets/svgs/compare-bar.svg';
import OfficeIcon from '@assets/svgs/compare-office.svg';

export const ActivityIcons = ({ activities }) => {
  return (
    <Box>
      <Text
        fontWeight='semi'
      >
        Perfect For:
      </Text>

      <Flex
        middle
        between
      >
        <Box
          opacity={activities.includes('gym') ? 1 : .25}
        >
          <Svg
            as={GymIcon}
          />

          <Text textAlign='center'>
            Gym
          </Text>
        </Box>

        <Box
          opacity={activities.includes('home') ? 1 : .25}
        >
          <Svg
            as={HomeIcon}
          />

          <Text textAlign='center'>
            Home
          </Text>
        </Box>

        <Box
          opacity={activities.includes('travel') ? 1 : .25}
        >
          <Svg
            as={TravelIcon}
          />

          <Text textAlign='center'>
            Travel
          </Text>
        </Box>

        <Box
          opacity={activities.includes('bar') ? 1 : .25}
        >
          <Svg
            as={BarIcon}
          />

          <Text textAlign='center'>
            Bar
          </Text>
        </Box>

        <Box
          opacity={activities.includes('office') ? 1 : .25}
        >
          <Svg
            as={OfficeIcon}
          />

          <Text textAlign='center'>
            Office
          </Text>
        </Box>
      </Flex>
    </Box>
  );
};

ActivityIcons.propTypes = {

}

export default ActivityIcons
