import React from 'react';
import PropTypes from 'prop-types';

import { Date, Flex, Heading } from '@ripperoni/components';


export const OrderHeader = ({
  order,
  ...props
}) => {
  return (
    <Flex
      between
      middle
      variant='account.order.header'
      {...props}
    >
      <Heading variant='text.account.order.heading'>
        {`Order ${order.name}`}
      </Heading>

      <Date variant='text.account.order.date'>
        {order.date}
      </Date>
    </Flex>
  );
};

OrderHeader.propTypes = {
  order: PropTypes.object,
};
