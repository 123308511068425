/**
 * @jsx jsx
 */

/* eslint-disable max-lines */

import {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useReducer,
} from 'react';
import { jsx } from 'theme-ui';
import PropTypes from 'prop-types';
import { StringParam, useQueryParam } from 'use-query-params';

import { useMessageBusContext } from '@ripperoni/message-bus';

const ProductDataByHandleContext = createContext();

const initialState = {
  product: {},
  selectedVariant: {},
  variantOptions: {},
  selectedOptions: {},
};

const productReducer = (state, action) => {
  switch (action.type) {
    case 'SET_VARIANT_OPTIONS': {
      return {
        ...state,
        variantOptions: action.variantOptions,
      };
    }
    case 'SET_SELECTED_OPTIONS': {
      return {
        ...state,
        selectedOptions: {
          ...state.selectedOptions,
          ...action.selectedOptions,
        },
      };
    }
    case 'CHANGE_SELECTED_VARIANT': {
      let newSelectedVariant = state.product.variants.filter(
        (variant) => action.variantId === variant.foreignId
      );

      if (newSelectedVariant.length) {
        // If we want to get the first available variant
        if (action.firstAvailable && action.firstAvailable == 'true') {
          const current = state.product.variants.filter(
            (variant) => action.variantId === variant.foreignId
          )[0].selectedOptionsMap;

          const currentColor = current.Color;
          const similarFirstAvailableProduct = state.product.variants
            .filter(
              (variant) => variant.selectedOptionsMap.Color === currentColor
            )
            .find((variant) => variant.inventory > 10);

          if (similarFirstAvailableProduct) {
            newSelectedVariant = [similarFirstAvailableProduct];
          }
        }

        return {
          ...state,
          selectedVariant: newSelectedVariant[0],
          selectedOptions: {
            ...state.selectedOptions,
            ...(newSelectedVariant[0]
              ? newSelectedVariant[0].selectedOptionsMap
              : {}),
          },
        };
      }

      return {
        ...state,
      };
    }
    default:
      throw new Error(`Invalid ProductContext action type: ${action.type}`);
  }
};

// Product options hieararchy
const optionsOrder = [
  'Singleoption',
  'Color',
  'Style',
  'Size',
  'Fit',
  'Waist',
  'Inseam',
];

export const ProductContext = ({ product, location, children }) => {
  const { publish, topics } = useMessageBusContext();
  const [variantParam, setVariantParam] = useQueryParam('variant', StringParam);
  const [firstAvailableParam, setFirstAvailableParam] = useQueryParam(
    'firstAvailable',
    StringParam
  );

  const paramVariant = product.variants.filter(
    ({ foreignId }) =>
      foreignId.split('gid://shopify/ProductVariant/')[1] === variantParam
  );

  let firstAvailable = product.variants.reduce((i, variant) =>
    i.inventory > variant.inventory ? i : variant
  );

  // default all-day-every-day-short to regular fit
  if (product.handle === 'all-day-every-day-short') {
    firstAvailable = product.variants.find(
      (variant) =>
        variant.available && variant.selectedOptionsMap.Fit === 'Regular'
    );
  }
  if (product.handle === 'workday-pant') {
    let key = 'Fit';
    delete product.optionValues[key];
  }

  const paramsVariant = paramVariant.length
    ? paramVariant[0]
    : firstAvailable
    ? firstAvailable
    : product.variants[0];

  const [productState, dispatch] = useReducer(productReducer, {
    ...initialState,
    product,
    selectedVariant: paramsVariant,
    selectedOptions: paramsVariant ? paramsVariant?.selectedOptionsMap : {},
  });

  // Get available options from product in hieararchy order
  const productOptionOrder = useMemo(() => {
    return orderOptions(optionsOrder, Object.keys(product.optionValues));
  }, [product]);

  // Get variant options by variantId
  const variantMap = useMemo(() => {
    return getAllVariantOptionCombo(productOptionOrder, product.variants);
  }, [product]);

  useEffect(() => {
    if (!variantParam) return;

    dispatch({
      type: 'CHANGE_SELECTED_VARIANT',
      firstAvailable: firstAvailableParam,
      variantId: `gid://shopify/ProductVariant/${variantParam}`,
    });
  }, []);

  // Bases on selected variant and options, generate selectable options
  // for UI selection
  useEffect(() => {
    const currSelectedVariantId = productState?.selectedVariant?.foreignId;
    const currSelectedOptions = productState?.selectedOptions;
    const variantOptions = variantMap[currSelectedVariantId];

    const [availableOptions, newSelectedOptions, newSelectedVariantId] =
      getSelectableOptions(
        productOptionOrder,
        variantOptions,
        currSelectedOptions
      );

    // newSelectedOptions is next available options if not a valid selectedOption,
    // else return undefined if valid
    if (newSelectedOptions) {
      dispatch({
        type: 'SET_SELECTED_OPTIONS',
        selectedOptions: newSelectedOptions,
      });
    }

    // Set selectable options for ui selection
    if (availableOptions) {
      dispatch({
        type: 'SET_VARIANT_OPTIONS',
        variantOptions: availableOptions,
      });
    }

    // If selectedVariantId, update to newly selected variantId
    if (
      newSelectedVariantId &&
      currSelectedVariantId !== newSelectedVariantId
    ) {
      dispatch({
        type: 'CHANGE_SELECTED_VARIANT',
        variantId: newSelectedVariantId,
      });
    }

    if (variantParam) {
      const newVariantParam = newSelectedVariantId?.split('/').slice(-1);
      setVariantParam(newVariantParam, 'replaceIn');
    }
  }, [productState.selectedVariant, productState.selectedOptions]);

  const contextState = useMemo(() => {
    return {
      productState,
      // set variant by id
      setSelectedVariantById: (variantId) => {
        dispatch({ type: 'CHANGE_SELECTED_VARIANT', variantId });
      },
      // Set new selected options
      setSelectedOptions: (optionName, optionValue) => {
        dispatch({
          type: 'SET_SELECTED_OPTIONS',
          selectedOptions: {
            [optionName]: optionValue,
          },
        });
      },
    };
  }, [productState]);

  useEffect(() => {
    publish(topics.VIEW_PRODUCT, { ...productState, location });
  }, [product.handle]);

  return (
    <ProductDataByHandleContext.Provider value={contextState}>
      {children}
    </ProductDataByHandleContext.Provider>
  );
};

export const useProduct = () => useContext(ProductDataByHandleContext);

const orderOptions = (order, options) => {
  return [...options].sort(function (a, b) {
    return order.indexOf(a) - order.indexOf(b);
  });
};

// Returns a map of variants and their available options
const getAllVariantOptionCombo = (productOptionOrder, variants) => {
  const variantMap = {};
  const optionsList = {};

  variants.forEach(({ foreignId, selectedOptionsMap }) => {
    productOptionOrder.reduce((acc, curr, index) => {
      const optionValue = selectedOptionsMap[curr];

      // At last option, then return its foreign id
      if (index === productOptionOrder.length - 1) {
        variantMap[foreignId] = optionsList;

        return (acc[optionValue] = foreignId);
      } else {
        if (!acc[optionValue]) {
          // If there is not option in current optionList, return a new reference
          // to an object to nest in
          return (acc[optionValue] = {});
        } else {
          // If there is an option in the current optionList, return the optionList
          // to add more sibling values
          return acc[optionValue];
        }
      }
    }, optionsList);
  });

  return variantMap;
};

// Gets selectable options based on all the variant's option, and what options
// are currently selected
const getSelectableOptions = (
  productOptionOrder,
  variantOptions,
  selectedOptions
) => {
  const availableOptions = {};
  let newSelectedOptions = null;
  let selectedVariantId = null;

  const _getOptions = (
    productOptionOrder,
    variantOptions,
    selectedOptions,
    index
  ) => {
    const optionName = productOptionOrder[index];
    const selectedOptionValue = selectedOptions[optionName];

    // If we are at the end of the options, set current variant id,
    // or reselect an available option
    if (
      index === productOptionOrder.length ||
      !variantOptions ||
      !Object.keys(variantOptions).length
    ) {
      if (variantOptions) {
        // If we are at the last, the value of options will be the foreignVariantId
        return variantOptions;
      } else {
        return 'NO_ID';
      }
    }

    // Set optionName and optionValues to the available options
    availableOptions[optionName] = Object.keys(variantOptions);
    const finalOption = _getOptions(
      productOptionOrder,
      variantOptions[selectedOptionValue],
      selectedOptions,
      index + 1
    );

    if (finalOption === 'NO_ID') {
      newSelectedOptions = {
        ...selectedOptions,
        [optionName]: Object.keys(variantOptions)[0],
      };
    }

    if (finalOption) {
      selectedVariantId = finalOption;
    }
  };

  _getOptions(productOptionOrder, variantOptions, selectedOptions, 0);

  return [availableOptions, newSelectedOptions, selectedVariantId];
};

ProductContext.propTypes = {
  product: PropTypes.object.isRequired,
  selectedForeignVariantId: PropTypes.string,
  children: PropTypes.any,
};
