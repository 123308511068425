/**
 * @prettier
 */
import React, { memo, useEffect } from 'react';

import { ContentfulContent } from '@ripperoni/cms';

export const FooterLinkBlocks = memo(({ linkBlocks, children, ...props }) => {
  // useEffect(() => {
  //   var linkToAppend = document.getElementById(
  //     'c15afe38-19aa-5a2e-850f-185e90713337'
  //   );

  //   linkToAppend.insertAdjacentHTML(
  //     'beforeend',
  //     `<a class="truevault-polaris-privacy-notice"
  //     href="https://privacy.publicrec.com/privacy-policy#financial-incentive"
  //     noreferrer
  //     noopener
  //     hidden
  //     >
  //     Notice of Financial Incentive
  //     </a>
  //   <a
  //     className='truevault-polaris-dns-link'
  //     href='https://privacy.publicrec.com/privacy-policy#california-privacy-notice'
  //     noreferrer
  //     noopener
  //   >
  //     California Privacy Notice
  //   </a>`
  //   );
  // }, []);

  return linkBlocks.nodes.map((node, index) => (
    <ContentfulContent
      data-comp={FooterLinkBlocks.displayName}
      {...node}
      key={index}
      mr={16}
      mb={[index === 0 ? 9 : 12, null, null, 0]}
      sx={{
        'a:hover': {
          textDecoration: 'underline',
        },
      }}
    />
  ));
});

FooterLinkBlocks.displayName = 'Footer Link Blocks';
