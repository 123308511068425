/* eslint-disable import/no-default-export */
/** @jsx jsx */
import React, { useState } from 'react';
import { jsx } from 'theme-ui';

import { Box, Container, Flex, Image, Video } from '@ripperoni/components';

import { Seo } from 'src/@packdigital/gatsby-theme-ripperoni-marketing/components/Seo/Seo.jsx';

const DigitalCatalog = (props) => {
  return (
    <>
      <Seo pageTitle='Public Rec Fall 2022 Digital Catalog' />
      <Container.Full bg='gray.0' px={8}>
        <Container py={[11, null, null, 15]} position='relative'>
          <Box>
            <Box position='relative'>
              <Container width='100%' height={['250px', null, null, '900px']}>
                <iframe
                  top='0'
                  height='100%'
                  border={0}
                  width='100%'
                  allow='clipboard-write'
                  sandbox='allow-top-navigation allow-top-navigation-by-user-activation allow-downloads allow-scripts allow-same-origin allow-popups allow-modals allow-popups-to-escape-sandbox'
                  allowFullScreen='true'
                  src='https://e.issuu.com/embed.html?d=pr_fw22_issuu&u=publicrec'
                />
              </Container>
            </Box>
          </Box>
        </Container>
      </Container.Full>
    </>
  );
};

export default DigitalCatalog;
