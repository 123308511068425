/** @jsx jsx */
import { jsx } from 'theme-ui';
import { motion } from 'framer-motion';

import { Box, Button, Svg } from '@ripperoni/components';
import { useUIContext } from '@ripperoni/core';
import { useCart, useCartCount, useCartReady } from '@ripperoni/cart';
import { useMessageBusContext } from '@ripperoni/message-bus';

import BagIcon from '@assets/svgs/bag.svg';

const AnimatedButton = motion.custom(Button);

export const CartToggle = (props) => {
  const cart = useCart();
  const cartCount = useCartCount();
  const cartReady = useCartReady();
  const { toggleCart } = useUIContext();
  const { publish, topics } = useMessageBusContext();

  const hasItems = cartCount > 0;

  const handleCartToggle = () => {
    toggleCart();
    publish(topics.VIEW_CART, cart);
  };

  if (!cartReady) {
    // placeholder box so other icons don't snap
    return <Box height='25px' width='24px' {...props} />;
  }

  return (
    <AnimatedButton
      data-comp={CartToggle.displayName}
      aria-label='Cart Toggle'
      variant={['plain']}
      position='relative'
      width='24px'
      sx={{
        '&:active:focus': {
          outline: 'none',
        },
        '&:focus': {
          outline: '1px dotted #212121',
          outline: '5px auto -webkit-focus-ring-color',
        },
      }}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
      onClick={handleCartToggle}
      {...props}
    >
      <Svg
        as={BagIcon}
        height='25px'
        stroke='primary'
        strokeWidth={4}
        sx={{
          '.fill': {
            fill: hasItems && 'gray.3',
          },
        }}
      />

      {hasItems && (
        <Box
          fontSize='11px'
          weight='semi'
          color='white'
          position='absolute'
          left='50%'
          bottom='2px'
          transform='translateX(-50%)'
        >
          {cartCount}
        </Box>
      )}
    </AnimatedButton>
  );
};

CartToggle.displayName = 'CartToggle';
