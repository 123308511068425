/** @jsx jsx */
import React from 'react';
import { jsx } from 'theme-ui';
import PropTypes from 'prop-types';

import { Link, Text } from '@ripperoni/components';

export const Breadcrumb = ({ breadcrumbs }) => {
  if (!breadcrumbs) return null;

  return breadcrumbs.breadCrumbLinks.map((link, index) => {
    if (index === breadcrumbs.breadCrumbLinks.length - 1) {
      return (
        <Text
          key={index}
          textDecoration='none'
          fontSize={[3, null, null, null, 5]}
          color='black'
          display='inline'
        >
          {link.title}
        </Text>
      );
    }

    return (
      <React.Fragment key={index + Math.random()}>
        <Link
          // key={index + Math.random()}
          to={link.url}
          textDecoration='none'
          fontSize={[3, null, null, null, 5]}
          color='gray.2'
          variant='a'
          sx={{
            ':hover': {
              textDecoration: 'underline',
            },
          }}
        >
          {link.title}
        </Link>

        <Text as='span' color='gray.2'>
          {' / '}
        </Text>
      </React.Fragment>
    );
  });
};

Breadcrumb.propTypes = {};

export default Breadcrumb;
