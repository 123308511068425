/**
 * @prettier
 */

/* eslint-disable react/prop-types */
import React from 'react';

import { Box, Flex, Link, Text } from '@ripperoni/components';
import {
  AccountLayout,
  OrderAddresses,
  OrderHeader,
  OrderLineItems,
  OrderTotals,
  useCustomerContext,
} from '@ripperoni/account';

export const Order = ({ location, ...props }) => {
  const { state } = useCustomerContext();
  const order =
    location.state?.order ||
    state.customer.orders.find((order) => order.id == props.id);

  return (
    <AccountLayout variant='account.layout.order' loggedIn={true} {...props}>
      <OrderHeader order={order} />

      <Flex.Col variant='account.order.content'>
        <OrderAddresses
          shippingAddress={order.shippingAddress}
          fulfillment={order.fulfillments[0]}
        />

        <OrderLineItems
          statusUrl={order.statusUrl}
          lineItems={order.lineItems}
        />

        <OrderTotals
          subtotal={order.subtotalPrice}
          shipping={order.shippingPrice}
          tax={order.totalTax}
          totalPrice={order.totalPrice}
        />
      </Flex.Col>

      <Flex mt={9}>
        <Flex
          as={Link}
          animate={false}
          to='/account/'
          sx={{
            color: 'text',
            textDecoration: 'none',
          }}
        >
          <Box transform='rotate(45deg)'>&#8735;</Box>

          <Text pb={1}>Back to Order History</Text>
        </Flex>
      </Flex>
    </AccountLayout>
  );
};
