import React from 'react';
import PropTypes from 'prop-types';

import { useUIContext } from '@ripperoni/core';
import { getLegacyShopifyId } from '@ripperoni/utilities';
import {
  Box,
  Button,
  ClientSideOnly,
  Flex,
  Heading,
  Image,
  Link,
  Price,
  Svg,
  Text,
} from '@ripperoni/components';
import { useRemoveItemFromCart } from '@ripperoni/cart';

import Close from 'src/assets/svgs/close.svg';
import { QuantitySelect } from 'src/@packdigital/gatsby-theme-ripperoni-cart/components/QuantitySelect/QuantitySelect.jsx';

export const CartLineItem = ({
  id,
  title,
  quantity,
  variant,
  customAttributes = [],
  ...props
}) => {
  const { toggleCart } = useUIContext();
  const removeItemFromCart = useRemoveItemFromCart();
  const { selectedOptions, image, price, compareAtPrice } = variant || {};
  console.log('variants', variant);
  const trimmedTitle = title
    .replace("Men's", '')
    .replace("Women's", '')
    .split('|');
  const options = selectedOptions
    ?.filter((name) => name !== 'Color' && name !== 'Style')
    .map(({ name, value }) => `${name}: ${value}`)
    .join('\n');
  const url = customAttributes.find(({ key }) => key === '_url')?.value;
  const cursor = url === '#' ? 'auto' : 'pointer';
  const singleOption = ['Chill Beanie', 'Pro Pack', 'Pro Pack Plus'];
  const productColor = trimmedTitle[1];

  return (
    <ClientSideOnly>
      <Flex
        data-comp={CartLineItem.displayName}
        data-variantid={getLegacyShopifyId(variant.id)}
        pb='12px'
        mb='25px'
        {...props}
      >
        <Box
          as={url && Link}
          to={url}
          position='relative'
          maxWidth='90px'
          width='100%'
          mr='28px'
          sx={{ variant: 'links.plain' }}
          onClick={() => url && toggleCart()}
        >
          <Image
            src={image?.src}
            alt={image?.altText}
            imgStyle={{ width: '100%' }}
          />
        </Box>

        <Flex.Col middle>
          {compareAtPrice ? (
            <Text
              sx={{
                backgroundColor: '#9D3838',
                borderRadius: '30px',
                fontWeight: '600',
                fontSize: '10px',
                lineHeight: '11px',
                textAlign: 'center',
                color: '#FFFFFF',
                px: '10px',
                py: '3px',
                mx: 0,
                my: 2,
                width: 70,
              }}
            >
              Final Sale
            </Text>
          ) : (
            <></>
          )}
          <Heading
            mb='2px'
            size='15px'
            weight='bold'
            // textTransform='uppercase'
            variant='cart.text.lineItem.primaryTitle'
          >
            {url ? (
              <Link
                to={url || '#'}
                position='relative'
                sx={{
                  cursor,
                  color: 'inherit',
                  textDecoration: 'none',
                  variant: 'links.plain',
                  fontSize: '15px',
                  fontWeight: '400',
                }}
                onClick={() => url && toggleCart()}
              >
                {trimmedTitle[0]}
              </Link>
            ) : (
              title
            )}
          </Heading>
          {/* {trimmedTitle[1] ? (
            <Text size='11px'>Color: {trimmedTitle[1]}</Text>
          ) : null} */}
          {options.includes('Amount') ? (
            <Text
              size='13px'
              mb={2}
              maxWidth={'180px'}
              // weight='400'
              // letterSpacing='.25px'
              // variant='cart.text.lineItem.secondaryTitle'
            >
              {options}
            </Text>
          ) : options.includes('Color') ? (
            <Text size='13px' maxWidth={'180px'} mb={2}>
              Color: {trimmedTitle[1]}
            </Text>
          ) : (
            <Text
              size='13px'
              maxWidth={'180px'}
              mb={2}
              sx={{
                whiteSpace: 'pre-line',
              }}
            >
              Color: {trimmedTitle[1]}
              <br />
              {options}
            </Text>
          )}

          {/* <Text
            size='11px'
            mb={2}
            // weight='400'
            // letterSpacing='.25px'
            // variant='cart.text.lineItem.secondaryTitle'
          >
            {options}
          </Text> */}
          <QuantitySelect
            width='90px'
            height='26px'
            id={id}
            quantity={quantity}
          />
        </Flex.Col>

        <Flex.Col right ml='auto' pl='22px'>
          <Button.Plain mb='auto' onClick={() => removeItemFromCart(id)}>
            <Svg as={Close} color='#4E4E4E' width='11px' />
          </Button.Plain>

          <Box mb='0'>
            {compareAtPrice ? (
              <>
                <Price
                  fontSize='14px'
                  fontWeight='400'
                  color='#4F4F4F'
                  textDecoration='line-through'
                >
                  {Math.round(compareAtPrice)}
                </Price>
                <Price color='#9E3838' fontSize='14px' fontWeight='400'>
                  {Math.round(price)}
                </Price>
              </>
            ) : (
              <Price fontSize='14px' fontWeight='400'>
                {price}
              </Price>
            )}
          </Box>
        </Flex.Col>
      </Flex>
    </ClientSideOnly>
  );
};

CartLineItem.displayName = 'Cart Line Item';

CartLineItem.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  quantity: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  variant: PropTypes.object,
};
