import React from 'react';

import { Main as Layout } from '@layouts/Main';

export const Main = ({ children, ...props }) => {
  return (
    <Layout {...props}>
      {children}
    </Layout>
  );
};
