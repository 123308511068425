import React from 'react'
import PropTypes from 'prop-types'

export const CompareSection = props => {
  return (
    <div>
      HI
    </div>
  )
}

CompareSection.propTypes = {

}
