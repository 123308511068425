import React from 'react';
import PropTypes from 'prop-types';

import { Flex, Svg } from '@ripperoni/components';

import ArrowRight from '@assets/svgs/arrow-right.svg';


export const NextArrow = (props) => {
  return (
    <Flex
      middle
      center
      sx={{
        display: ['none', null, null, 'flex'],
        cursor: 'pointer',
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        right: '-22px',
        zIndex: 1,
        background: 'white',
        boxShadow: '-1px 3px 5px -1px rgba(0,0,0,0.50)',
        width: '45px',
        height: '45px',
        borderRadius: '50%'
      }}
      {...props}
    >
      <Svg
        as={ArrowRight}
      />
    </Flex>
  );
};

NextArrow.propTypes = {

};
