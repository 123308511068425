import React, { useEffect, useMemo, useRef } from 'react';
import Swiper from 'react-id-swiper';

import { isBrowser } from '@ripperoni/utilities';
import { CmsContent, Container, Text } from '@ripperoni/components';

export const ProductsRow = ({ heading, products, imageType, ...props }) => {
  const sliderRef = useRef(null);
  const sliderParams = {
    loop: false,
    slidesPerView: 1.25,
    spaceBetween: 20,
    centerSlides: true,
    allowSlidePrev: true,
    allowSlideNext: true,
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
    },
    breakpoints: {
      600: {
        loop: false,
        slidesPerView: 2,
      },
      768: {
        spaceBetween: 24,
        slidesPerView: 3,
      },
    },
  };

  useEffect(() => {
    if (isBrowser) {
      const reinitSlide = setInterval(() => {
        window.dispatchEvent(new Event('resize'));
        clearInterval(reinitSlide);
      }, 500);
    }
  }, []);

  const renderTiles = useMemo(() => {
    return () => (
      <CmsContent
        content={products}
        imageType={imageType}
        fromCms={true}
        maxWidth='357px'
      />
    );
  }, []);

  return (
    <Container.Full px={8} data-comp={ProductsRow.displayName} {...props}>
      <Container
        position='relative'
        sx={{
          '.gatsby-image-wrapper img': {
            opacity: '1 !important',
          },
        }}
      >
        <CmsContent
          as={Text}
          variant='text.h3'
          textAlign='center'
          content={heading}
          mb={[10, null, null, 12]}
        />

        <Swiper {...sliderParams} ref={sliderRef}>
          {renderTiles()}
        </Swiper>
      </Container>
    </Container.Full>
  );
};

ProductsRow.displayName = 'Products Row';
