import React from 'react';
import { Helmet } from 'react-helmet';

import { Flex, Grid, Heading, Image, Svg, Text } from '@ripperoni/components';

import Logos from '../components/Map/Logos';
import Map from '../components/Map/Map';
import StoreList from '../components/Map/StoreList';
import storeLocatorData from '../hooks/storeLocatorData';

const StoreLocator = () => {
  const { locations } = storeLocatorData();
  return (
    <>
      <Helmet>
        <title>Public Rec Store Locator: Comfort's Closer Than You Think</title>
      </Helmet>

      <div>
        <Heading
          backgroundColor='#f9F9F9'
          height={['330px', null, '380px', null, null]}
          variant='heading.h1'
          textAlign='center'
          fontSize={['21px', null, '30px', null, null]}
          // mt={[11, null, 13, null, null]}
          mb={10}
          mt={2}
          pt={[40, null, 80, null, null]}
        >
          Comfort's Closer Than You Think
          <Text
            fontSize={['16px', null, '20px', null, null]}
            textAlign='center'
            lineHeight={['1.25', null, '1.3', null, null]}
            maxWidth={['90%', null, '50%', null, null]}
            display='block'
            mx='auto'
            mb={[10, null, 12, null, null]}
            my={10}
          >
            Public Rec is available at a variety of retailers across the US,
            including our very own stores.
          </Text>
          <Logos />
        </Heading>
        <Flex
          sx={{
            backgroundColor: '#ffffff',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center,',
          }}
        >
          <Text
            textAlign='center'
            fontSize={['24px', null, '28px', null, null]}
            fontWeight='600'
            maxWidth='573px'
            display='block'
            mx='auto'
            mt={['15px', null, '65px', null, null]}
            mb={['5px', null, '55px', null, null]}
          >
            Visit Our Stores
          </Text>
        </Flex>

        {/* Begin PR stores section */}
        <Grid
          backgroundColor='#ffffff'
          columns={['1fr 1fr 1fr']}
          maxWidth={'1100px'}
          mx='auto'
          rows={['repeat(2,1)']}
          columnGap={-220}
          alignItems='block'
        >
          <Flex mb={['30px', null, '80px', null, null]}>
            <Text
              color='#bebdbd'
              padding='15px'
              height='auto'
              backgroundColor='#ffffff'
              textAlign='center'
              fontSize='1.4em'
              fontWeight='600'
              display='block'
              mx='auto'
            >
              <Image.Static src='/dummyContent/0Scottsdale0.png' width='100%' />
              <Text
                color='primary'
                textAlign='center'
                fontSize={['11px', null, '14px', null, null]}
                fontWeight='600'
                display='block'
                mx='auto'
                mt={['0px', null, '44px', null, null]}
                mb='10px'
              >
                NOW OPEN
              </Text>
              <Text
                color='primary'
                textAlign='center'
                fontSize={['20px', null, '24px', null, null]}
                fontWeight='600'
                maxWidth='573px'
                display='block'
                mx='auto'
                mt={-1}
              >
                Scottsdale
                <br />
                Kierland Commons
              </Text>
              <Text
                color='primary'
                textAlign='center'
                fontSize={['14px', null, '18px', null, null]}
                fontWeight='500'
                maxWidth='573px'
                display='block'
                mx='auto'
                mt='10px'
              >
                15211 N Kierland Blvd Suite 110
              </Text>
              <Text
                color='primary'
                textAlign='center'
                fontSize={['14px', null, '18px', null, null]}
                fontWeight='500'
                maxWidth='573px'
                display='block'
                mx='auto'
                mb='20px'
              >
                Scottsdale, AZ 85254
              </Text>
              <hr width='30%' />
              <Text
                color='primary'
                textAlign='center'
                fontSize={['18px', null, '20px', null, null]}
                fontWeight='600'
                maxWidth='573px'
                display='block'
                mx='auto'
                mt='20px'
              >
                Store Hours:
              </Text>
              <Text
                color='primary'
                textAlign='center'
                fontSize={['14px', null, '18px', null, null]}
                fontWeight='500'
                maxWidth='573px'
                display='block'
                mx='auto'
              >
                Mon-Sat: 10am - 8pm
              </Text>
              <Text
                color='primary'
                textAlign='center'
                fontSize={['14px', null, '18px', null, null]}
                fontWeight='500'
                maxWidth='573px'
                display='block'
                mx='auto'
              >
                Sun: 11am - 6pm
              </Text>
            </Text>
          </Flex>

          <Flex mb={['30px', null, '80px', null, null]}>
            <Text
              color='#bebdbd'
              padding='15px'
              height='auto'
              backgroundColor='#ffffff'
              textAlign='center'
              fontSize='1.4em'
              fontWeight='600'
              display='block'
              mx='auto'
            >
              <Image.Static src='/dummyContent/chicago.png' width='100%' />
              <Text
                color='primary'
                textAlign='center'
                fontSize={['11px', null, '14px', null, null]}
                fontWeight='600'
                display='block'
                mx='auto'
                mt={['0px', null, '44px', null, null]}
                mb='10px'
              >
                NOW OPEN
              </Text>
              <Text
                color='primary'
                textAlign='center'
                fontSize={['20px', null, '24px', null, null]}
                fontWeight='600'
                maxWidth='573px'
                display='block'
                mx='auto'
                mt={-1}
              >
                Chicago
                <br />
                West Loop
              </Text>
              <Text
                color='primary'
                textAlign='center'
                fontSize={['14px', null, '18px', null, null]}
                fontWeight='500'
                maxWidth='573px'
                display='block'
                mx='auto'
                mt='10px'
              >
                946 W. Randolph
              </Text>
              <Text
                color='primary'
                textAlign='center'
                fontSize={['14px', null, '18px', null, null]}
                fontWeight='500'
                maxWidth='573px'
                display='block'
                mx='auto'
                mb='20px'
              >
                Chicago, IL 60607
              </Text>
              <hr width='30%' />
              <Text
                color='primary'
                textAlign='center'
                fontSize={['18px', null, '20px', null, null]}
                fontWeight='600'
                maxWidth='573px'
                display='block'
                mx='auto'
                mt='20px'
              >
                Store Hours:
              </Text>
              <Text
                color='primary'
                textAlign='center'
                fontSize={['14px', null, '18px', null, null]}
                fontWeight='500'
                maxWidth='573px'
                display='block'
                mx='auto'
              >
                Mon-Sat: 11am - 7pm
              </Text>
              <Text
                color='primary'
                textAlign='center'
                fontSize={['14px', null, '18px', null, null]}
                fontWeight='500'
                maxWidth='573px'
                display='block'
                mx='auto'
              >
                Sun: 11am - 6pm
              </Text>
            </Text>
          </Flex>

          <Flex>
            <Text
              color='#bebdbd'
              padding='15px'
              height='auto'
              backgroundColor='#ffffff'
              textAlign='center'
              fontSize='1.4em'
              fontWeight='600'
              display='block'
              mx='auto'
            >
              {/* <Svg key={NycStore} as={NycStore} width='100%'></Svg> */}
              <Image.Static src='/dummyContent/ny.png' width='100%' />
              <Text
                color='primary'
                textAlign='center'
                fontSize={['11px', null, '14px', null, null]}
                fontWeight='600'
                display='block'
                mx='auto'
                mt={['0px', null, '44px', null, null]}
                mb='10px'
              >
                NOW OPEN
              </Text>
              <Text
                color='primary'
                textAlign='center'
                fontSize={['20px', null, '24px', null, null]}
                fontWeight='600'
                maxWidth='573px'
                display='block'
                mx='auto'
                mt={-1}
              >
                New York
                <br />
                Manhattan West
              </Text>

              <Text
                color='primary'
                textAlign='center'
                fontSize={['14px', null, '18px', null, null]}
                fontWeight='500'
                maxWidth='573px'
                display='block'
                mx='auto'
                mt='10px'
              >
                375 9th Ave Space #117
              </Text>
              <Text
                color='primary'
                textAlign='center'
                fontSize={['14px', null, '18px', null, null]}
                fontWeight='500'
                maxWidth='573px'
                display='block'
                mx='auto'
                mb='20px'
              >
                New York, NY 10001
              </Text>

              <hr width='30%' />
              <Text
                color='primary'
                textAlign='center'
                fontSize={['18px', null, '20px', null, null]}
                fontWeight='600'
                maxWidth='573px'
                display='block'
                mx='auto'
                mt='20px'
              >
                Store Hours:
              </Text>
              <Text
                color='primary'
                textAlign='center'
                fontSize={['14px', null, '18px', null, null]}
                fontWeight='500'
                maxWidth='573px'
                display='block'
                mx='auto'
              >
                Mon-Sat: 11am-7pm
              </Text>
              <Text
                color='primary'
                textAlign='center'
                fontSize={['14px', null, '18px', null, null]}
                fontWeight='500'
                maxWidth='573px'
                display='block'
                mx='auto'
              >
                Sun: 12pm-6pm
              </Text>
            </Text>
          </Flex>
        </Grid>

        {/* End PR Stores - Map Start */}
        <Map />
        <StoreList />
        <Text
          padding='60px'
          backgroundColor='#f9F9F9'
          textAlign='center'
          fontSize={['22px', null, '28px', null, null]}
          fontWeight='600'
          display='block'
          mx='auto'
          mt={['0px', null, '44px', null, null]}
        >
          Interested in carrying Public Rec?
          <Text
            textAlign='center'
            fontSize={['14px', null, '18px', null, null]}
            fontWeight='400'
            maxWidth='573px'
            display='block'
            mx='auto'
            mt='18px'
            mb={2}
          >
            Drop us a line at wholesale@publicrec.com
          </Text>
        </Text>
      </div>
    </>
  );
};

export default StoreLocator;
