import React, { useEffect, useRef, useState } from 'react';
import mapboxgl from 'mapbox-gl';

import Markers from './Markers';
import storeLocatorData from '../../hooks/storeLocatorData';
import useWindowSize from '../../utils/useWindowSize';

import 'mapbox-gl/dist/mapbox-gl.css';

const MAPBOX_TOKEN = process.env.GATSBY_MAPBOX_TOKEN;
const origin = [-93.787, 40];

const Map = () => {
  const { width } = useWindowSize();

  var mapContainerStyle = {
    width: '100%',
    height: '',
  };

  const { locations } = storeLocatorData();

  const mapContainerRef = useRef(null);
  const [map, setMap] = useState(null);

  useEffect(() => {
    const map = new mapboxgl.Map({
      container: mapContainerRef.current,
      accessToken: MAPBOX_TOKEN,
      style: 'mapbox://styles/jwernecke/ckqlff8iz0t7b18o7vf7qmti8',
    });

    map.scrollZoom.disable();
    map.addControl(new mapboxgl.NavigationControl(), 'top-right');

    if (width > 480) {
      map.setZoom(3.34); //set map zoom level for desktop size
    } else {
      map.setZoom(1.9); //set map zoom level for mobile size
    }

    map.setCenter(origin);
    setMap(map);
    return () => map.remove();
  }, []);

  return (
    <div ref={mapContainerRef} style={mapContainerStyle}>
      {locations && map && <Markers map={map} locations={locations} />}
    </div>
  );
};

export default Map;
