/** @jsx jsx */
import { jsx } from 'theme-ui';
import React, { useEffect } from 'react';
import { graphql } from 'gatsby';

import { useMessageBusContext } from '@ripperoni/message-bus';
import { ContentfulContent } from '@ripperoni/cms';

import CollectionComponent from '@components/Collection';
import { CollectionHero } from '@components/CollectionHero';
import { CollectionContext } from '@components/CollectionContext';

import { Seo } from 'src/@packdigital/gatsby-theme-ripperoni-marketing/components/Seo/Seo.jsx';

import seoTitle from '../hooks/seoTitle';

const Collections = ({ data, ...props }) => {
  const { breadcrumbs, collection, categoryImages, banner } = data;
  const { publish, topics } = useMessageBusContext();

  useEffect(() => {
    publish(topics.VIEW_COLLECTION, collection);
  }, [collection.handle]);

  const { seoTitleReplacements } = seoTitle();
  const allSeoTitleReplacements = seoTitleReplacements.map((a) => a.title);
  const isSeoTitleReplaced = allSeoTitleReplacements.includes(collection.title);
  const seoTitleChange = seoTitleReplacements.filter(
    (a) => a.title === collection.title
  )[0];
  const replacedSeoTitle = seoTitleChange;
  console.log('replaced SEO TITLE ', collection.title, replacedSeoTitle);

  return (
    <>
      <Seo
        pageTitle={
          isSeoTitleReplaced
            ? Object.values(replacedSeoTitle)[1]
            : collection.title
        }
        description={collection.description}
        logo={
          collection.image && collection.image.src
            ? `${collection.image.src}`
            : '/logo.png'
        }
      />
      <CollectionContext collection={collection}>
        {banner ? (
          <ContentfulContent {...banner} sx={collectionBannerStyles} />
        ) : (
          <CollectionHero collection={collection} />
        )}
        <CollectionComponent
          categoryImages={categoryImages}
          breadcrumbs={breadcrumbs}
        />
      </CollectionContext>
    </>
  );
};

Collections.propTypes = {};

// eslint-disable-next-line
export default Collections;

// collectionBannerStyles set outside of Banner.jsx to keep any
// backwards compatibility
const collectionBannerStyles = {
  backgroundColor: ['gray.1', 'novalue'],
  '&&': {
    height: ['125px', null, null, '250px'],
    position: 'relative',
    top: ['10px', null, null, '15px'],
    mb: ['10px', null, null, '15px'],
    px: ['10px', '10px', '10px', '20px', '20px'],
    backgroundRepeat: 'no-repeat',
    backgroundPosition: ['68% 0%', null, null, 'center'],
    backgroundSize: 'cover',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  '&& > div': {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column-reverse',
  },
  '&& > div div:nth-child(2) div': {
    mb: 4,
    mt: 'auto',
    display: 'inline-block',
    fontSize: ['14px', null, null, '20px'],
    fontWeight: '300',
  },
  '&& > div div': {
    ml: ['5px', 0],
  },
  '&& > div div:first-child div': {
    mt: 0,
    mb: 'auto',
    fontSize: ['24px', null, null, '42px'],
    fontWeight: 'normal',
  },
};

export const query = graphql`
  query ($handle: String!) {
    breadcrumbs: contentfulCollectionBreadcrumbs(metaHandle: { eq: $handle }) {
      breadCrumbLinks {
        title
        url
        __typename
      }
      __typename
    }
    categoryImages: allContentfulAtomImage(
      filter: { metaTags: { eq: "collection" } }
    ) {
      nodes {
        ...ContentfulAtomImage
        metaHandle
        __typename
      }
      __typename
    }
    banner: contentfulMolecule(
      metaHandle: { eq: $handle }
      metaTags: { eq: "banner" }
    ) {
      ...ContentfulMolecule
      __typename
    }
    collection: backpackCollection(handle: { eq: $handle }) {
      id
      title
      handle
      image {
        src
        __typename
      }
      description
      variants {
        id
        metadata
        inventory
        price
        product {
          title
          handle
          __typename
        }
        __typename
      }
      __typename
    }
    __typename
  }
`;
