/** @jsx jsx */
import { Box, jsx } from 'theme-ui';
import { useMemo } from 'react';

import { CmsContent, Flex, FlexCol, Heading } from '@ripperoni/components';

export const Banner = ({
  desktopImage,
  mobileImage,
  title,
  description,
  ...props
}) => {
  const image = useMemo(() => {
    const desktopPrimary = desktopImage?.[0]?.props?.primaryImage?.fluid;
    const mobilePrimary = mobileImage?.[0]?.props?.primaryImage?.fluid;
    return {
      desktopSrc: desktopPrimary?.srcWebp || desktopPrimary?.src,
      mobileSrc:
        mobilePrimary?.srcWebp || mobilePrimary?.src || desktopPrimary?.src,
    };
  }, []);
  const titleString = title[0].props.longText.text;
  console.log(titleString);
  return (
    <Flex
      data-comp={Banner.displayName}
      sx={bannerSx(image.desktopSrc, image.mobileSrc)}
      {...props}
    >
      <FlexCol sx={contentSx}>
        <Box>
          <Heading
            as='h1'
            fontSize={['24px', null, null, '42px']}
            ml={['5px', 0]}
            fontWeight='regular'
          >
            {titleString}
          </Heading>
        </Box>
        <Box>
          <CmsContent content={description} />
        </Box>
      </FlexCol>
    </Flex>
  );
};

const bannerSx = (desktopSrc, mobileSrc) => ({
  height: ['125px', null, null, '250px'],
  position: 'relative',
  top: ['10px', null, null, '15px'],
  mb: ['10px', null, null, '15px'],
  px: ['10px', '10px', '10px', '20px', '20px'],
  backgroundImage: desktopSrc
    ? [`url(${mobileSrc || desktopSrc})`, `url(${desktopSrc})`]
    : null,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: ['68% 0%', null, null, 'center'],
  backgroundSize: 'cover',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
});

const contentSx = {
  position: 'relative',
  alignItems: 'flex-stretch',
  height: '100%',
  width: '100%',
  mx: 'auto',
  maxWidth: '1116px',
};

Banner.displayName = 'Banner';
