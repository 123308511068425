/**
 * @jsx jsx
 * @prettier
 */

/* eslint-disable max-lines */

import {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useReducer,
} from 'react';
import { jsx } from 'theme-ui';
// import { keyframes } from '@emotion/core';

import { Flex } from '@ripperoni/components';

// const pulse = keyframes`
//   0% {
//     background-position: 0% 0%
//   }
//   100% {
//     background-position: -135% 0%
//   }
// `;

export const pulseStyle = {
  height: '100%',
  width: '100%',
  background: 'linear-gradient(-90deg, #f9f9f9 0%, #efefef 50%, #f9f9f9 100%)',
  backgroundSize: '400% 400%',
  // animation: pulse.toString(),
  animationTimingFunction: 'ease-in-out',
  animationDuration: '1.2s',
  animationIterationCount: 'infinite',
};

const areSameObj = (obj1, obj2) => {
  return Object.keys(obj1).every((key) => {
    return obj1[key] === obj2[key];
  });
};

const ProductVariantContext = createContext();

const initialState = {
  init: false,
  product: null,
};

const productVariantReducer = (state, action) => {
  switch (action.type) {
    case 'INIT_PRODUCT_STATE':
      return {
        ...state,
        init: true,
        ...action.variant,
      };

    case 'CHANGE_SELECTED_VARIANT': {
      if (!state.product.variants) {
        return {
          ...state,
        };
      }

      // NEED TO REDUCE AND EXCLUDE THIS VARIANT FROM THE PRODUCT
      const variantWithNewOption = {
        ...state.selectedOptionsMap,
        [action.variantOption.option]: action.variantOption.value,
      };

      const newVariant = state.product.variants.filter((variant) => {
        return areSameObj(variant.selectedOptionsMap, variantWithNewOption);
      });

      if (newVariant.length) {
        return {
          ...state,
          ...newVariant[0],
        };
      }

      return {
        ...state,
      };
    }

    default:
      throw new Error(`Invalid ProductContext action type: ${action.type}`);
  }
};

export const ProductVariant = ({ variantId, children }) => {
  const [productVariantState, dispatch] = useReducer(
    productVariantReducer,
    initialState
  );

  // Get new context values when the variant id changes
  const productByHandle = useMemo(() => {
    return {
      productVariantState,
      setVariantOption: (option, value) => {
        dispatch({
          type: 'CHANGE_SELECTED_VARIANT',
          variantOption: {
            option,
            value,
          },
        });
      },
    };
  }, [productVariantState]);

  useEffect(() => {
    const id = productVariantState.id || variantId;
    const isBpVariantId = id.includes('Backpack__ProductVariant');
    const isShopifyVariantId = id.includes('gid://shopify/ProductVariant/');
    const dispatchInit = (variant) =>
      dispatch({ type: 'INIT_PRODUCT_STATE', variant });
    const encodedId = encodeURIComponent(id);
    if (!isBpVariantId && !isShopifyVariantId) return;

    if (isBpVariantId) {
      fetchVariant(`/json/productVariant/id/${id}.json`).then(dispatchInit);
    }

    if (isShopifyVariantId) {
      fetchVariant(`/api/bp-variantid-from-gid?variantGid=${encodedId}`)
        .then((response) => response?.data?.bpVariantId)
        .then((id) => fetchVariant(`/json/productVariant/id/${id}.json`))
        .then(dispatchInit);
    }
  }, [productVariantState.id]);

  return (
    <ProductVariantContext.Provider value={productByHandle}>
      {productVariantState.init ? (
        children
      ) : (
        <Flex
          stretch
          center
          middle
          sx={{
            minWidth: '100%',
            width: '100%',
            height: '100%',
            borderRadius: '5px',
            ...pulseStyle,
          }}
        />
      )}
    </ProductVariantContext.Provider>
  );
};

export const useProductVariant = () => useContext(ProductVariantContext);

const fetchVariant = (url) => {
  if (!url) return;
  // console.log('url', url);

  const handleSuccess = (response) => response.json();
  const handleError = (fn) => (error) =>
    console.log('fetchVariant error:', error) && fn();

  return fetch(url)
    .then(handleSuccess)
    .then((result) => {
      console.log('result', result);
      return result;
    })
    .catch(handleError(() => null));
};
