/* eslint-disable import/no-default-export */
import React from 'react';
import { graphql } from 'gatsby';
import get from 'lodash/get';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Card } from 'theme-ui';

import {
  Box,
  Container,
  Flex,
  Grid,
  Heading,
  Link,
  Text,
} from '@ripperoni/components';
import { Seo } from '@ripperoni/marketing';

class Blogs extends React.Component {
  render() {
    const posts = get(this, 'props.data.allItems.edges');
    const hero = get(this, 'props.data.heroImage.gatsbyImageData');
    return (
      <>
        <Seo pageTitle='Blog' />
        <Container.Full>
          <Box px={[3, null, null, 15, null]} py={[8, null, null, 12, null]}>
            <GatsbyImage image={getImage(hero)} />
            <Box
              mx='auto'
              px={2}
              textAlign='center'
              maxWidth={['auto', null, null, 'auto', null]}
            >
              <Heading
                as='h1'
                fontSize={[8, null, null, 14, null]}
                my={[5, null, null, 25, null]}
              >
                Styling Guides
                <hr></hr>
              </Heading>

              <Grid
                gap={[1, null, null, 10]}
                columns={['1fr', null, null, '1fr 1fr']}
                mb={[12, null, null, 8]}
                mt={[2, null, null, 70]}
              >
                {posts.map(({ node }) => {
                  const postURL = '/blog/' + node.slug;

                  return (
                    <Box key={node.id} my={6}>
                      <Card
                        sx={{
                          padding: 2,
                          borderRadius: 4,
                          boxShadow: '0 0 10px rgba(0, 0, 0, 0.185)',
                          transform: '400ms ease-in-out',
                          ':hover': {
                            opacity: '70%',
                            maxWidth: '90%',
                            margin: 'auto',
                            transform: '500ms ease-in-out',
                          },
                        }}
                      >
                        <Link
                          to={postURL}
                          fontSize={[2, null, null, 8, null]}
                          fontWeight='light'
                          sx={{
                            ':hover': {
                              textDecoration: 'underline',
                              fontSize: '1, null, null, 7, null',
                            },
                          }}
                        >
                          <GatsbyImage image={getImage(node.banner)} />
                          <Text>{node.metaTitle}</Text>
                        </Link>
                      </Card>
                    </Box>
                  );
                })}
              </Grid>
            </Box>
          </Box>
        </Container.Full>
      </>
    );
  }
}

Blogs.propTypes = {};

export default Blogs;

export const query = graphql`
  query {
    heroImage: contentfulAsset(
      contentful_id: { eq: "4Elsinsq6xBu85Pw2ZgSGx" }
    ) {
      id
      title
      gatsbyImageData
    }
    allItems: allContentfulBlogPost {
      edges {
        node {
          banner {
            gatsbyImageData
          }
          id
          metaTitle
          slug
        }
      }
    }
  }
`;
