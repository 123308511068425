/** @jsx jsx */
import { jsx } from 'theme-ui';
import React from 'react';
import PropTypes from 'prop-types';

import { Box, Container, Heading, Text } from '@ripperoni/components';

export const CollectionHero = ({ collection, ...props }) => {
  return (
    <Container.Full
      bg='gray.1'
      position='relative'
      px={[8, null, null, 0]}
      sx={{
        height: ['125px', null, null, '270px'],
        backgroundImage:
          collection.image && collection.image.src
            ? `url('${collection.image.src}')`
            : 'none',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: ['68% 0%', null, null, 'center'],
        backgroundSize: 'cover',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
      }}
      {...props}
    >
      <Container>
        <Box px={[0, null, null, 8]}>
          {collection.description && (
            <Text
              maxWidth={['375px', null, null, '565px']}
              sx={{
                mb: 2,
                mt: 'auto',
                fontSize: ['16px', null, null, '20px'],
                fontWeight: '300',
              }}
            >
              {collection.description}
            </Text>
          )}

          <Heading
            as='h1'
            // variant='heading.h5'
            sx={{
              mt: 0,
              mb: 'auto',
              fontSize: ['24px', null, null, '42px'],
              fontWeight: 'normal',
              maxWidth: '800px',
            }}
          >
            {collection.title}
          </Heading>
        </Box>
      </Container>
    </Container.Full>
  );
};

CollectionHero.propTypes = {
  collection: PropTypes.shape({
    image: PropTypes.shape({
      src: PropTypes.string,
    }),
    title: PropTypes.string,
    description: PropTypes.string,
  }),
};
