/**
 * @jsx jsx
 * @prettier
 */
import { jsx } from 'theme-ui';

import { CmsContent, Flex } from '@ripperoni/components';

export const LinkBlock = ({
  heading,
  links,
  headingProps,
  linkProps,
  ...props
}) => {
  return (
    <Flex.Col data-comp={LinkBlock.displayName} {...props}>
      <CmsContent content={heading} />
      <CmsContent content={links} />
    </Flex.Col>
  );
};

LinkBlock.displayName = 'Link Block';
