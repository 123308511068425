/* eslint-disable max-lines */
import React from 'react';
import PropTypes from 'prop-types';

import { Box, Flex, Heading, Image, Link, Text } from '@ripperoni/components';

const CategoryTile = ({ image, title, categoryPrice, product }) => {
  const link = `/products/${product?.handle}?firstAvailable=true&variant=${image?.variant}`;

  return (
    <>
      <Box mb={4} display={['block', null, null, null, 'none']}>
        <Heading.h4>{title}</Heading.h4>
        {/* <Text variant='text.h4'>{title}</Text> */}

        {image && image.alt && (
          <Text
            maxWidth='400px'
            variant='styles.h6'
            fontWeight='light'
            lineHeight='text'
            mt={4}
            paddingRight='15px'
          >
            {image.alt}
          </Text>
        )}
      </Box>

      <Box display={['none', null, null, null, 'block']}>
        <Link to={link}>
          <Box
            // 370x450 aspect ratio
            bg='gray.0'
            position='relative'
            sx={{
              width: '100%',
              '::before': {
                content: '""',
                display: 'block',
                paddingTop: '120%',
              },
            }}
          >
            {image && (
              <Image.Contentful
                {...image}
                display='block'
                position='absolute'
                top='0'
                right='0'
                bottom='0'
                left='0'
              />
            )}
          </Box>
        </Link>

        <Flex.Col middle px={8} py={7} bg='gray.3' minHeight='94px'>
          <Text variant='text.h5' color='white'>
            {title}
          </Text>

          {image && image.alt && (
            <Text
              color='white'
              variant='styles.h6'
              fontWeight='light'
              lineHeight='text'
              mt={2}
            >
              {image.alt}
            </Text>
          )}
        </Flex.Col>
      </Box>
    </>
  );
};

CategoryTile.propTypes = {};

export default CategoryTile;
