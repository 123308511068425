/**
 * @jsx jsx
 * @prettier
 */
import { jsx } from 'theme-ui';
import React, { forwardRef } from 'react';
import { motion } from 'framer-motion';

import { useUIContext } from '@ripperoni/core';
import { ContentfulContent } from '@ripperoni/cms';
import { Box, Container, Divider, Flex } from '@ripperoni/components';

const AnimatedFlex = motion.custom(Flex);

export const SecondaryNavigation = forwardRef(
  ({ navGroups, ...props }, ref) => {
    const {
      state,
      showSecondaryNavigation,
      hideSecondaryNavigation,
    } = useUIContext();
    const { secondaryNavigation } = state;
    const activeLinks = navGroups[secondaryNavigation]?.links;
    const featuredImage = navGroups[secondaryNavigation]?.featureImage;

    return (
      <Flex
        data-comp={SecondaryNavigation.displayName}
        ref={ref}
        width='100%'
        position='fixed'
        zIndex='3'
        bg='gray.0'
        onMouseEnter={() => showSecondaryNavigation(secondaryNavigation)}
        onMouseLeave={() => hideSecondaryNavigation()}
        {...props}
      >
        <Container maxWidth='1392px' py={10} px={8}>
          <AnimatedFlex
            between
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            <Flex flexShrink={0}>
              {activeLinks?.map((linkGroup, index) => (
                <React.Fragment key={index}>
                  <ContentfulContent
                    {...linkGroup}
                    mr={index !== activeLinks.length - 1 && 15}
                    onClick={() => hideSecondaryNavigation()}
                    sx={{
                      'a:hover': {
                        textDecoration: 'underline',
                      },
                    }}
                  />

                  {index === 0 && <Divider vertical mr={[9]} color='gray.2' />}
                </React.Fragment>
              ))}
            </Flex>

            {featuredImage?.map((feature, index) => {
              if (!feature) return null;

              return (
                <ContentfulContent
                  {...feature}
                  key={`${index}${Math.random()}`}
                />
              );
            })}
          </AnimatedFlex>
        </Container>
      </Flex>
    );
  }
);

SecondaryNavigation.displayName = 'Secondary Navigation';
