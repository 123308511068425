import { useEffect, useState } from 'react';

import { isBrowser } from '@ripperoni/utilities';

const DEBUG = false;

const refreshYotpoWidgets = ({ delay = 250 }) => {
  // refresh yotpo on page navigation
  if (isBrowser && window.yotpo) {
    const t0 = requestTimeout(() => {
      if (typeof window.yotpo.refreshWidgets === 'function') {
        if (window.yotpo && window.yotpo.widgets.length) {
          DEBUG &&
            console.log('🔄 Refreshing Yotpo widgets', window.yotpo.widgets);
          window.yotpo.refreshWidgets();
        }
      }
      window.clearRequestTimeout(t0);
    }, delay);
  }
};

export const useYotpoRefreshOnMount = ({ delay = 100 }) => {
  const [mounted, setMounted] = useState(false);
  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    if (!mounted) return;
    DEBUG && console.log('🔄 Yotpo element mounted');
    refreshYotpoWidgets({ delay });
  }, [mounted]);
};
