import React from 'react';
import PropTypes from 'prop-types';

import { CmsContent } from '@ripperoni/components';


export const ProductContent = ({ productContent }) => {
  return (
    productContent.map( (content, index) => {
      return (
        <CmsContent
          key={index}
          content={content}
        />
      );
    })
  );
};

ProductContent.propTypes = {

};

export default ProductContent;
