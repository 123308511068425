/* eslint-disable react/prop-types */
/* eslint-disable max-lines */
import React from 'react';
import { graphql } from 'gatsby';

import { Modal } from '@ripperoni/components';
import { ContentfulContent } from '@ripperoni/cms';
import { Seo } from '@ripperoni/marketing';

import { ProductContext } from '@components/ProductContext';
import ProductMain from '@components/ProductMain';
import { SizeGuideTable } from '@components/SizeGuideTable';
import { FullSizeGuideTable } from '@components/FullSizeGuideTable';
import { ProductReviews as YotpoProductReviews } from '@components/YotpoProductReviews';

import seoTitle from '../hooks/seoTitle';
import seoDescription from '../hooks/seoDescription';

const Product = ({ data, location, ...props }) => {
  const {
    product,
    pageContainer,
    fullSizeGuide,
    sizeGuide,
    productsRow,
    deafaultProductRow,
  } = data;

  const { seoTitleReplacements } = seoTitle();
  const { seoDescReplacements } = seoDescription();

  const allSeoTitleReplacements = seoTitleReplacements.map((a) => a.title);
  const isSeoTitleReplaced = allSeoTitleReplacements.includes(product.title);
  const seoTitleChange = seoTitleReplacements.filter(
    (a) => a.title === product.title
  )[0];
  const replacedSeoTitle = seoTitleChange;

  const allSeoDescReplacements = seoDescReplacements.map(
    (a) => a.newDescription
  );
  const isSeoDescReplaced = allSeoDescReplacements.includes(product.title);
  const seoDescChange = seoDescReplacements.filter(
    (a) => a.title === product.title
  )[0];
  const replacedSeoDesc = seoDescChange?.newDescription;
  console.log('Why doesnt this work?', seoDescChange);

  console.log('Why in the description', replacedSeoDesc);

  return (
    <>
      <Seo
        pageTitle={
          product.title === 'Gift Card'
            ? 'Gift Card'
            : isSeoTitleReplaced
            ? Object.values(replacedSeoTitle)[1]
            : product.title
        }
        description={isSeoDescReplaced ? replacedSeoDesc : product.description}
        logo={
          product?.images?.[0]?.src ||
          product?.variants?.[0]?.images?.[0]?.src ||
          '/logo.png'
        }
      />
      {console.log('SEO INFO', <Seo description={product.description} />)}
      {/* <FullSizeGuideTable data={fullSizeGuide} /> */}
      <ProductContext product={product} location={location} {...props}>
        <ProductMain
          sizeGuideId={
            fullSizeGuide ? 'full-size-guide-modal' : 'size-guide-modal'
          }
        />
        <ContentfulContent {...pageContainer} />

        {productsRow ? (
          <ContentfulContent
            sx={{ mb: 13 }}
            {...productsRow}
            imageType='Model'
          />
        ) : (
          <ContentfulContent
            sx={{ mb: 13 }}
            {...deafaultProductRow}
            imageType='Model'
          />
        )}

        <YotpoProductReviews />

        <Modal
          hideClose={fullSizeGuide}
          modalId={fullSizeGuide ? 'full-size-guide-modal' : 'size-guide-modal'}
        >
          {fullSizeGuide ? (
            <FullSizeGuideTable data={fullSizeGuide} />
          ) : (
            <SizeGuideTable
              tableData={sizeGuide?.tableData?.tableData}
              instructions={sizeGuide?.instructions}
            />
          )}
        </Modal>
      </ProductContext>
    </>
  );
};

Product.propTypes = {};

export default Product;

export const query = graphql`
  query ($handle: String!) {
    pageContainer: contentfulPageContainer(metaHandle: { eq: $handle }) {
      ...ContentfulPageContainer
      __typename
    }
    sizeGuide: contentfulSizeGuideTable(metaTags: { eq: $handle }) {
      instructions
      tableData {
        tableData
        __typename
      }
      __typename
    }
    fullSizeGuide: contentfulFullSizeGuideTable(metaTags: { eq: $handle }) {
      sizeGuideTitle
      __typename
      quickSizeChartDescription {
        quickSizeChartDescription
        __typename
      }
      quickSizeChart1 {
        tableData
        __typename
      }
      quickSizeChart2 {
        tableData
        __typename
      }
      seeItInOtherSizes {
        ...ContentfulAtomImage
        __typename
      }
      howToTakeYourMeasurements {
        ...ContentfulAtomImage
        __typename
      }
      completeSizeChartDescription {
        completeSizeChartDescription
        __typename
      }
      completeSizeGuideChart {
        tableData
        __typename
      }
      completeSizeGuideChartXAxis
      completeSizeGuideChartYAxis
      completeSizeGuideChartZAxis
      __typename
    }
    productsRow: contentfulMolecule(metaTags: { eq: $handle }) {
      ...ContentfulMolecule
      __typename
    }
    deafaultProductRow: contentfulMolecule(
      metaHandle: { eq: "default-related-products" }
    ) {
      ...ContentfulMolecule
      __typename
    }
    product: backpackProduct(handle: { eq: $handle }) {
      # ...Product
      available
      metadata
      optionValues
      title
      handle
      description
      descriptionHtml
      type
      foreignIds
      updatedAt
      featuredImage {
        position
        src
        altText
        updatedAt
        localFile {
          id
        }
      }
      images {
        position
        src
        altText
        updatedAt
        localFile {
          id
        }
      }
      options {
        position
        title
        productId
        updatedAt
      }
      variants {
        id
        available
        foreignProductPublishedAt
        metadata
        selectedOptionsMap
        inventory
        price
        compareAtPrice
        position
        title
        productId
        foreignId
        productForeignId
        foreignProductHandle
        sku
        updatedAt
        product {
          available
          metadata
          optionValues
          title
          handle
          description
          descriptionHtml
          type
          foreignIds
          updatedAt
          featuredImage {
            position
            src
            altText
            updatedAt
            localFile {
              id
            }
          }
          images {
            position
            src
            altText
            updatedAt
            localFile {
              id
            }
          }
          options {
            position
            title
            productId
            updatedAt
          }
        }
        image {
          position
          src
          altText
          updatedAt
          localFile {
            id
          }
        }
        hoverImage {
          position
          src
          altText
          updatedAt
          localFile {
            id
          }
        }
        images {
          position
          src
          altText
          updatedAt
          localFile {
            id
          }
        }
        selectedOptions {
          title
          productOptionId
          updatedAt
          option {
            position
            title
            productId
            updatedAt
          }
        }
      }
    }
  }
`;
