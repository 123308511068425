import { addressBook } from './addressBook';
import { colors } from './colors';
import { forms } from './forms';
import { layout } from './layout';
import { loginSignup } from './loginSignup';
import { order } from './order';
import { orders } from './orders';
import { space } from './space';
import { text } from './text';


export const account = {
  space,
  colors,
  forms,
  text,
  layout,
  addressBook,
  loginSignup,
  order,
  orders,
};
