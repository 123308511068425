/* eslint-disable max-lines */
import React, { createContext, useContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';

const CollectionProductsContext = createContext();
const CollectionFilteringContext = createContext();

const collectionProductsInitialState = {
  imageType: 'Model',
  products: [],
  productCount: 0,
};

const collectionFiltersInitialState = {
  filters: [],
  activeFilters: {},
};

const collectionProductReducer = (state, action) => {
  switch (action.type) {
    case 'SET_PRODUCTS':
      return {
        ...state,
        products: action.products,
        productCount: action.productCount,
      };
    case 'SET_IMAGE_TYPE':
      return {
        ...state,
        imageType: action.imageType,
      };
    default:
      throw new Error(
        `Invalid CollectionProductsContext action type: ${action.type}`
      );
  }
};

const collectionFilterReducer = (state, action) => {
  switch (action.type) {
    case 'SET_FILTER_VALUES':
      return {
        ...state,
        filters: action.filters,
      };
    case 'SET_FILTER':
      return {
        ...state,
        activeFilters: {
          ...state.activeFilters,
          [action.filterName]: action.filterValue,
        },
      };
    case 'REMOVE_ALL_FILTERS':
      return {
        ...state,
        activeFilters: {},
      };
    case 'REMOVE_FILTER': {
      const currentFilters = { ...state.activeFilters };
      delete currentFilters[action.filterName];

      return {
        ...state,
        activeFilters: {
          ...currentFilters,
        },
      };
    }
    default:
      throw new Error(
        `Invalid CollectionFilteringContext action type: ${action.type}`
      );
  }
};

export const CollectionContext = ({ collection, children }) => {
  const [productsState, productsDispatch] = useReducer(
    collectionProductReducer,
    collectionProductsInitialState
  );
  const [filtersState, filtersDispatch] = useReducer(
    collectionFilterReducer,
    collectionFiltersInitialState
  );

  if (!collection) return null;

  // When we change collection, need to re-init filters
  useEffect(() => {
    const variants = collection.variants;
    const filters = createFilters(variants);

    const formattedFilters = Object.keys(filters).map((filterName) => {
      return {
        title: filterName,
        options: filters[filterName],
      };
    });

    filtersDispatch({
      type: 'SET_FILTER_VALUES',
      filters: formattedFilters,
    });
  }, [collection.handle]);

  // When active filters changes, we need to filter variants, then regroup products
  useEffect(() => {
    let variants = collection.variants;
    const activeFilters = filtersState.activeFilters;

    if (Object.keys(filtersState.activeFilters).length) {
      variants = variants.filter((variant) => {
        if (!variant.metadata.filter) return false;

        // prettier-ignore
        const filters = typeof variant.metadata.filter === 'string'
          ? [variant.metadata.filter]
          : variant.metadata.filter;

        const variantFilterGroups = filters.reduce((groups, group) => {
          const [name, values] = group.split('--');
          return { ...groups, [name]: values.split('|').filter(Boolean) };
        }, {});

        return Object.entries(activeFilters).every(([group, value]) => {
          return variantFilterGroups[group].includes(value);
        });
      });
    }

    productsDispatch({
      type: 'SET_PRODUCTS',
      products: groupProductTypes(variants),
      productCount: variants.length,
    });
  }, [filtersState.activeFilters]);

  const filterContextValue = {
    ...filtersState,
    collectionHandle: collection.handle,
    setActiveFilter: (filterName, filterValue) => {
      if (filtersState.activeFilters[filterName] === filterValue) {
        filtersDispatch({
          type: 'REMOVE_FILTER',
          filterName,
        });
      } else {
        filtersDispatch({
          type: 'SET_FILTER',
          filterName,
          filterValue,
        });
      }
    },
    clearFilter: (filterName) => {
      filtersDispatch({
        type: 'REMOVE_FILTER',
        filterName,
      });
    },
    clearAllFilters: () => {
      filtersDispatch({
        type: 'REMOVE_ALL_FILTERS',
      });
    },
  };

  const collectionProductsValue = {
    ...productsState,
    setImageType: (imageType) => {
      productsDispatch({
        type: 'SET_IMAGE_TYPE',
        imageType,
      });
    },
  };

  return (
    <CollectionFilteringContext.Provider value={filterContextValue}>
      <CollectionProductsContext.Provider value={collectionProductsValue}>
        {children}
      </CollectionProductsContext.Provider>
    </CollectionFilteringContext.Provider>
  );
};

export const useCollectionProducts = () =>
  useContext(CollectionProductsContext);
export const useCollectionFilter = () => useContext(CollectionFilteringContext);

// Creates collection sections by grouping variants to specified product "types"
// in this case, their product titles

const groupProductTypes = (variants) => {
  return variants.reduce((acc, curr) => {
    if (!acc[curr?.product?.handle]) {
      acc[curr?.product?.handle] = [curr];
    } else {
      acc[curr?.product?.handle] = [...acc[curr?.product?.handle], curr];
    }

    return acc;
  }, {});
};

const createFilters = (variants) => {
  return variants
    .filter(function (el) {
      return el.inventory > 10;
    })
    .reduce((acc, { metadata }) => {
      const { filter } = metadata;

      if (filter) {
        if (Array.isArray(filter)) {
          filter.forEach((filter) => {
            const [name, values] = filter.split('--');
            const filterValues = values.split('|').filter((value) => value);

            if (!acc[name]) {
              acc[name] = [...filterValues];
            } else {
              acc[name] = [...new Set([...acc[name], ...filterValues])];
            }
          });
        } else {
          const [name, values] = filter.split('--');
          const filterValues = values.split('|').filter((value) => value);

          if (!acc[name]) {
            acc[name] = [...filterValues];
          } else {
            acc[name] = [...new Set([...acc[name], ...filterValues])];
          }
        }
      }

      return acc;
    }, {});
};

CollectionContext.propTypes = {};

export default CollectionContext;
