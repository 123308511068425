export const layout = {
  content: {
    mb: [null, null, null, 12],
  },
  grid: {
    gridColumnGap: [null, null, null, 14],
  },
  inner: {
    px: [8, null, null, null, 12, null, 18],
  },
  meta: {
    mb: [7, null, null, 12],
  },
  navigation: {
    mb: [10, null, null, 12],
    item: {
      '&.active': {
        borderColor: 'primary',
      }
    },
  },
  addressBook: {
    bg: 'background',
  },
  order: {
    bg: 'background',
  },
  orders: {
    bg: 'background',
  },
};
