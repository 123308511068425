/* eslint-disable import/no-default-export */
/** @jsx jsx */
import React, { useState } from 'react';
import { jsx } from 'theme-ui';

import { Box, Container, Flex, Image, Video } from '@ripperoni/components';

import { ShopFeatureSlider } from '@components/ShopFeatureSlider';

import { Seo } from 'src/@packdigital/gatsby-theme-ripperoni-marketing/components/Seo/Seo.jsx';

const videoHero = {
  loopId: '209295359',
  mainId: '209265959',
};

const JasonKipnis = (props) => {
  const [video, setVideo] = useState(false);

  return (
    <>
      <Seo pageTitle='Jason Kipnis Hits The Batting Cage & Talks Gear' />
      <Container.Full bg='gray.0' px={8}>
        <Container py={[11, null, null, 15]} position='relative'>
          <Box>
            <Flex
              middle
              center
              flexDirection={['column', null, null, 'row']}
              maxWidth='992px'
              mx='auto'
              mb={11}
            >
              <Box
                gridArea='collab-logo'
                maxWidth='170px'
                width='100%'
                mr={12}
                mb={[5, null, null, 0]}
              >
                <Image.Static src='/dummyContent/kipnis-logo.png' />
              </Box>

              <Box gridArea='collab-copy'>
                Jason Kipnis, two-time All-Star second baseman for the Cleveland
                Indians, heads to the batting cages wearing Public Rec for an
                offseason workout despite the noise of critics commenting on the
                2016 World Series.
              </Box>
            </Flex>

            <Box position='relative'>
              <Video
                // previewUrl='https://vimeo.com/209295359'
                url='https://vimeo.com/209265959'
                type='vimeo'
              />
            </Box>
          </Box>
        </Container>
      </Container.Full>

      <ShopFeatureSlider />
    </>
  );
};

export default JasonKipnis;
