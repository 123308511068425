/**
 * @prettier
 * @jsx jsx
 */
import React from 'react';
import { jsx } from 'theme-ui';

import {
  Box,
  Button,
  CmsContent,
  Container,
  Flex,
  Grid,
  Heading,
  Link,
  Text,
} from '@ripperoni/components';

export const LandingPageFooter = ({ footerContent, ...props }) => {
  return (
    <>
      <Container my={[0, null, null, 115]}>
        <Flex
          bg={'#313033'}
          minHeight={'265px'}
          maxWidth='1400px'
          px={[0, null, null, '15px']}
          py='0px'
          // margin='auto'
          alignItems='center'
        >
          <Grid
            {...props}
            position='relative'
            gap={0}
            columns={['1fr', null, null, '65% 35%']}
          >
            <Box py={[10, null, null, null]} letterSpacing='-.03em'>
              <CmsContent
                sx={{
                  lineHeight: '1.25',
                  fontSize: ['38px', null, null, '54px'],
                }}
                ml={(-10, null, null, 10)}
                py={[1, null, null, null]}
                px={[1, null, null, 12]}
                content={footerContent}
              />
            </Box>
            <Box
              position={['relative', null, null, 'absolute']}
              bottom={['3', null, null, '6']}
              right={[-2, null, null, '-15']}
              mb={[10, null, null, null]}
              px={(2, null, null, 10)}
            >
              <Button
                variant='plain'
                sx={{
                  color: 'white',
                }}
                fontSize={['13.14px', null, null, '20px']}
                fontWeight='600'
                lineHeight='130%'
                borderBottom='1.02px solid #ffffff'
                paddingBottom='2px'
              >
                <a
                  href='https://publicrec.conciergeteam.co/'
                  className='button'
                >
                  Sign Up For Texts
                </a>
              </Button>
            </Box>
          </Grid>
        </Flex>
      </Container>
    </>
  );
};

LandingPageFooter.propTypes = {};

LandingPageFooter.displayName = 'Landing Page Footer';
