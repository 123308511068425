/**
 * @jsx jsx
 * @prettier
 */

/* eslint-disable max-lines */

import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { jsx } from 'theme-ui';
import Swiper from 'react-id-swiper';

import { Box, Container, Flex, Grid, Text } from '@ripperoni/components';

import { PrevArrow } from './PrevArrow';
import { NextArrow } from './NextArrow';

export const QuoteSlider = ({ quotes: rawQuotes }) => {
  const quotes = React.Children.toArray(rawQuotes).map(
    ({ props: { entries } }) => ({
      text: entries.find(
        ({ __typename: type }) => type === 'ContentfulAtomText'
      ),
      image: entries.find(
        ({ __typename: type }) => type === 'ContentfulAtomImage'
      ),
    })
  );

  const quotesRef = useRef(null);
  const controlsRef = useRef(null);

  const [activeIndex, setActiveIndex] = useState(0);

  const quoteParams = {
    loop: true,
    centeredSlides: true,
    slidesPerView: 1,
  };

  const controlParams = {
    slidesPerView: 1,
    noSwiping: true,
  };

  useEffect(() => {
    if (quotesRef.current !== null && quotesRef.current.swiper !== null) {
      quotesRef.current.swiper.on('slideChange', () => {
        const index = quotesRef.current.swiper.realIndex;

        setActiveIndex(index);
        controlsRef.current.swiper.slideTo(index);
      });
    }
  }, [quotesRef, controlsRef]);

  const goToSlide = useCallback((index) => {
    if (quotesRef.current !== null && quotesRef.current.swiper !== null) {
      quotesRef.current.swiper.slideTo(index);
    }
  });

  const goNext = useCallback(() => {
    if (quotesRef.current !== null && quotesRef.current.swiper !== null) {
      quotesRef.current.swiper.slideNext();
    }
  });

  const goPrev = useCallback(() => {
    if (quotesRef.current !== null && quotesRef.current.swiper !== null) {
      quotesRef.current.swiper.slidePrev();
    }
  });

  return (
    <Container.Full
      as='section'
      data-comp={QuoteSlider.displayName}
      bg='gray.0'
      mx='auto'
      py={[12, null, null, 15]}
    >
      <Flex.Col
        as={Container}
        center
        middle
        mx='auto'
        px={[7, null, null, null, 0]}
      >
        <Container
          mb={[10, null, null, 15]}
          maxWidth='870px'
          position='relative'
        >
          <Swiper {...quoteParams} ref={quotesRef}>
            {quotes.map(
              (
                {
                  text: {
                    longText: { text },
                  },
                },
                index
              ) => {
                return (
                  <Box key={index}>
                    <Text
                      variant='text.h2'
                      textAlign='center'
                      maxWidth='730px'
                      fontSize={[7, null, null, 9]}
                      mx='auto'
                    >
                      &ldquo;{text}&rdquo;
                    </Text>
                  </Box>
                );
              }
            )}
          </Swiper>

          <PrevArrow
            onClick={goPrev}
            sx={{
              display: ['none', null, null, 'block'],
            }}
          />

          <NextArrow
            onClick={goNext}
            sx={{
              display: ['none', null, null, 'block'],
            }}
          />
        </Container>

        <Grid
          as={Container.Small}
          columns={`repeat(${quotes.length}, 1fr)`}
          columnGap={13}
          maxWidth='920px'
          sx={{
            display: ['none', null, null, 'grid'],
          }}
        >
          {quotes.map(({ image: { alt, primaryImage } }, index) => {
            return (
              <Flex
                key={index}
                height='28px'
                onClick={() => goToSlide(index + 1)}
                center
                middle
                sx={{
                  transition: 'all .2s ease-in',
                  opacity: activeIndex === index ? 1 : 0.35,
                  cursor: 'pointer',
                  '&:hover': {
                    opacity: [null, null, null, '0.75'],
                  },
                }}
              >
                <img
                  alt={alt}
                  src={primaryImage.file.url}
                  sx={{
                    maxWidth: '100%',
                    maxHeight: '100%',
                  }}
                />
              </Flex>
            );
          })}
        </Grid>

        {/* MOBILE IMAGE CONTROLS */}
        <Box
          display={['block', null, null, 'none']}
          width='100%'
          position='relative'
        >
          <Swiper {...controlParams} ref={controlsRef}>
            {quotes.map(({ image: { alt, primaryImage } }, index) => {
              return (
                <Flex
                  key={index}
                  height='28px'
                  onClick={() => goToSlide(index + 1)}
                  center
                  middle
                >
                  <img
                    alt={alt}
                    src={primaryImage.file.url}
                    sx={{
                      maxWidth: '100%',
                      maxHeight: '100%',
                    }}
                  />
                </Flex>
              );
            })}
          </Swiper>

          <NextArrow onClick={goNext} />

          <PrevArrow onClick={goPrev} />

          <Flex center>
            {quotes.map((_, index) => {
              return (
                <Box
                  key={index}
                  sx={{
                    width: '8px',
                    height: '8px',
                    borderRadius: '50%',
                    bg: index === activeIndex ? 'gray.3' : 'gray.2',
                    mt: 8,
                    mx: 2,
                  }}
                />
              );
            })}
          </Flex>
        </Box>
      </Flex.Col>
    </Container.Full>
  );
};

QuoteSlider.displayName = 'QuoteSlider';

QuoteSlider.propTypes = {};
