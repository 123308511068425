/**
 * @prettier
 */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Swiper from 'react-id-swiper';

import { isBrowser } from '@ripperoni/utilities';
import { Box, Container, Heading } from '@ripperoni/components';

import { PrevArrow } from './PrevArrow';
import { NextArrow } from './NextArrow';

export const TileSlider = ({
  heading,
  children,
  slides = 3,
  slideOnDesktop = false,
  arrows = false,
  ...props
}) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const sliderRef = useRef(null);
  const sliderParams = {
    loop: true,
    slidesPerView: 1.25,
    spaceBetween: 20,
    centerSlides: true,
    allowSlidePrev: true,
    allowSlideNext: true,
    observeSlideChildren: true,
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
    },
    breakpoints: {
      600: {
        loop: slideOnDesktop,
        slidesPerView: 2,
      },
      768: {
        spaceBetween: 24,
        slidesPerView: slides,
        allowSlidePrev: slideOnDesktop,
        allowSlideNext: slideOnDesktop,
      },
    },
  };

  useEffect(() => {
    if (sliderRef.current !== null && sliderRef.current.swiper !== null) {
      sliderRef.current.swiper.on('slideChange', () => {
        const index = sliderRef.current.swiper.realIndex;
        setActiveIndex(index);
      });

      sliderRef.current.swiper.loopDestroy();
      sliderRef.current.swiper.loopCreate();
    }

    if (isBrowser) {
      const reinitSlide = setInterval(() => {
        window.dispatchEvent(new Event('resize'));
        clearInterval(reinitSlide);
      }, 500);
    }
  }, [sliderRef]);

  const goNext = useCallback(() => {
    if (sliderRef.current !== null && sliderRef.current.swiper !== null) {
      sliderRef.current.swiper.slideNext();
    }
  });

  const goPrev = useCallback(() => {
    if (sliderRef.current !== null && sliderRef.current.swiper !== null) {
      sliderRef.current.swiper.slidePrev();
    }
  });

  return (
    <Container.Full px={8} {...props}>
      <Container
        position='relative'
        sx={{
          '.gatsby-image-wrapper img': {
            opacity: '1 !important',
          },
          '.gatsby-image-wrapper img:hover': {
            opacity: [null, null, null, '0.8 !important'],
          },
        }}
      >
        {heading && (
          <Heading
            variant='heading.h2'
            textAlign='center'
            fontSize={[7, null, null, 9]}
            mb={[9, null, null, 11]}
          >
            {heading}
          </Heading>
        )}

        <Swiper {...sliderParams} ref={sliderRef}>
          {children}
        </Swiper>

        <Box mt={7} display={['block', null, null, 'none']}>
          {React.Children.toArray(children).map((c, i) => {
            return (
              <Box
                key={i}
                width='9px'
                height='9px'
                bg={activeIndex === i ? 'gray.3' : 'gray.2'}
                display='inline-block'
                mr={4}
                sx={{
                  borderRadius: '50%',
                }}
              />
            );
          })}
        </Box>

        {arrows ? (
          <>
            <PrevArrow onClick={goPrev} />
            <NextArrow onClick={goNext} />
          </>
        ) : null}
      </Container>
    </Container.Full>
  );
};

TileSlider.propTypes = {
  heading: PropTypes.arrayOf(PropTypes.element),
};

TileSlider.displayName = 'Tile Slider';

export default TileSlider;
