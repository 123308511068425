/* eslint-disable max-lines */
import React from 'react'
import PropTypes from 'prop-types'

import { Box, Flex, Image, Link, Price, Text } from '@ripperoni/components';

import {
  useProductVariant,
} from '@components/ProductVariantContext/ProductVariantContext.jsx';
import { colorMapping } from '@components/ProductOptions/inputStyle';

import { ActivityIcons } from './ActivityIcons';

export const CompareTile = ({ compareProduct }) => {
  const { productVariantState, setVariantOption } = useProductVariant();

  const {
    productImage,
    productTitle,
    description,
    activity,
  } = compareProduct;

  const {
    product,
    foreignId,
    selectedOptionsMap,
    price,
  } = productVariantState;

  const link = product && `/products/${product?.handle}?variant=${foreignId.split('/').slice(-1)}`;

  const {
    optionValues,
  } = product || {};

  return (
    <Box
      px={9}
      py={10}
      bg='gray.1'
    >
      <Image.Contentful
        {...productImage}
      />

      {/* TITLE */}
      <Flex
        between
        mb={3}
      >
        <Text
          variant='text.h4'
          textTransform='uppercase'
        >
          {productTitle}
        </Text>

        <Price
          as={Text}
          variant='text.h4'
          textTransform='uppercase'
        >
          {price / 100}
        </Price>
      </Flex>

      {/* DESCRIPTION */}
      <Text
        mb={9}
      >
        {description}
      </Text>

      {/* ACTIVITIES */}
      <Box
        mb={9}
      >
        <ActivityIcons activities={activity.split(',').map(activity => activity.toLowerCase())} />
      </Box>

      {/* FEATURES */}
      <Box
        as='ul'
        mb={7}
        sx={{
          listStyle: 'none',
          p: 0
        }}
      >
        {
          [ 'fit', 'weight', 'fabric', 'stretch', 'waist', 'fly' ].map( feat => {
            return (
              <Box
                key={feat + Math.random()}
                as='li'
                mb={4}
              >
                <Text
                  fontWeight='semi'
                  display='inline'
                  textTransform='capitalize'
                >
                  {feat}:&nbsp;
                </Text>

                <Text
                  color='gray.2'
                  display='inline'
                  fontWeight='light'
                >
                  {compareProduct[feat]}
                </Text>
              </Box>
            );
          })
        }

        <Box
          as='li'
          mb={4}
        >
          <Text
            fontWeight='semi'
            display='inline'
          >
            Color:&nbsp;
          </Text>

          <Text
            color='gray.2'
            display='inline'
            fontWeight='light'
          >
            {selectedOptionsMap?.Color}
          </Text>
        </Box>
      </Box>

      {/* SWATCHES */}
      <Flex
        middle
        pl={1}
      >
        {
          optionValues?.Color.map((color, i) => {
            return (
              <Box
                onClick={() => {
                  setVariantOption('Color', color);
                }}
                key={i}
                width='14px'
                height='14px'
                bg={colorMapping[color] || 'gray.2'}
                mr={4}
                position='relative'
                sx={{
                  borderRadius: '50%',
                  '::before': {
                    pointerEvents: ' none',
                    content: '""',
                    borderRadius: '50%',
                    position: 'absolute',
                    width: '100%',
                    height: '100%',
                    border: 'thin solid',
                    borderColor: selectedOptionsMap?.Color === color ? colorMapping[color] : 'transparent',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    p: '3px',
                  }
                }}
              />
            );
          })
        }
      </Flex>

      <Link
        to={link}
        sx={{
          variant: 'buttons.primary',
          textTransform: 'uppercase',
          mt: 9
        }}
      >
        SHOP {productTitle}
      </Link>

    </Box>
  );
};

CompareTile.propTypes = {

}
