import React from 'react';
import PropTypes from 'prop-types';

import { Box, Flex, CmsContent, Image, Link, Text } from '@ripperoni/components';


export const CollabFavoriteTile = ({
  image,
  link,
  className
}) => {
  return (
    <Flex.Col
      data-comp={CollabFavoriteTile.displayName}
      center
      maxWidth='520px'
      width='100%'
      mx='auto'
      className={className}
    >
      <Box
        display='block'
        width='100%'
        mb={5}
      >
        <CmsContent content={image} />
      </Box>

      <Text
        textAlign={'left'}
        width='100%'
      >
        Shop the&nbsp;

        <CmsContent
          content={link}
          variant='links.text'
        />
      </Text>
    </Flex.Col>
  );
};

CollabFavoriteTile.displayName = 'CollabFavoriteTile'

CollabFavoriteTile.propTypes = {

};

