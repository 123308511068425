/**
 * @prettier
 */
import React, { useState } from 'react';

import { Button, Flex } from '@ripperoni/components';
import {
  AccountLayout,
  AddressBookHeader,
  AddressBookRow,
  AddressForm,
  useCustomerContext,
} from '@ripperoni/account';

export const AddressBook = (props) => {
  const { state, createAddress } = useCustomerContext();
  const [addFormActive, setAddFormActive] = useState(false);

  return (
    <AccountLayout
      variant='account.layout.addressBook'
      loggedIn={true}
      {...props}
    >
      <AddressBookHeader />

      <Flex.Col>
        {state.customer.addresses.map((address) => (
          <AddressBookRow address={address} key={address.id} />
        ))}

        {!addFormActive ? (
          <Flex
            variant='account.addressBook.row'
            sx={{ justifyContent: 'center' }}
          >
            <Button.Plain
              color='text'
              bg='transparent'
              width='full'
              weight='semi'
              onClick={() => setAddFormActive(true)}
            >
              + ADD NEW ADDRESS
            </Button.Plain>
          </Flex>
        ) : (
          <AddressForm
            variant='account.addressBook.form'
            title='Add A New Address'
            action={createAddress}
            cancelToggle={() => setAddFormActive(false)}
          />
        )}
      </Flex.Col>
    </AccountLayout>
  );
};
