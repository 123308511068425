module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-use-query-params/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/@packdigital/gatsby-theme-ripperoni-message-bus/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx"],"defaultLayouts":{},"gatsbyRemarkPlugins":[],"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/Users/me/Work/PublicRec-main/website","JSFrontmatterEngine":false,"engines":{}},
    },{
      plugin: require('../node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[],"mergeSecurityHeaders":false,"allPageHeaders":["Access-Control-Allow-Origin: *","Content-Security-Policy: connect-src: location.truevault.com; font-src 'self' data: acsbace.com acsbapp.com fonts.gstatic.com fonts.googleapis.com https://publicrec.kustomer.help https://cdn.kustomerapp.com https://s3.global-e.com staticw2.yotpo.com; frame-ancestors 'self' 35.196.122.47 67.207.94.120 accessibe.com acsbace.com https://tracking.sezzle.com https://webservices.global-e.com; script-src: polaris.truevaultcdn.com; upgrade-insecure-requests;","Vary: Origin","x-content-type-options: nosniff","x-frame-options: DENY"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-helmet-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.publicrec.com","noTrailingSlash":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"start_url":"/","display":"minimal-ui","name":"Public Rec","short_name":"Public Rec","description":"Now comfort looks good.","theme_color":"#141414","background_color":"#ffffff","icon":"src/assets/images/logo.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"6cb456af35aa16b6913e53bc30ccafc2"},
    },{
      plugin: require('../node_modules/@packdigital/gatsby-theme-ripperoni-core/gatsby-browser.js'),
      options: {"plugins":[],"metadata":{"site":{"url":"https://www.publicrec.com","siteUrl":"https://www.publicrec.com","shopifyUrl":"https://shop.publicrec.com","name":"Public Rec","author":"Pack Digital","description":"Now comfort looks good.","color":"#141414","bgColor":"#ffffff","logo":"src/assets/images/logo.png","supportEmail":"support@publicrec.com"},"seo":{"title":"","author":"","description":""},"social":{"facebook":"PublicRecApparel","twitter":"public_rec","instagram":"publicrec"},"date":{"format":"mmm d, yyyy"},"money":{"trimTrailingZeros":true}}},
    },{
      plugin: require('../node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/@packdigital/gatsby-theme-ripperoni-marketing/gatsby-browser.js'),
      options: {"plugins":[],"metadata":{"site":{"url":"https://www.publicrec.com","siteUrl":"https://www.publicrec.com","shopifyUrl":"https://shop.publicrec.com","name":"Public Rec","author":"Pack Digital","description":"Now comfort looks good.","color":"#141414","bgColor":"#ffffff","logo":"src/assets/images/logo.png","supportEmail":"support@publicrec.com"},"seo":{"title":"","author":"","description":""},"social":{"facebook":"PublicRecApparel","twitter":"public_rec","instagram":"publicrec"},"date":{"format":"mmm d, yyyy"},"money":{"trimTrailingZeros":true}},"googleTagManager":{"id":"GTM-T4P2KXP","defaultDataLayer":{"platform":"Headless PWA Storefront"},"includeInDevelopment":true},"googleAnalytics":{},"facebookPixel":{}},
    },{
      plugin: require('../node_modules/@packdigital/gatsby-theme-ripperoni-cart/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/@packdigital/gatsby-theme-ripperoni-account/gatsby-browser.js'),
      options: {"plugins":[],"metadata":{"site":{"url":"https://www.publicrec.com","siteUrl":"https://www.publicrec.com","shopifyUrl":"https://shop.publicrec.com","name":"Public Rec","author":"Pack Digital","description":"Now comfort looks good.","color":"#141414","bgColor":"#ffffff","logo":"src/assets/images/logo.png","supportEmail":"support@publicrec.com"},"seo":{"title":"","author":"","description":""},"social":{"facebook":"PublicRecApparel","twitter":"public_rec","instagram":"publicrec"},"date":{"format":"mmm d, yyyy"},"money":{"trimTrailingZeros":true}}},
    },{
      plugin: require('../node_modules/@packdigital/gatsby-theme-ripperoni-store/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
