/* eslint-disable max-lines */
/* @jsx jsx */
import { jsx } from 'theme-ui';
import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { Box, Flex, Image, Svg } from '@ripperoni/components';

import PlayButton from '@assets/svgs/PlayButton.svg';

import { SwiperSlider } from '../SwiperSlider';
import { PrevArrow } from './PrevArrow';
import { NextArrow } from './NextArrow';

const ProductImages = ({ product, selectedVariant }) => {
  const [productImages, setProductImages] = useState([]);
  // set to 1 because swiper set to loop
  const [activeIndex, setSlideIndex] = useState(0);
  const [swiper, setSwiper] = useState(null);

  const productImageSliderParams = useRef({
    autoplay: false,
    roundLengths: true,
    init: false,
    loop: true,
    loopedSlides: 3,
    slidesPerView: 1,
    observer: true,
    observeParents: true,
    observeSlideChildren: true,
  });

  useEffect(() => {
    let productImages = [];

    if (product.images.length) {
      productImages = product.images;
    } else {
      productImages = selectedVariant?.images;
    }

    productImages = productImages
      ? productImages.filter(({ altText }) => {
          if (altText?.includes('fit')) {
            const imageFit = altText
              .split('fit')[1]
              .split('-')[1]
              .split(']')[0]
              .trim();
            const selectedFit =
              selectedVariant?.selectedOptionsMap?.Fit.toLowerCase().trim();
            return imageFit === selectedFit;
          }
          if (altText?.includes('style') && altText?.includes('inseam')) {
            const imageStyle = altText
              .split('style')[1]
              .split('-')[1]
              .split(']')[0]
              .trim();
            const imageInseam = altText
              .split('inseam')[1]
              .split('-')[1]
              .split(']')[0]
              .trim();
            const selectedInseam =
              selectedVariant?.selectedOptionsMap?.Inseam.toLowerCase().trim();
            const selectedStyle =
              selectedVariant?.selectedOptionsMap?.Style.toLowerCase().trim();
            return (
              imageStyle === selectedStyle && imageInseam === selectedInseam
            );
          }
          if (altText?.includes('style')) {
            const imageStyle = altText
              .split('style')[1]
              .split('-')[1]
              .split(']')[0]
              .trim();
            const selectedStyle =
              selectedVariant?.selectedOptionsMap?.Style.toLowerCase().trim();
            return imageStyle === selectedStyle;
          }
          if (altText?.includes('inseam')) {
            const imageInseam = altText
              .split('inseam')[1]
              .split('-')[1]
              .split(']')[0]
              .trim();
            const selectedInseam =
              selectedVariant?.selectedOptionsMap?.Inseam.toLowerCase().trim();
            return imageInseam === selectedInseam;
          }

          if (altText?.includes('flatlay')) {
            return false;
          }

          return true;
        })
      : [];

    setProductImages(productImages);

    // if (swiper) {
    //   setSlideIndex(0);
    //   swiper?.slideToLoop(0, 0);
    //   swiper?.loopDestroy();
    // }
  }, [product, selectedVariant]);

  // Manually init swiper
  useEffect(() => {
    if (!swiper || swiper.initialized) return;

    swiper.on('resize', (swiper) => {
      swiper.update();
    });
    swiper.on('slideChange', (swiper) => {
      setSlideIndex(swiper.realIndex);
    });

    swiper.init();

    return () => {
      swiper?.destroy();
    };
  }, [swiper]);

  useEffect(() => {
    // recreate looped slides after swatch change
    if (swiper) {
      swiper?.loopCreate();
      swiper?.update();
    }
  }, [productImages]);

  if (!productImages) return null;

  return (
    <Flex flexDirection={['column-reverse', null, null, null, 'row']}>
      <Flex
        mr={[0, null, null, null, 8]}
        flexShrink={0}
        display={['none', null, null, 'flex']}
        flexDirection={['row', null, null, null, 'column']}
        maxHeight='805px'
        maxWidth='65px'
        width='100%'
        sx={{
          overflowY: 'scroll',
          msOverflowStyle: 'none',
          scrollbarWidth: 'none',
          '&::-webkit-scrollbar': {
            display: 'none',
          },
        }}
      >
        {
          // Each Image Thumb
          productImages.map((image, index) => (
            <ProductImageThumb
              activeIndex={activeIndex}
              swiper={swiper}
              key={index + '.Thumbnail'}
              index={index}
              {...image}
            />
          ))
        }
      </Flex>

      <Box
        position='relative'
        zIndex='0'
        ml='-1px'
        mr='-1px'
        width='100%'
        pt={['120%', null, null, null, 0]}
      >
        <Box
          position={['absolute', null, null, null, 'static']}
          top={[0, null, null, null, 'auto']}
          right={[0, null, null, null, 'auto']}
          bottom={[0, null, null, null, 'auto']}
          left={[0, null, null, null, 'auto']}
        >
          <SwiperSlider
            params={productImageSliderParams.current}
            className='ProductImages'
            ref={([swiperEl, swiper]) => setSwiper(swiper)}
          >
            {
              // Each Large Image
              productImages.map(({ id, src, altText }, index) => {
                return (
                  <Flex
                    middle
                    center
                    key={`${src}.Slide`}
                    width='100%'
                    position='relative'
                    zIndex={1}
                  >
                    {index === activeIndex &&
                      altText?.includes('[video') &&
                      getVimeoId(altText) && (
                        <iframe
                          src={`https://player.vimeo.com/video/${getVimeoId(
                            altText
                          )}?api=1;title=0&amp;byline=0&amp;portrait=0&amp;background=1&amp;loop=1&amp;autoplay=1`}
                          frameBorder='0'
                          title='A Video'
                          webkitallowfullscreen=''
                          mozallowfullscreen=''
                          allowFullScreen=''
                          style={{
                            position: 'absolute',
                            width: '100%',
                            height: '100%',
                            backgroundColor: '#ffffff',
                            pointerEvents: 'none',
                          }}
                        />
                      )}

                    <Image.Static
                      src={src}
                      alt={selectedVariant.product?.title}
                      display='block'
                      width='100%'
                    />
                  </Flex>
                );
              })
            }
          </SwiperSlider>
        </Box>
        <Box
          sx={{
            // Change value at index 0 to 'block' to allow navigation arrows to be displayed on mobile.
            display: ['none', null, null, null, 'block'],
          }}
        >
          <PrevArrow onClick={() => swiper?.slidePrev()} />
          <NextArrow onClick={() => swiper?.slideNext()} />
        </Box>

        <Box
          sx={{
            display: ['flex', null, null, null, 'none'],
            flexDirection: 'row',
            alignContent: 'center',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 2,
            height: '30px',
          }}
        >
          {productImages.map((img, index) => {
            return (
              <Box
                key={index}
                width='6px'
                height='6px'
                bg={activeIndex === index ? '#B9B9B9' : 'transparent'}
                display='inline-block'
                mr='9px'
                sx={{
                  borderRadius: '50%',
                  border: '1px solid #B9B9B9',
                }}
              />
            );
          })}
        </Box>
      </Box>
    </Flex>
  );
};

const ProductImageThumb = ({
  id,
  src,
  altText,
  swiper,
  activeIndex = 0,
  index,
}) => {
  return (
    <Box
      width='100%'
      maxWidth={['100%', null, null, null, '65px']}
      minWidth='65px'
      minHeight='75px'
      onClick={() => {
        swiper?.slideToLoop(index, 300);
      }}
      mb={[0, null, null, null, 8]}
      mt={[8, null, null, null, 0]}
      mx={[1, null, null, null, 0]}
      position='relative'
      zIndex='0'
      sx={{
        cursor: 'pointer',
      }}
    >
      {altText?.includes('[video') && getVimeoId(altText) && (
        <Flex
          middle
          center
          position='absolute'
          bg=''
          top='0'
          left='0'
          right='0'
          bottom='0'
          zIndex={1}
        >
          <Svg as={PlayButton} height='32px' />
        </Flex>
      )}

      <Image.Static
        src={src}
        alt='Product Thumbnail'
        display='block'
        sx={{
          opacity: index === activeIndex ? '1' : '.75',
          border: '1px solid',
          borderColor: index === activeIndex ? 'gray.2' : 'gray.1',
          transition: 'border-color .15s ease-in',
          '&:hover': {
            borderColor: 'gray.2',
          },
        }}
      />
    </Box>
  );
};

// https://stackoverflow.com/questions/2916544/parsing-a-vimeo-id-using-javascript
const getVimeoId = (url) => {
  // Look for a string with 'vimeo', then whatever, then a
  // forward slash and a group of digits.
  var match = /vimeo.*\/(\d+)/i.exec(url);

  // If the match isn't null (i.e. it matched)
  if (match) {
    // The grouped/matched digits from the regex
    return match[1];
  }
};

ProductImages.propTypes = {
  product: PropTypes.object,
  selectedVariant: PropTypes.object.isRequired,
};

// eslint-disable-next-line
export default ProductImages;
