/**
 * @prettier
 */
/* eslint-disable max-lines */
import React, { forwardRef, useEffect, useRef, useState } from 'react';
import { AnimatePresence, motion, useViewportScroll } from 'framer-motion';
import { graphql, useStaticQuery } from 'gatsby';
import groupBy from 'lodash/groupBy';
import map from 'lodash/map';
import keyBy from 'lodash/keyBy';

import { Box, MQ, Overlay } from '@ripperoni/components';
import { getContent } from '@ripperoni/cms';
import { useUIContext } from '@ripperoni/core';

import { Promobar } from '@components/Promobar';
import { Navigation, SecondaryNavigation } from '@components/Navigation';
import {
  NavigationMobile,
  SecondaryNavigationMobile,
} from '@components/NavigationMobile';

const AnimatedPromobar = motion.custom(Promobar);
const AnimatedSecondaryNavigation = motion.custom(SecondaryNavigation);

export const Header = forwardRef((props, ref) => {
  const { state } = useUIContext();
  const { scrollY } = useViewportScroll();
  const [promobarVisible, setPromobarVisible] = useState(true);
  const promobarRef = useRef(null);
  const globalePromobarRef = useRef(null);
  const navigationRef = useRef(null);
  const {
    primary: { lookup, entries },
  } = useStaticQuery(staticQuery);

  useEffect(() => {
    const updatePromobarVisibility = (latest) =>
      setPromobarVisible(latest <= 0);
    const unsubscribeScroll = scrollY.onChange(updatePromobarVisibility);

    return () => unsubscribeScroll();
  }, []);

  const parseAndGetContent = (key) => (lookup, entries) => {
    const lookups = groupBy(lookup, 'type');
    return getContent(lookups.content, entries)[key];
  };

  const parseAndGetLinks = parseAndGetContent('links');
  const parseAndGetHeading = parseAndGetContent('heading');
  const parseAndGetFeatureImage = parseAndGetContent('featureImage');
  const links = parseAndGetLinks(lookup, entries);

  const primaryNavItems = links.filter(Boolean).map(({ lookup, entries }) => ({
    heading: parseAndGetHeading(lookup, entries)[0],
    featureImage: parseAndGetFeatureImage(lookup, entries),
    links: parseAndGetLinks(lookup, entries),
  }));

  const headings = map(primaryNavItems, 'heading');
  const navGroups = keyBy(primaryNavItems, 'heading.text');

  const getSecondaryNavigationOffsetTop = () => {
    const navigationOffset = navigationRef?.current?.offsetHeight || 0;
    const globaleCountry = window?.GlobalE?.Country;

    if (globaleCountry && globaleCountry !== 'US') {
      return navigationOffset + globalePromobarRef?.current?.offsetHeight || 0;
    }

    if (promobarVisible) {
      return navigationOffset + promobarRef?.current?.offsetHeight || 0;
    }

    return navigationOffset;
  };

  return (
    <Box
      data-comp={Header.displayName}
      as='header'
      ref={ref}
      bg='white'
      width='100vw'
      position='fixed'
      top={0}
      zIndex={2}
      boxShadow='header'
      {...props}
    >
      <Box className='globale-hide'>
        <AnimatePresence>
          {promobarVisible && (
            <AnimatedPromobar
              ref={promobarRef}
              variants={variants}
              initial='initial'
              animate='completed'
              exit='initial'
            />
          )}
        </AnimatePresence>
      </Box>

      <Box className='globale-show' ref={globalePromobarRef}>
        <Box id='GlobaleFreeShippingBannerContainer' />
      </Box>

      <MQ
        comps={[
          <NavigationMobile headings={headings} key='mobile-navigation' />,
          null,
          null,
          null,
          <Navigation
            ref={navigationRef}
            headings={headings}
            key='desktop-navigation'
          />,
        ]}
      />

      <MQ
        comps={[
          <AnimatePresence key='mobile-secondary-navigation'>
            {state.mobileNavigation && (
              <SecondaryNavigationMobile
                headings={headings}
                navGroups={navGroups}
                key='mobile-secondary-navigation'
              />
            )}

            {state.mobileNavigation && (
              <Overlay zIndex={0} onClick={state.hideMobileNavigation} />
            )}
          </AnimatePresence>,
          null,
          null,
          null,
          <AnimatePresence key='desktop-secondary-navigation'>
            {state.secondaryNavigation && (
              <AnimatedSecondaryNavigation
                navGroups={navGroups}
                boxShadow='header'
                initial={false}
                animate={{
                  top: getSecondaryNavigationOffsetTop(),
                }}
                exit='initial'
                transition={{
                  duration: 0.3,
                  ease: 'linear',
                }}
              />
            )}
          </AnimatePresence>,
        ]}
      />
    </Box>
  );
});

Header.displayName = 'Header';

const staticQuery = graphql`
  {
    primary: contentfulMolecule(metaHandle: { eq: "primary-navigation" }) {
      ...ContentfulMolecule
    }
  }
`;

const variants = {
  initial: {
    y: '-100%',
    opacity: 0,
    height: '0px',
    transition: {
      ease: 'anticipate',
    },
  },
  completed: {
    y: 0,
    opacity: 1,
    height: '100%',
    transition: {
      ease: 'anticipate',
    },
  },
};
