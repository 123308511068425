import React, { forwardRef, useCallback, useRef } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Swiper from 'react-id-swiper';
import { Autoplay, Swiper as RealSwiper } from 'swiper';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

import { Box, Flex, Text } from '@ripperoni/components';

import { PrevArrow } from './PrevArrow';
import { NextArrow } from './NextArrow';

export const Promobar = forwardRef((props, ref) => {
  const { promobar } = useStaticQuery(staticQuery);
  const { color, enablePromobar, messages } = promobar;

  const params = useRef({
    loop: true,
    slidesPerView: 1,
    autoplay: {
      delay: 6000,
      disableOnInteraction: false,
    },
  });

  const sliderRef = useRef(null);

  const goNext = useCallback(() => {
    if (sliderRef.current !== null && sliderRef.current.swiper !== null) {
      sliderRef.current.swiper.slideNext();
    }
  });

  const goPrev = useCallback(() => {
    if (sliderRef.current !== null && sliderRef.current.swiper !== null) {
      sliderRef.current.swiper.slidePrev();
    }
  });

  RealSwiper.use(Autoplay);

  if (!enablePromobar || !messages.length) return null;

  if (messages && messages.length === 1) {
    return (
      <Flex
        data-comp={Promobar.displayName}
        ref={ref}
        middle
        center
        bg={color}
        position='relative'
        zIndex={4}
        sx={{
          height: ['30px', null, null, null, '40px'],
        }}
        {...props}
      >
        {messages && messages[0].message && messages[0].message.message && (
          <Text
            variant='text.h6'
            color='white'
            sx={{
              fontSize: ['11px', null, null, null, '14px'],
              textAlign: 'center',
            }}
            letterSpacing='.02rem'
          >
            {documentToReactComponents(JSON.parse(messages[0].message.message))}
          </Text>
        )}
      </Flex>
    );
  }

  return (
    <Flex
      data-comp={Promobar.displayName}
      ref={ref}
      middle
      center
      bg='black'
      position='relative'
      zIndex={4}
      sx={{
        height: ['30px', null, null, null, '40px'],
        paddingLeft: ['10px', null, null, '24px', null],
        paddingRight: ['10px', null, null, '24px', null],
      }}
      {...props}
    >
      <PrevArrow onClick={goPrev} />

      <Box width='100%'>
        <Swiper {...params.current} ref={sliderRef}>
          {messages &&
            messages.map((msg, index) => {
              if (!msg || !msg.message || !msg.message.message) return null;

              return (
                <Text
                  key={index}
                  variant='text.h6'
                  color='white'
                  width='100%'
                  letterSpacing='.02rem'
                  px={8}
                  sx={{
                    fontSize: ['11px', null, null, null, '14px'],
                    textAlign: 'center',
                    a: {
                      color: 'white',
                    },
                  }}
                >
                  {documentToReactComponents(JSON.parse(msg.message.message))}
                </Text>
              );
            })}
        </Swiper>
      </Box>

      <NextArrow onClick={goNext} />
    </Flex>
  );
});

Promobar.displayName = 'Promobar';

const staticQuery = graphql`
  {
    promobar: contentfulPromobar(title: { eq: "Promobar" }) {
      color
      enablePromobar
      messages {
        message {
          message: raw
        }
      }
    }
  }
`;
