/**
 * @prettier
 */
import React from 'react';

import {
  Box,
  CmsContent,
  Container,
  Flex,
  Grid,
  Text,
} from '@ripperoni/components';

export const FeatureBlock = ({
  title,
  icon,
  image,
  details,
  caption,
  link,
  ...props
}) => {
  return (
    <>
      <Container.Full px={[0, null, null, 8]}>
        <Container py={[0, null, null, 14]}>
          <Grid
            bg='gray.0'
            areas={[
              `
              "sizing-image"
              "sizing-icon"
              "sizing-title"
              "sizing-text"
              "sizing-link"
            `,
              null,
              null,
              null,
              `
              "sizing-image sizing-image sizing-icon"
              "sizing-image sizing-image sizing-title"
              "sizing-image sizing-image sizing-text"
              "sizing-image sizing-image sizing-sizes"
            `,
            ]}
            columns={['1fr', null, null, null, '2fr 600px']}
            rows={['repeat(3, auto)', null, null, null, 'repeat(3, auto)']}
            columnGap={[null, null, null, 9, null, 13]}
          >
            <Box
              gridArea='sizing-image'
              mt={0}
              mb={0}
              alignSelf='center'
              // mx={[0, null, null, '10px']}
            >
              <CmsContent width={'100%'} content={image} />
            </Box>

            <Flex.Col px={[10]} gridArea='sizing-title' bottom>
              <CmsContent
                textAlign={['left', null, null, 'left']}
                mt={['70px', null, null, '110px']}
                mb={10}
                width={['60px', null, null, '70px']}
                content={icon}
              />
              <CmsContent
                fontSize={'28px'}
                mb={4}
                fontWeight={'600'}
                color={'#525B65'}
                content={title}
              />

              <CmsContent
                textAlign={['left', null, null, 'left']}
                fontSize={['22px', null, null, '28px']}
                fontWeight={'300'}
                color={'#525B65'}
                width={'90%'}
                mt={5}
                mb={8}
                as={Text}
                content={details}
              />
              <CmsContent
                color={'#525B65'}
                textAlign={['left', null, null, 'left']}
                fontSize={'18px'}
                width={'fit-content'}
                my={10}
                // mb={5}
                borderBottom={'2px solid #525B65'}
                fontWeight={'600'}
                fontFamily={'Messina Sans'}
                textTransform={'uppercase'}
                letterSpacing={'2px'}
                // textDecoration={'underline'}
                as={Text}
                content={link}
              />
            </Flex.Col>
          </Grid>
        </Container>
      </Container.Full>
    </>
  );
};

FeatureBlock.propTypes = {};

FeatureBlock.displayName = 'FeatureBlock';
