import React, { useState } from 'react';

import { isBrowser } from '@ripperoni/utilities';
import {
  Box,
  Button,
  Flex,
  FlexCol,
  Loader,
  Text,
} from '@ripperoni/components';
import { useUIContext } from '@ripperoni/core';

export const SmsSignUp = ({
  variantId: vId,
  title,
  options,
  selectedVariant,
  setEmailSubmitted,
}) => {
  const { toggleModal } = useUIContext();
  const [phone, setPhone] = useState('');
  const [error, setError] = useState('');
  const [submitText, setSubmitText] = useState('Submit');
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [acceptedMarketing, setAcceptedMarketing] = useState(false);

  const BIS_URLS = {
    development: 'http://localhost:8000/sms_backinstock',
    test: 'https://staging.conciergeteam.co/sms_backinstock',
    production: 'https://app.conciergeteam.co/sms_backinstock',
  };
  const env = process.env.NODE_ENV;
  const url = BIS_URLS[env];

  const handleSubmit = async () => {
    if (!isBrowser || loading || submitted) return;
    const isValid = phone.match(/\d/g);
    if (!isValid || isValid.length !== 10) {
      setError('Please enter a valid mobile number.');
      return;
    }
    setError('');
    setLoading(true);
    const productId = selectedVariant.productForeignId?.split('/').pop();
    const variantId = vId?.split('/').pop();
    const variantTitle =
      options.Size && options.Color
        ? `in size ${options.Size} and color ${options.Color}`
        : !options.Size
        ? `in color ${options.Color}`
        : !options.Color
        ? `in size ${options.Size}`
        : ``;

    const params = {
      pk: `eyJhbGciOiJIUzI1NiJ9.eyJvcmdfaWQiOjM4LCJvcmlnaW4iOiJwdWJsaWNyZWMuY29tIn0.dPkcSYIsxsEK_QoPK9_ccYaaMf12t2cGgaZe-o0LwIA`,
      url,
      product_no: productId,
      link: window.document.URL.split('?')[0],
      product_title: selectedVariant.product?.title,
      shopify_domain: 'publicrec.myshopify.com',
      platform: 'klaviyo',
      variant_no: variantId,
      variant_title: variantTitle,
      phone_number: phone,
      accepts_sms_marketing: acceptedMarketing,
    };
    try {
      const res = await fetch(url, {
        body: JSON.stringify(params),
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
      });

      if (res.ok) {
        setLoading(false);
        setSubmitted(true);
        setPhone('');
        setSubmitText('Submitted!');
        setTimeout(() => {
          toggleModal();
          setSubmitted(false);
          setSubmitText('Submit');
          setEmailSubmitted();
        }, 1500);
      } else if (res.status === 422) {
        setLoading(false);
        setError(
          'The mobile number provided may already be subscribed to this notification, or may not be active.'
        );
      } else {
        setLoading(false);
        setError(
          'Error trying to submit your mobile number. Please try again later.'
        );
        throw new Error(res.status);
      }
    } catch (err) {
      throw err;
    }
  };

  return (
    <FlexCol data-comp={SmsSignUp.displayName} sx={{ alignItems: 'center' }}>
      <Text
        variant='text.h3'
        mb={3}
        sx={{
          textAlign: 'center',
          lineHeight: 1.5,
        }}
      >
        Email submitted!
        <br />
        Want a text notification as well?
      </Text>

      <Text
        sx={{
          maxWidth: '450px',
          mb: 12,
          lineHeight: 'text',
          textAlign: 'center',
          span: {
            fontWeight: 500,
          },
        }}
      >
        For <span>{title}</span> in size <span>{options.Size}</span> and color{' '}
        <span>{options.Color}</span>.
      </Text>

      <Flex
        sx={{
          width: '100%',
          maxWidth: '300px',
        }}
      >
        <Box
          as='input'
          type='tel'
          value={phone}
          onChange={(event) => setPhone(event.target.value)}
          placeholder='Your mobile number'
          sx={{
            p: 6,
            flex: 2,
            minWidth: '100%',
            fontSize: 3,
            fontFamily: 'text',
            '&::placeholder': {
              fontFamily: 'text',
              fontWeight: 300,
            },
          }}
        />
      </Flex>

      <Flex
        sx={{
          justifyContent: 'center',
          mt: 5,
          mb: 9,
        }}
      >
        <Box
          as='input'
          id='back-in-stock-sms-checkbox'
          type='checkbox'
          onChange={() => {
            const checked = document.getElementById(
              'back-in-stock-sms-checkbox'
            ).checked;
            setAcceptedMarketing(checked ? true : false);
          }}
          sx={{
            mr: 2,
            mt: 1,
          }}
        />
        <Text text="Sign up for Public Rec's SMS newsletter." />
      </Flex>

      <Text
        sx={{
          textAlign: 'center',
          maxWidth: '450px',
          mb: 12,
        }}
      >
        By providing your mobile number, you agree to receive recurring
        automated marketing text messages from Public Rec.
      </Text>

      <Button
        aria-label='Submit Back In Stock'
        sx={{
          width: '100%',
          maxWidth: '356px',
          fontSize: 3,
          height: '46px',
          py: 0,
          cursor: !loading && !submitted ? 'pointer' : 'auto',
          '&:hover': {
            bg: !loading && !submitted ? null : 'black',
          },
        }}
        onClick={handleSubmit}
      >
        {!loading ? (
          submitText
        ) : (
          <Loader
            sx={{
              position: 'block',
              height: '100%',
              color: 'white',
              my: 0,
              py: 3,
            }}
          />
        )}
      </Button>

      <Box mt={3}>
        <Text
          sx={{
            fontSize: 3,
            color: 'error',
            textAlign: 'center',
          }}
        >
          {error}
        </Text>
      </Box>
      <Text
        sx={{
          cursor: 'pointer',
          color: 'gray.3',
          mt: 1,
        }}
        onClick={() => {
          toggleModal();
          setEmailSubmitted();
        }}
      >
        Skip
      </Text>
    </FlexCol>
  );
};

SmsSignUp.displayName = 'SmsSignUp';
