import React from 'react';
import { Grid } from 'theme-ui';

import { Box, Text } from '@ripperoni/components';

import storeLocatorData from '../../hooks/storeLocatorData';

const StoreList = () => {
  const { locations } = storeLocatorData();
  return (
    <Grid
      gap={2}
      mt={['40px', null, '65px', null, null]}
      mb={['40px', null, '65px', null, null]}
      mx='auto'
      px={[5, null, 12, null, null]}
      sx={{
        maxWidth: '1280px',
        gridTemplateColumns: [
          'repeat(2,1fr)',
          null,
          'repeat(4,1fr)',
          null,
          null,
        ],
      }}
    >
      {locations.map(({ name, address1, city, stateAbbrv, zipCode }, index) => {
        if (
          name !== 'Public Rec - Chicago' &&
          name !== 'Public Rec - NYC' &&
          name !== 'Scottsdale Kierland Commons'
        )
          return (
            <Box
              key={index}
              my={3}
              sx={{
                justifySelf: 'safe center',
                width: ['160px', null, '220px', null, null],
              }}
            >
              <Text
                fontWeight='600'
                fontSize={['16px', null, '18px', null, null]}
              >
                {name}
              </Text>
              <Text fontSize={['12px', null, '15px', null, null]}>
                {address1}
              </Text>
              <Text fontSize={['12px', null, '15px', null, null]}>
                {city}, {stateAbbrv} {zipCode}
              </Text>
            </Box>
          );
      })}
    </Grid>
  );
};

export default StoreList;
