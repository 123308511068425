/** @jsx jsx */
import PropTypes from 'prop-types';
import { Children, forwardRef, useEffect, useRef } from 'react';
import { jsx } from 'theme-ui';
// eslint-disable-next-line
import { Swiper, Controller, HashNavigation, History, Lazy, Mousewheel, Virtual, Autoplay, Navigation, Pagination, Scrollbar, EffectFade } from 'swiper';

import uniqueid from 'lodash.uniqueid';

export const SwiperSlider = forwardRef(({
  params,
  className,
  children,
  ...props
}, ref) => {
  const SwiperId = useRef(uniqueid('swiper-'))
  const SwiperRef = useRef()
  const SwiperInstance = useRef(null)

  Swiper.use(Virtual)
  Swiper.use(Mousewheel)
  Swiper.use(Controller)
  Swiper.use(History)
  Swiper.use(HashNavigation)

  if (params.autoplay) {
    Swiper.use(Autoplay)
  }
  if (params.effect === 'fade') {
    Swiper.use(EffectFade)
  }
  if (params.navigation) {
    Swiper.use(Navigation)
  }
  if (params.pagination) {
    Swiper.use(Pagination)
  }
  if (params.scrollbar) {
    Swiper.use(Scrollbar)
  }
  if (params.lazy) {
    Swiper.use(Lazy)
  }

  useEffect(() => {
    // swiper mounted
    SwiperInstance.current = new Swiper(SwiperRef.current, {
      ...params,
    });

    // on init pass el and swiper references
    ref([SwiperRef.current,  SwiperInstance.current])

    return () => {};
  }, []);

  // update swiper on resize
  useEffect(() => {
    let ticking = false;

    const resizeHandler = () => {
      if (!ticking) {
        window.requestAnimationFrame(function() {
          SwiperInstance.current.update()
          // console.log(className, 'Swiper resize update...')
          ticking = false;
        });
        ticking = true;
      }
    }

    // set resize listener
    window.addEventListener('resize', resizeHandler)

    return () => {
      window.removeEventListener('resize', resizeHandler)
    };
  });

  return (
    <div
      id={SwiperId.current}
      className={['swiper-container', className].join(' ')}
      ref={SwiperRef}
      {...props}
    >
      <div className="swiper-wrapper">
        {Children.map(children, (slide, index) => {
        return (
          <div
            className="swiper-slide"
            key={SwiperId.current + index}
          >
            {slide}
            {/* If Lazy loaded */}
            { params.lazy
              ? <div className="swiper-lazy-preloader swiper-lazy-preloader-white"></div>
              : null
            }
          </div>
          )
        })}
        { params.pagination
          ? <div className='swiper-pagination'></div>
          : null
        }
      </div>
    </div>
  )

})

SwiperSlider.displayName = 'SwiperSlider'
SwiperSlider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element)
  ]),
  className: PropTypes.string.isRequired,
  params: PropTypes.object.isRequired,
}