// onClientEntry
const {
  setThirdPartiesAllowedInitialState,
  loadThirdParties,
} = require('./on-client-third-parties');

module.exports = async () => {
  setThirdPartiesAllowedInitialState();

  loadThirdParties();
};
