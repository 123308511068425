import React from 'react';
import PropTypes from 'prop-types';

import { Box, Svg } from '@ripperoni/components';

import ArrowRight from '@assets/svgs/thin-arrow-right.svg';


export const NextArrow = (props) => {
  return (
    <Box
      sx={{
        cursor: 'pointer',
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        zIndex: 1,
        right: 7
      }}
      {...props}
    >
      <Svg
        as={ArrowRight}
      />
    </Box>
  );
};

NextArrow.propTypes = {

};
