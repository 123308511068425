/**
 * @prettier
 */
import React from 'react';

import { Box, Grid } from '@ripperoni/components';

export const HeroSplit = ({ heroLeft, heroRight, ...props }) => {
  return (
    <Grid
      {...props}
      position='relative'
      gap={0}
      columns={['1fr', null, null, '1fr 1fr']}
      sx={{ '& *': { height: '100%' } }}
    >
      <Box>{heroLeft}</Box>
      <Box>{heroRight}</Box>
    </Grid>
  );
};

HeroSplit.propTypes = {};

HeroSplit.displayName = 'Hero Split';
