/**
 * @jsx jsx
 */
import { jsx } from 'theme-ui';
import React, { memo } from 'react';

import { Box, Flex } from '@ripperoni/components';

import { useProduct } from '@components/ProductContext';

import useYotpo from './useYotpo';

export const ProductReviews = memo(() => {
  const { productState } = useProduct(),
    { product, selectedVariant } = productState,
    { title, handle } = product,
    { productForeignId } = selectedVariant || {},
    { MaybeInjectYoptoScript } = useYotpo();
  return (
    <>
      <Flex justifyContent='center' id='YotpoProductReviews'>
        <Box data-comp={ProductReviews.displayName} css={yotpoWidgetCss}>
          <div
            className='yotpo yotpo-main-widget'
            data-product-id={productForeignId?.replace(
              'gid://shopify/Product/',
              ''
            )}
            data-name={title}
            data-url={`"https://publicrec.com/products/${handle}`}
          />
        </Box>
      </Flex>
      {MaybeInjectYoptoScript}
    </>
  );
});

const yotpoWidgetCss = {
  width: '100%',
  maxWidth: '1156px',
  marginBottom: '32px',
  '.main-widget': {
    display: 'flex',
    flexDirection: 'column',
  },
  '.yotpo-review-stars': {
    marginTop: '16px !important',
    span: {
      fontSize: '16px !important',
      marginRight: '2px !important',
    },
  },
  '.yotpo .yotpo-review .yotpo-header .yotpo-review-stars .yotpo-user-related-fields .yotpo-user-field .yotpo-user-field-description': {
    fontSize: '12px !important',
  },
  '.yotpo .yotpo-review .yotpo-header .yotpo-review-stars .yotpo-user-related-fields .yotpo-user-field .yotpo-user-field-answer': {
    fontSize: '12px !important',
  },
  '.yotpo div, .yotpo span, .yotpo p, .yotpo a, .yotpo img, .yotpo i,.yotpo strong, .yotpo sup, .yotpo ul, .yotpo li, .yotpo form, .yotpo label': {
    fontFamily:
      'Messina Sans, Helvetica Neue, Helvetica, Arial, sans-serif !important',
    fontWeight: '400',
    '&:active': {
      outline: 0,
    },
    '&:focus': {
      outlineWidth: 0,
    },
    '&:focus-visible': {
      outlineWidth: 1,
    },
  },
  '.filters-container .free-text-search': {
    width: '100% !important',
    maxWidth: '390px !important',
  },
  '.filters-container .free-text-search-input': {
    width: '100% !important',
    marginRight: '0 !important',
    borderColor: '#C4C4C4 !important',
  },
  '.filters-container .dropdown-filters-container': {
    marginTop: '-10px !important',
  },
  '.filters-dropdown .yotpo-dropdown-button': {
    height: '40px !important',
    paddingTop: '10px !important',
    paddingBottom: '6px !important',
  },
  '.yotpo-icon-down-triangle': {
    paddingTop: '0px !important',
  },
  '.suggested-topic': {
    padding: '10px 20px !important',
    marginRight: '15px !important',
  },
  '.suggested-topic-text': {
    padding: '0px !important',
  },
  '.suggested-topics-row': {
    marginBottom: '20px !important',
  },
  '.yotpo .yotpo-regular-box-filters-padding': {
    marginTop: '32px !important',
  },
  '.yotpo-reviews-header': {
    paddingBottom: '0px !important',
  },
  '.main-widget .yotpo-reviews-header': {
    marginBottom: '-20px !important',
  },
  '.yotpo .yotpo-review .yotpo-header .yotpo-review-stars .yotpo-user-related-fields': {
    marginTop: '0px !important',
    fontSize: '12px !important',
  },
  '.reviews-qa-label': {
    color: '#979797 !important',
    fontWeight: '600 !important',
  },
  // Hidden Elements
  '.yotpo-label-container, .pT20, .yotpo-icon-write-no-frame, .yotpo-icon-dialog, .yotpo-icon-profile': {
    display: 'none !important',
  },
  // Star Rating
  '.yotpo-display-wrapper': {
    order: 2,
    alignSelf: 'center',
    margin: '0 !important',
  },
  '.content-title, .avg-score': {
    color: '#141414 !important',
    fontWeight: '600 !important',
  },
  '.bottom-line-items': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  // Review / Question Tabs
  '.new-yotpo-small-box': {
    order: 1,
    alignSelf: 'center',
    borderWidth: '0 !important',
    margin: '0 !important',
  },
  '.yotpo-nav-wrapper': {
    textAlign: 'center',
    span: {
      fontSize: '18px !important',
    },
  },
  '.yotpo-nav-tab': {
    margin: '0 10px !important',
  },
  '.yotpo-nav-tab .yotpo-active': {
    margin: '0 !important',
  },
  '.yotpo .yotpo-nav-content>div.yotpo-active': {
    margin: '0 20px !important',
  },
  // Write Review / Ask Question Buttons
  '.write-question-review-buttons-container': {
    order: 3,
    alignSelf: 'center',
    margin: '0 !important',
  },
  '.write-question-review-button-text': {
    color: 'black !important',
  },
  '.write-question-review-buttons-container .write-question-review-button': {
    width: '150px !important',
    height: '40px !important',
    padding: '0px !important',
    lineHeight: '40px !important',
  },
  // Filter
  '.filters-container .filters-container-title': {
    fontSize: '18px !important',
    color: '#000000 !important',
    fontWeight: '600 !important',
  },
  '.suggested-topic-text, .selected': {
    color: '#000000 !important',
    fontWeight: '400 !important',
  },
  '.filters-container .desktop-clear-all-btn': {
    marginTop: '20px !important',
  },
  // Review
  '.reviews-amount': {
    color: '#979797 !important',
  },
  '.yotpo-nav-content': {
    order: 5,
  },
  '.filters-container-title': {
    borderTop: '1px solid #e3e3e3',
    paddingTop: '20px',
    paddingBottom: '0px !important',
  },
  '.yotpo-main, .yotpo-footer': {
    marginLeft: '0px !important',
  },
  '.yotpo-main': {
    marginTop: '20px !important',
  },
  '.yotpo-footer': {
    marginTop: '30px !important',
  },
  '.yotpo-user-name': {
    fontSize: '16px !important',
    fontWeight: '600 !important',
    marginRight: '50% !important',
  },
  '.yotpo-user-title': {
    fontSize: '14px !important',
    fontWeight: '600 !important',
    color: '#979797 !important',
    marginTop: '4px !important',
  },
  '.yotpo-review-date, .label-helpful, .vote-sum': {
    fontWeight: '600 !important',
    color: '#979797 !important',
  },
  '.yotpo-action': {
    span: {
      // fontWeight: '600 !important',
      color: '#979797 !important',
    },
  },
  '.yotpo-icon-magnifying-glass, .yotpo-icon-thumbs-up, .yotpo-icon-thumbs-down, .yotpo-icon-share': {
    color: '#C4C4C4 !important',
  },
  '.content-title': {
    fontSize: '18px !important',
    // marginTop: '-16px !important',
    paddingBottom: '8px !important',
    lineHeight: '1.2em !important',
  },
  '.content-review': {
    fontSize: '15px !important',
    lineHeight: '1.25em !important',
    color: '#000000 !important',
    fontWeight: '400 !important',
  },
  '.yotpo-user-field': {
    span: {
      color: '#000000 !important',
      fontWeight: '500 !important',
    },
  },
  '.yotpo .yotpo-review .yotpo-header .yotpo-review-stars .yotpo-user-related-fields .yotpo-user-field .yotpo-user-field-description': {
    fontStyle: 'normal !important',
    fontSize: '12px !important',
  },
  '.yotpo-product-custom-fields .product-related-fields-item-title': {
    width: 'auto !important',
    fontSize: '12px !important',
    color: '#000000 !important',
    fontWeight: '600 !important',
  },
  '.product-related-fields-item-value': {
    fontSize: '12px !important',
    color: '#000000 !important',
    width: 'auto !important',
    paddingLeft: '8px !important',
    paddingRight: '0px !important',
  },
  '.yotpo-header-element': {
    marginBottom: '-18px !important',
  },
  '.yotpo-icon-star': {
    marginBottom: '18px !important',
  },
  '.yotpo .yotpo-review .yotpo-header .yotpo-review-stars .yotpo-user-related-fields .yotpo-user-field .yotpo-user-field-description': {
    fontWeight: '600 !important',
    fontStyle: 'normal !important',
    fontSize: '12px !important',
  },
  '.content-question': {
    marginTop: '32px !important',
  },
  '.yotpo-comments-box': {
    marginBottom: '20px !important',
  },
  '.yotpo-comments-box .yotpo-comment-box': {
    '.yotpo-user-name': {
      marginRight: '0 !important',
    },
    '.content-review': {
      marginTop: '16px !important',
      display: 'block !important',
    },
  },

  // MEDIA QUERY MAX WIDTH 655PX
  '@media only screen and (max-width: 655px)': {
    '.yotpo-product-related-fields-wrapper': {
      marginTop: '-16px !important',
    },
    '.yotpo .yotpo-review .yotpo-main .yotpo-multiple-rating-fields .yotpo-user-related-fields .yotpo-user-field .yotpo-user-field-description': {
      fontStyle: 'normal !important',
      fontWeight: '600 !important',
      fontSize: '12px !important',
    },
    '.yotpo-main': {
      marginTop: '24px !important',
    },
  },

  // MEDIA QUERY MAX WIDTH 960PX
  '@media only screen and (max-width: 960px)': {
    '.yotpo-comments-box .yotpo-comment-box': {
      '.content-review': {
        marginTop: '0px !important',
      },
    },
    '.main-widget .reviews-qa-labels-container .reviews-qa-label': {
      fontSize: '12px !important',
      color: '#979797 !important',
      fontWeight: '600 !important',
    },
    '.bottom-line-items-container .bottom-line-items .yotpo-filter-stars': {
      marginTop: '10px !important',
      marginBottom: '-36px !important',
    },
    '.reviews-qa-label': {
      marginTop: '34px !important',
      textAlign: 'center !important',
      color: '#979797 !important',
      fontWeight: '600 !important',
    },
    '.reviews-qa-labels-container': {
      marginLeft: '0px !important',
    },
    '.avg-score': {
      fontSize: '20px !important',
      textAlign: 'center !important',
    },
    '.write-question-review-buttons-container .write-question-review-button': {
      width: '150px !important',
      height: '40px !important',
      padding: '0px !important',
      lineHeight: '40px !important',
      marginTop: '20px !important',
      marginBottom: '-2px !important',
    },
    '.yotpo .yotpo-regular-box': {
      // borderTop: '1px solid #e3e3e3 !important',
      width: 'calc(100vw - 40px) !important',
      marginLeft: '0px !important',
    },
    '.yotpo-review .yotpo-regular-box .yotpo-regular-box-filters-padding': {
      borderTop: '0px !important',
    },
    '.write-review': {
      paddingLeft: '20px !important',
      marginRight: '20px !important',
    },
    '.yotpo-display-wrapper': {
      borderTop: '1px solid #e3e3e3 !important',
      marginTop: '15px !important',
    },
    '.main-widget': {
      borderTop: '0px !important',
      marginTop: '0px !important',
    },
    '.filters-container .free-text-search': {
      width: '100% !important',
      maxWidth: '390px !important',
    },
    '.filters-container .free-text-search-input': {
      width: '100% !important',
      marginRight: '0 !important',
      border: '1px solid #C4C4C4 !important',
    },
    '.filters-container-title': {
      textAlign: 'center !important',
    },
    '.yotpo-icon-star, .yotpo-icon-half-star, .yotpo-icon-empty-star': {
      fontSize: '19px !important',
      margin: '0 2px !important',
    },
    '.yotpo .yotpo-review .yotpo-header .yotpo-review-stars .yotpo-user-related-fields': {
      marginTop: '8px !important',
    },
    '.rating-star': {
      margin: '0 4px 0 0 !important',
    },
    '.yotpo.yotpo-main-widget .yotpo-icon-btn .yotpo-icon': {
      color: '#C4C4C4 !important',
      lineHeight: '40px !important',
    },
    '.more-filters-btn': {
      width: '100% !important',
      height: '40px !important',
      padding: '0px 11px !important',
      borderColor: '#C4C4C4 !important',
    },
    '.more-filters-text': {
      lineHeight: '40px !important',
      color: '#000000 !important',
      marginLeft: '8px !important',
    },
  },

  // MEDIA QUERY MIN WIDTH 961PX
  '@media only screen and (min-width: 961px)': {
    // Star Rating
    '.yotpo-display-wrapper': {
      // order: 2,
      alignSelf: 'flex-start',
      margin: '0 !important',
    },
    '.content-title, .avg-score': {
      color: '#141414 !important',
      fontWeight: '600 !important',
    },
    '.bottom-line-items': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-start',
      flexWrap: 'wrap !important',
      width: '190px !important',
      marginBottom: '-42px !important',
      marginLeft: '20px !important',
    },
    '.avg-score': {
      fontSize: '24px !important',
      textAlign: 'left !important',
      marginRight: '-14px !important',
    },
    '.yotpo-regular-box': {
      margin: '0px !important',
      paddingBottom: '0px !important',
    },
    '.yotpo-icon-star, .yotpo-icon-half-star, .yotpo-icon-empty-star': {
      fontSize: '24px !important',
      marginRight: '1px !important',
    },
    '.yotpo-filter-stars': {
      marginRight: '10px !important',
    },
    '.reviews-qa-labels-container': {
      marginTop: '-4px !important',
    },
    // Review / Question Tabs
    '.new-yotpo-small-box': {
      // order: 1,
      alignSelf: 'center',
      borderWidth: '0 !important',
      margin: '0 0 -78px 0 !important',
    },
    '.yotpo-nav-wrapper': {
      span: {
        fontSize: '18px !important',
      },
    },
    '.yotpo-nav-tab': {
      margin: '0 10px !important',
    },
    '.yotpo-nav-tab .yotpo-active': {
      margin: '0 !important',
    },
    '.yotpo .yotpo-nav-content>div.yotpo-active': {
      margin: '0 20px !important',
    },
    // Write Review / Ask Question Buttons
    '.write-question-review-buttons-container': {
      // order: 3,
      alignSelf: 'flex-end',
      margin: '0 10px -12px 0 !important',
    },
    '.write-question-review-button-text': {
      color: 'black !important',
    },
    '.write-question-review-buttons-container .write-question-review-button': {
      width: '150px !important',
      height: '40px !important',
      padding: '0px !important',
      lineHeight: '40px !important',
    },
    // Filter
    '.filters-container .filters-container-title': {
      fontSize: '18px !important',
      color: '#000000 !important',
      fontWeight: '600 !important',
    },
    '.suggested-topic-text, .selected': {
      color: '#000000 !important',
      fontWeight: '400 !important',
    },
    '.write-review': {
      marginBottom: '48px !important',
    },
    // Footer
    '.yotpo-footer': {
      marginBottom: '24px !important',
    },
    '.write-review': {
      paddingLeft: '20px !important',
      marginRight: '20px !important',
      marginBottom: '48px !important',
    },
    '.yotpo-main': {
      marginTop: '0px !important',
    },
    '.filters-dropdown .yotpo-dropdown-button .yotpo-filter-stars': {
      marginTop: '-25px !important',
    },
  },

  // MEDIA QUERY MAX WIDTH 510PX
  '@media only screen and (max-width: 510px)': {
    '.main-widget .yotpo-reviews-filters': {
      borderBottomWidth: '0px !important',
    },
    '.main-widget .yotpo-reviews-header': {
      paddingTop: '0px !important',
    },
    '.filters-container .free-text-search-input, .more-filters-btn, .free-text-search': {
      width: '100% !important',
      maxWidth: '100% !important',
    },
    '.filters-container .free-text-search': {
      maxWidth: '100% !important',
    },
    '.suggested-topic': {
      padding: '9px 15px !important',
    },
    '.yotpo-review-date': {
      marginRight: '10px !important',
    },
  },

  // MEDIA QUERY MAX WIDTH 541PX
  '@media only screen and (max-width: 541px)': {
    '.main-widget .mobile-clear-filters-btn': {
      position: 'absolute !important',
      width: '50px !important',
      top: '235px !important',
      right: '-60px !important',
      zIndex: 1,
    },
    '.yotpo-filter-tag': {
      padding: '0 11px !important',
      margin: '10px 10px 0 0 !important',
      span: {
        lineHeight: '33px !important',
      },
    },
  },
};

ProductReviews.displayName = 'ProductReviews';
