/**
 * @jsx jsx
 * @prettier
 */
import { jsx } from 'theme-ui';

import { Box, CmsContent, Container, Heading } from '@ripperoni/components';

import { useYotpoRefreshOnMount } from './useYotpoRefreshOnMount';

export const IGSlider = ({ heading, subHeading, images, ...props }) => {
  useYotpoRefreshOnMount({ delay: 50 });

  const galleryId = '608989a7693f7755edecfc2b';

  return (
    <Container.Full px={8} {...props}>
      <Container textAlign='center'>
        <CmsContent
          as={Heading}
          variant='heading.h2'
          textAlign='center'
          fontSize={[7, null, null, 9]}
          mb={1}
          content={heading}
        />

        <CmsContent
          fontWeight='semi'
          content={subHeading}
          variant='text.default'
          textDecoration='none'
          target='_blank'
        />

        <Box mt={[9, null, null, 11]} position='relative'>
          <Box
            data-comp='IGSlider'
            css={yotpoInstaCss}
            id={`yotpo-pictures-widget-${galleryId}`}
            className='yotpo yotpo-pictures-widget'
            data-gallery-id={galleryId}
          />
        </Box>
      </Container>
    </Container.Full>
  );
};

const yotpoInstaCss = {
  'div.yotpo-single-image-container': {
    aspectRatio: '4 / 5 !important',
  },
  '.yotpo-slider .yotpo-display-wrapper .y-arrows .arrows-wrapper-left, .yotpo-visual-carousel .yotpo-display-wrapper .y-arrows .arrows-wrapper-left, .yotpo-pictures-widget .yotpo-display-wrapper .y-arrows .arrows-wrapper-left, .yotpo-slider .yotpo-display-wrapper .y-arrows .arrows-wrapper-right, .yotpo-visual-carousel .yotpo-display-wrapper .y-arrows .arrows-wrapper-right, .yotpo-pictures-widget .yotpo-display-wrapper .y-arrows .arrows-wrapper-right': {
    zIndex: '0 !important',
  },
  '.yotpo-slider .yotpo-display-wrapper .y-arrows .yotpo-icon-right-arrow-light, .yotpo-slider .yotpo-display-wrapper .y-arrows .yotpo-icon-left-arrow-light, .yotpo-visual-carousel .yotpo-display-wrapper .y-arrows .yotpo-icon-right-arrow-light, .yotpo-visual-carousel .yotpo-display-wrapper .y-arrows .yotpo-icon-left-arrow-light, .yotpo-pictures-widget .yotpo-display-wrapper .y-arrows .yotpo-icon-right-arrow-light, .yotpo-pictures-widget .yotpo-display-wrapper .y-arrows .yotpo-icon-left-arrow-light': {
    zIndex: '0 !important',
  },
  '.yotpo-pictures-gallery .yotpo-single-image-container, .yotpo-pictures-widget .yotpo-single-image-container': {
    float: 'none !important',
  },
  '.yotpo-hover-enable .yotpo-single-image-container .y-image-wrapper:hover .yotpo-hover-cta, .yotpo-hover-enable .yotpo-single-image-container .hover-preview .yotpo-hover-cta': {
    backgroundColor: 'transparent',
    border: '2px solid #fff',
    textTransform: 'uppercase',
    fontWeight: '400 !important'
  },
  '.yotpo-hover-enable .y-image-wrapper:hover .y-image-overlay, .yotpo-hover-enable .hover-preview .y-image-overlay': {
    opacity: '0.4 !important'
  }
};

IGSlider.propTypes = {};

export default IGSlider;
