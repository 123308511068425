/**
 * @prettier
 */
import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { Box } from '@ripperoni/components';

import ProductTile from '@components/ProductTile';

export const Products = memo(
  ({ variantId: variantIds, className, fromCms, imageType, ...props }) => {
    if (!variantIds) return null;

    return variantIds.map((variantId, index) => (
      <Box key={index} className={className} {...props}>
        <ProductTile
          variantId={variantId}
          imageType={imageType}
          fromCms={fromCms}
        />
      </Box>
    ));
  }
);

Products.displayName = 'Products';

Products.displayName = 'Products';

Products.propTypes = {
  variantId: PropTypes.array,
};
