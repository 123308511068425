/**
 * @prettier
 */
/** @jsx jsx */
import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { jsx } from 'theme-ui';

import { Container } from '@ripperoni/components';
import { ContentfulContent } from '@ripperoni/cms';

import { PeruVideo } from '@components/PeruVideo';

import { Seo } from 'src/@packdigital/gatsby-theme-ripperoni-marketing/components/Seo/Seo.jsx';

const PeruPage = (props) => {
  const { pageContainer } = useStaticQuery(PeruStaticQuery);

  return (
    <Container.Full>
      <Seo pageTitle='Made in Peru with Pima Cotton, Made to be the best' />
      <PeruVideo />
      <ContentfulContent {...pageContainer} />
    </Container.Full>
  );
};

export default PeruPage;

const PeruStaticQuery = graphql`
  {
    pageContainer: contentfulPageContainer(
      slug: { eq: "/made-in-peru-public-rec" }
    ) {
      ...ContentfulPageContainer
    }
  }
`;
