/**
 * @prettier
 */
import React from 'react';
import { motion } from 'framer-motion';

import { Button } from '@ripperoni/components';
import { useUIContext } from '@ripperoni/core';

export const NavigationToggle = ({ headings, ...props }) => {
  const { state, showMobileNavigation, hideMobileNavigation } = useUIContext();
  const navigationOpen = !!state.mobileNavigation;

  return (
    <Button
      data-comp={NavigationToggle.displayName}
      aria-label='Mobile Navigation Toggle'
      variant='plain'
      position='relative'
      zIndex={4}
      width='24px'
      height='18px'
      onClick={() => {
        if (!navigationOpen) {
          showMobileNavigation(headings[0].text);
          return;
        }

        hideMobileNavigation();
      }}
      {...props}
    >
      <svg width='24' height='24' viewBox='0 0 24 24'>
        <Path
          initial={false}
          animate={{
            d: navigationOpen ? 'M 3 16.5 L 17 2.5' : 'M 2 2.5 L 20 2.5',
          }}
        />
        <Path
          d='M 2 9.423 L 20 9.423'
          initial={false}
          animate={{
            opacity: navigationOpen ? 0 : 1,
          }}
          transition={{ duration: 0.1 }}
        />
        <Path
          initial={false}
          animate={{
            d: navigationOpen
              ? 'M 3 2.5 L 17 16.346'
              : 'M 2 16.346 L 20 16.346',
          }}
        />
      </svg>
    </Button>
  );
};

NavigationToggle.displayName = 'Mobile Navigation Toggle';

const Path = (props) => (
  <motion.path
    fill='transparent'
    strokeWidth='1'
    stroke='hsl(0, 0%, 18%)'
    strokeLinecap='round'
    {...props}
  />
);
