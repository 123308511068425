import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { AnimatePresence, motion } from 'framer-motion';

import { Box, Flex, Text } from '@ripperoni/components';

function useOutsideClick(ref, callback) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        callback && callback();
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);
}

export const AccordionItem = ({
  title,
  description,
  open,
  grouped,
  onClick,
}) => {
  const [isOpen, setIsOpen] = useState(open);
  const accordionRef = useRef(null);

  if (!grouped) {
    useOutsideClick(accordionRef, () => {
      setIsOpen(false);
      onClick && onClick();
    });
  }

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  return (
    <Box ref={accordionRef}>
      <Flex
        borderBottom='thin solid'
        borderColor='gray.2'
        between
        middle
        onClick={() => {
          setIsOpen(!isOpen);
          onClick && onClick();
        }}
        py={8}
        sx={{
          cursor: 'pointer',
        }}
      >
        <Text fontWeight='semi' fontSize={3}>
          {title}
        </Text>

        <Box
          sx={{
            position: 'relative',
            width: '13px',
            height: '13px',
            '&:before, &:after': {
              content: '""',
              position: 'absolute',
              backgroundColor: 'gray.2',
              transition: 'all 0.15s ease-out',
            },
            /* Vertical line */
            '&:before': {
              top: 0,
              left: '50%',
              width: '1px',
              height: '100%',
              ml: '-1px',
              transform: isOpen ? 'rotate(90deg)' : 'rotate(0deg)',
            },
            /* horizontal line */
            '&:after': {
              top: '50%',
              left: 0,
              width: '100%',
              height: '1px',
              // mt: '-1px',
              transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)',
            },
          }}
        />
      </Flex>

      <AnimatePresence>
        {isOpen && (
          <motion.div
            style={{ overflow: 'hidden' }}
            initial={{ height: 0 }}
            animate={{ height: 'auto' }}
            exit={{ height: 0 }}
            transition={{ duration: 0.15 }}
          >
            <Box py={8}>{description}</Box>
          </motion.div>
        )}
      </AnimatePresence>
    </Box>
  );
};

AccordionItem.propTypes = {};

export default AccordionItem;
