import { graphql, useStaticQuery } from 'gatsby';

export default function isModelCopy() {
  const data = useStaticQuery(
    graphql`
      query {
        googleSheet {
          ModelCopy {
            color
            style
            inseam
            message
          }
        }
      }
    `
  );

  const modelCopyReplacements = data.googleSheet.ModelCopy.map((node) => {
    const { color, style, inseam, message } = node;
    return {
      color,
      style,
      inseam,
      message,
    };
  });
  return {
    modelCopyReplacements,
  };
}
