// TODO: Currently under performance testing vs loading the scripts at postBodyComponent
const LOAD_LOCAL_SCRIPTS_AFTER = 1000;
const APP_RENDER_THIRD_PARTIES = true;
const messageBus = require('@packdigital/gatsby-theme-ripperoni-message-bus');

const isBrowser = require('@ripperoni/utilities').isBrowser;

const { bindSubscriptions } = require('./analytics');

/**
 * When the dom is ready we set the <body/> data-load-3ps to true,
 * This triggers the mutation oberserver set at on-client-entry
 * which in turns loads the third party scripts once the dom is super ready.
 * @see: ../on-client-entry.js for the mutation observer/trigger
 * @param {boolean} enabled
 */
const triggerThirdPartiesInjection = () => {
  document.body.setAttribute('data-load-3ps', true);
}

module.exports = () => {
  // console.log("ReactDOM render complete.")

  bindSubscriptions();

  window.addEventListener('load', () => {
    const { publish, topics } = messageBus;
    publish(topics.INITIAL_LOAD, {});
  });

  if (isBrowser) {
    const tO = window.requestTimeout(() => {
      if (APP_RENDER_THIRD_PARTIES) triggerThirdPartiesInjection();
      if (tO) window.clearRequestTimeout(tO);
    }, LOAD_LOCAL_SCRIPTS_AFTER);
  }
}
