/* eslint-disable max-lines */
import React, { memo, useEffect, useState } from 'react';

import { Box, Flex, Image, Link, Price, Text } from '@ripperoni/components';

import {
  ProductVariant,
  useProductVariant,
} from '@components/ProductVariantContext/ProductVariantContext.jsx';

import ProductTileSwatches from './ProductTileSwatches';

// Will give back the flatlay and model images, or first 2 available images
const getTileImages = (images) => {
  if (!images) return [];

  const tileImages = [];

  // get the flatLay first
  images.forEach((img, i) => {
    if (img.altText && img.altText.includes('model')) tileImages.push(img);
  });

  // get the model second
  images.forEach((img, i) => {
    if (i === 2) tileImages.push(img);
  });

  // If we found a flatlay & model return, else just return the first two images

  return tileImages;
};

const ProductTile = memo(({ imageType = 'Model', variantId, ...props }) => {
  return (
    <ProductVariant variantId={variantId}>
      <ProductTileContent imageType={imageType} />
    </ProductVariant>
  );
});

ProductTile.displayName = 'ProductTile';

const ProductTileContent = ({ imageType }) => {
  const [hover, setHover] = useState(false);
  const [productImages, setProductImages] = useState([]);
  const { productVariantState } = useProductVariant();

  const {
    product,
    title,
    foreignId,
    images,
    selectedOptionsMap,
    price,
    compareAtPrice,
  } = productVariantState;

  const link = `/products/${
    product?.handle
  }?firstAvailable=true&variant=${foreignId?.split('/').slice(-1)}`;

  useEffect(() => {
    if (images && images.length) {
      const variantImages = getTileImages(images);

      setProductImages(variantImages);
    } else {
      const productImages = getTileImages(product?.images);

      setProductImages(productImages);
    }
  }, [foreignId]);

  return (
    <Box onMouseOver={() => setHover(true)} onMouseOut={() => setHover(false)}>
      <Flex
        as={Link}
        middle
        center
        to={link}
        // 370x450 aspect ratio
        bg='gray.0'
        position='relative'
        sx={{
          width: '100%',
          ...(imageType === 'Model'
            ? {
                '[data-comp="Model"]': {
                  opacity: hover ? 0 : 1,
                },
                '[data-comp="Flatlay"]': {
                  opacity: hover ? 1 : 0,
                },
              }
            : {
                '[data-comp="Flatlay"]': {
                  opacity: hover ? 1 : 0,
                },
                '[data-comp="Model"]': {
                  opacity: hover ? 0 : 1,
                },
              }),
          '::before': {
            content: '""',
            display: 'block',
            paddingTop: '118%',
          },
        }}
      >
        {productImages.map(({ src, productVariantState, id }, index) => {
          // 0 === Flatlay
          // 1 === Model
          let opacity = 0;
          if (index === 1) {
            opacity = 1;
          } else if (index === 0) {
            opacity = 1;
          }

          return (
            <Box
              key={`${index}-${id}`}
              position='absolute'
              data-comp={index === 0 ? 'Model' : 'Flatlay'}
              width='100%'
              height='100%'
              sx={{
                opacity,
                transition: 'opacity .15s ease-in',
              }}
            >
              <Image.Static src={src} alt={product?.title} display='block' />
            </Box>
          );
        })}
      </Flex>

      <Flex
        bg='white'
        pr={[2, null, null, null, 8]}
        py={[2, null, null, null, 8]}
      >
        <Flex.Col middle flexGrow={1}>
          <Text
            as={Link}
            to={link}
            variant='text.h5'
            // textTransform='uppercase'
            textDecoration='none'
            data-comp='Product-Tile-Title'
          >
            {/* {product?.title === 'Workday Pant'
              ? 'Workday Pant 2.0'
              : product?.title} */}

            {product?.title === 'Workday Pant'
              ? 'Workday Pant 2.0'
              : product?.title === 'Workday Short'
              ? 'Workday Short 2.0'
              : product?.title
              ? product?.title.includes('Archive')
                ? product?.title.replace('Archive', '')
                : product?.title
              : product?.title}
          </Text>

          <Text color='gray.2' fontSize={3} fontWeight='light' mb={1}>
            {selectedOptionsMap?.Color}
          </Text>
          <Flex>
            <ProductTileSwatches visible={hover} />
          </Flex>
        </Flex.Col>

        <Flex
          data-comp='Product-Tile-Price'
          variant='text.h5'
          fontWeight='light'
          // mt='-5px'
        >
          {compareAtPrice ? (
            <>
              <Price
                mr={3}
                sx={{
                  textDecoration: 'line-through',
                  color: '#4F4F4F',
                }}
              >
                {Math.round(compareAtPrice / 100)}
              </Price>

              <Price color='#9E3838'>{Math.round(price / 100)}</Price>
            </>
          ) : (
            <Price>{Math.round(price / 100)}</Price>
          )}
        </Flex>
      </Flex>
    </Box>
  );
};

ProductTile.propTypes = {};

export default ProductTile;
