import React from 'react';
import PropTypes from 'prop-types';

import { Box, CmsContent, Link, Svg, Text } from '@ripperoni/components';

import PlayButton from '@assets/svgs/PlayButton2.svg';


export const VideoPageTiles = ({
  thumbnail,
  heading,
  cta,
  className,
}) => {
  return (
    <Box
      maxWidth='370px'
      width='100%'
      bg='white'
      className={className}
      my={4}
      sx={{
        boxShadow: '0 2px 10px 0 rgba(0,0,0,0.08)'
      }}
    >
      <Box
        position='relative'
      >
        <CmsContent
          content={thumbnail}
        />

        <Link to={cta[0]?.props?.url}>
          <Svg
            as={PlayButton}
            position='absolute'
            top='50%'
            left='50%'
            sx={{
              transform: 'translate(-50%, -50%)',
            }}
          />
        </Link>
      </Box>

      <Box
        p={9}
      >
        <CmsContent
          content={heading}
          as={Text}
          textAlign='center'
          mb={3}
        />

        <Box
          textAlign='center'
        >
          <CmsContent
            content={cta}
            fontWeight='regular'
            sx={{
              variant: 'link.feature'
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

VideoPageTiles.propTypes = {

};
