/* eslint-disable react/prop-types */
/* eslint-disable max-lines */
/** @jsx jsx */
import { jsx } from 'theme-ui';

import { Box, Flex, Link, Text } from '@ripperoni/components';

const renderTable = (tableData, units) => {
  const headerValues = tableData[0].filter((row) => {
    return row !== '-' && row;
  });

  const headerLength = tableData[0].filter(Boolean).length;
  const headerWidth = (1 / headerLength) * 100 + '%';

  return tableData ? (
    <Box>
      <Flex between middle position='relative'>
        <Text
          textAlign='center'
          padding={['15px 8px', null, '15px']}
          textTransform='uppercase'
          fontSize={1}
          width={headerWidth}
        >
          {headerValues[0]}
        </Text>

        <Box
          position='absolute'
          width={
            headerLength > 2
              ? `calc(90% / ${headerLength - 1})`
              : `calc(90% / 3)`
          }
          height='2px'
          bg='gray.1'
          sx={{
            left: '50%',
            transform: 'translateX(-50%)',
            '::after': {
              content: '""',
              border: 'solid',
              borderColor: 'gray.1',
              borderWidth: '0 2px 2px 0',
              display: 'inline-block',
              padding: '3px',
              transform: 'rotate(-45deg)',
              position: 'absolute',
              top: '-3px',
              ml: 'calc(100% - 6px)',
            },
          }}
        />

        <Text
          textAlign='center'
          padding={['15px 8px', null, '15px']}
          textTransform='uppercase'
          fontSize={1}
          width={headerWidth}
        >
          {headerValues[headerValues.length - 1]}
        </Text>
      </Flex>

      <table
        sx={{
          textAlign: 'center',
          border: 'thin solid',
          borderColor: 'gray.1',
          width: '100%',
          borderCollapse: 'collapse',
          fontSize: [2, null, 3],
          'tr:nth-of-type(odd)': {
            backgroundColor: 'gray.0',
          },
          td: {
            width: headerWidth,
            border: 'thin solid',
            borderColor: 'gray.1',
            padding: ['15px 8px', null, '15px'],
          },
        }}
      >
        <tbody>
          {tableData.filter(Boolean).map((row, rowIndex) => {
            if (rowIndex === 0) return null;

            return (
              <tr key={`row-${rowIndex}`}>
                {row.filter(Boolean).map((col, colIndex) => {
                  if (rowIndex !== 0 && colIndex !== 0) {
                    return (
                      <td key={`data-${colIndex}`}>
                        {units === 'in' ? col : Math.round(col / 0.3937)}
                        {/* {units === 'in' ? '"' : 'cm'} */}
                      </td>
                    );
                  }

                  return <td key={`data-${colIndex}`}> {col} </td>;
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </Box>
  ) : null;
};

export const QuickSizeChart = ({
  description,
  table1,
  table2,
  setActiveAcc,
}) => {
  return (
    <Box>
      <Text mb={10}>
        {description} For more detailed measurements, see our&nbsp;
        <span
          onClick={() => setActiveAcc('complete-chart')}
          sx={{
            textDecoration: 'underline',
            cursor: 'pointer',
          }}
        >
          complete size chart
        </span>
        &nbsp;below.
      </Text>

      <Flex flexDirection={['column', null, null, null, 'row']}>
        <Box
          flexBasis='50%'
          mr={[0, null, null, null, 14]}
          mb={[6, null, null, null, 0]}
        >
          {renderTable(table1.tableData, 'in')}
        </Box>

        <Box flexBasis='50%'>{renderTable(table2.tableData, 'in')}</Box>
      </Flex>

      <Text fontSize={1} mt={8}>
        Need Help?&nbsp;
        <Link
          to='https://support.publicrec.com/hc/en-us/requests/new'
          target='_blank'
          variant='a'
          sx={{
            color: 'black',
            fontSize: 1,
          }}
        >
          Contact Us
        </Link>
      </Text>
    </Box>
  );
};
