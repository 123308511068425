/** @jsx jsx */
import React, { useState } from 'react';
import { jsx } from 'theme-ui';
import PropTypes from 'prop-types';

import { Box, Flex, Text } from '@ripperoni/components';

const renderTable = (tableData, units) => {
  return tableData ? (
    <table
      sx={{
        textAlign: 'center',
        width: '100%',
        borderCollapse: 'collapse',
        border: 'thin solid',
        borderColor: 'gray.1',
        fontSize: [2, null, 3],
        'tr:nth-of-type(odd)': {
          backgroundColor: 'gray.0',
        },
        'tr td:first-of-type': {
          textAlign: 'left',
        },
        'tr:first-of-type td': {
          fontWeight: 'bold',
        },
        td: {
          padding: ['15px 8px', null, '15px'],
          border: 'thin solid',
          borderColor: 'gray.1',
        },
        'td:first-of-type': {
          fontWeight: 'bold',
        },
        'td:not(:first-of-type)': {
          letterSpacing: '.5px',
        },
      }}
    >
      <tbody>
        {tableData.filter(Boolean).map((row, rowIndex) => {
          return (
            <tr key={`row-${rowIndex}`}>
              {row.filter(Boolean).map((col, colIndex) => {
                if (rowIndex !== 0 && colIndex !== 0) {
                  return (
                    <td key={`data-${colIndex}`}>
                      {units === 'in'
                        ? col
                        : col.includes('-')
                        ? col
                            .split('-')
                            .map(function (i) {
                              return parseInt(i / 0.3937);
                            })
                            .join(' - ')
                        : Math.round(col / 0.3937)}
                      {units === 'in' ? '"' : 'cm'}
                    </td>
                  );
                }

                return <td key={`data-${colIndex}`}> {col} </td>;
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  ) : null;
};

export const SizeGuideTable = ({ tableData, instructions }) => {
  const [units, setUnits] = useState('in');

  return (
    <Box p={8}>
      <Flex top between mb={10} flexDirection={['column', null, null, 'row']}>
        <Box mb={[8, null, null, 0]}>
          <Text variant='text.h3' mb={2}>
            Size Chart
          </Text>

          {instructions ? (
            <Text fontSize={2}>
              Use the size chart below to determine your size.
              <br />
              {instructions}
            </Text>
          ) : (
            <Text fontSize={2}>
              Use the size chart below to determine your size.
              <br />
              Our garments fit true to size. If you are in between, we recommend
              sizing down.
            </Text>
          )}
        </Box>

        <Flex>
          <Text
            onClick={() => setUnits('in')}
            fontSize={2}
            fontWeight='semi'
            mr={8}
            pb={1}
            borderBottom='2px solid'
            borderColor={units === 'in' ? 'black' : 'transparent'}
            sx={{ cursor: 'pointer' }}
          >
            Inches
          </Text>

          <Text
            onClick={() => setUnits('cm')}
            fontWeight='semi'
            fontSize={2}
            pb={1}
            borderBottom='2px solid'
            borderColor={units === 'cm' ? 'black' : 'transparent'}
            sx={{ cursor: 'pointer' }}
          >
            Centimeters
          </Text>
        </Flex>
      </Flex>

      <Box width='100%' p='1px' sx={{ overflowX: 'scroll' }}>
        {renderTable(tableData, units)}
      </Box>
    </Box>
  );
};

SizeGuideTable.propTypes = {
  tableData: PropTypes.array,
};
