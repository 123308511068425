/* eslint-disable import/no-default-export */
import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import { ContentfulContent } from '@ripperoni/cms';

import { Seo } from 'src/@packdigital/gatsby-theme-ripperoni-marketing/components/Seo/Seo.jsx';

const IndexPage = (props) => {
  const { pageContainer, metaImage } = useStaticQuery(StaticQuery);

  const seoImage =
    metaImage?.primaryImage?.fluid?.srcWebp?.replace(/\/\//g, 'https://') ||
    pageContainer?.pageContent
      ?.find((content) => content.component === 'hero')
      ?.entries?.find((entry) => entry?.primaryImage)
      ?.primaryImage?.fluid?.srcWebp?.replace(/\/\//g, 'https://');

  return (
    <>
      <Seo
        pageTitle='Comfortable Clothing For Everywhere'
        logo={seoImage || '/logo.png'}
      />

      <ContentfulContent {...pageContainer} />
    </>
  );
};

export default IndexPage;

const StaticQuery = graphql`
  {
    pageContainer: contentfulPageContainer(slug: { eq: "/" }) {
      ...ContentfulPageContainer
    }
    metaImage: contentfulAtomImage(metaHandle: { eq: "homepage-meta-image" }) {
      primaryImage {
        fluid {
          srcWebp
        }
      }
    }
  }
`;
