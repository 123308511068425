/** @jsx jsx */
import { jsx } from 'theme-ui';
import PropTypes from 'prop-types';

import { Svg } from '@ripperoni/components';

import ArrowRight from '@assets/svgs/arrow-right.svg';


export const PrevArrow = (props) => {
  return (
    <Svg
      as={ArrowRight}
      sx={{
        cursor: 'pointer',
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%) rotate(-180deg)',
        left: '0',
        zIndex: 1,
        '.fill': {
          fill: 'gray.2',
        }
      }}
      {...props}
    />
  );
};

PrevArrow.propTypes = {

};
