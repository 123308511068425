import BrMist from '../../../static/BrushedMist.svg';
import BrMaroon from '../../../static/BrushedMaroon.svg';
import GCamo from '../../../static/GreyCamo.svg';
import SilverNavy from '../../../static/SilverNavy.svg';

const cirlceSelects = (active) => ({
  cursor: 'pointer',
  width: '30px',
  height: '30px',
  lineHeight: '30px',
  textAlign: 'center',
  borderRadius: '50%',
  border: '1px solid',
  fontSize: 2,
  borderColor: active ? 'gray.2' : 'transparent',
  ':hover': {
    borderColor: 'gray.2',
  },
  transition: 'border-color .2s ease-in',
});

const rectangleSelects = (active) => ({
  cursor: 'pointer',
  bg: active ? '#4E4E4E' : 'gray.1',
  color: active ? 'white' : 'black',
  width: ['60px', null, null, null, '44px'],
  height: ['40px', null, null, null, '28px'],
  lineHeight: ['37px', null, null, null, '26px'],
  textAlign: 'center',
  borderRadius: '2px',
  border: '1px solid',
  fontSize: 2,
  fontWeight: active ? 'semi' : 'light',
  borderColor: active ? '#717171' : 'transparent',
  ':hover': {
    bg: [active ? '#4E4E4E' : 'gray.1', null, null, null, '#A7A7A7'],
    borderColor: [active ? '#717171' : 'gray.1', null, null, null, '#A7A7A7'],
    fontWeight: [active ? 'semi' : 'light', null, null, null, '#A7A7A7'],
    color: [active ? 'white' : 'black', null, null, null, 'white'],
  },
  // transition: 'all .2s ease-in',
});
const styleSelects = (active) => ({
  cursor: 'pointer',
  bg: active ? '#4E4E4E' : 'gray.1',
  color: active ? 'white' : 'black',
  width: ['86px', null, null, null, '68px'],
  height: ['44px', null, null, null, '32px'],
  lineHeight: ['44px', null, null, null, '28px'],
  textAlign: 'center',
  borderRadius: '2px',
  border: '1px solid',
  fontSize: [4, null, null, 3],
  fontWeight: active ? 'semi' : 'light',
  borderColor: active ? '#717171' : 'transparent',
  ':hover': {
    bg: [active ? '#4E4E4E' : 'gray.1', null, null, null, '#A7A7A7'],
    borderColor: [active ? '#717171' : 'gray.1', null, null, null, '#A7A7A7'],
    fontWeight: [active ? 'semi' : 'light', null, null, null, '#A7A7A7'],
    color: [active ? 'white' : 'black', null, null, null, 'white'],
  },
  // transition: 'all .2s ease-in',
});

const pillSelects = (active) => ({
  cursor: 'pointer',
  minWidth: '100px',
  py: 2,
  borderRadius: '25px',
  border: '1px solid',
  fontSize: 2,
  borderColor: active ? 'gray.2' : 'gray.1',
  ':hover': {
    borderColor: 'gray.2',
  },
  transition: 'border-color .2s ease-in',
  textAlign: 'center',
});

const colorSelects = (active) => ({
  cursor: 'pointer',
  borderRadius: '50%',
  position: 'relative',
  width: '40px',
  fontSize: 2,
  height: '40px',
  border: '1px solid',
  borderColor: active ? 'gray.2' : 'transparent',
  ':hover': {
    borderColor: 'gray.2',
  },
  transition: 'border-color .2s ease-in',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  '::before': {
    bg: 'gray.3',
    height: '30px',
    width: '30px',
    content: '""',
    display: 'block',
    position: 'absolute',
    borderRadius: '50%',
  },
});

export const getInputStyle = (optionName, active) => {
  switch (optionName.toLowerCase()) {
    case 'waist':
      return rectangleSelects(active);
    case 'size':
      return rectangleSelects(active);
    case 'inseam':
      return rectangleSelects(active);
    case 'style':
      return styleSelects(active);
    case 'fit':
      return pillSelects(active);
    case 'denominations':
      return pillSelects(active);
    case 'amount':
      return pillSelects(active);
    case 'price':
      return pillSelects(active);
    case 'color':
      return colorSelects(active);
    default:
      break;
  }
};

export const colorMapping = {
  'Dark Khaki': '#c99e81',
  'Dark Olive': '#424c44',
  'Heather Black': '#494949',
  'Heather Burgundy': '#663c4a',
  'Heather Charcoal': '#4b4d51',
  'Heather Grey': '#bababa',
  'Heather Navy': '#3b4856',
  'Heather Silver Spoon': '#dddddd',
  'Light Grey': '#e1e3e5',
  'Midnight Blue': '#315670',
  'Stone Grey': '#3c4251',
  'Storm Grey': '#9d9d9d',
  'TBD (Black)': '#2d2c2c',
  'TBD (Charcoal)': '#444444',
  'TBD (Grey)': '#828084',
  'TBD (Heather Charcoal)': '#313131',
  'TBD (Heather Navy)': '#445870',
  'TBD (Heather Silver Spoon)': '#dddddd',
  'TBD (Light Grey)': '#eaeaea',
  'TBD (Navy)': '#3d4b60',
  'TBD (Olive)': '#4c4c40',
  'Vintage Navy': '#1A466B',
  Black: '#1b1c17',
  Burgundy: '#882736',
  Cream: '#f4f4e6',
  Fog: '#D4D8D7',
  Grey: '#919091',
  Khaki: '#d1b37f',
  Maroon: '#5d1e26',
  Midnight: '#262e42',
  Navy: '#353f59',
  Nickel: '#78777c',
  Olive: '#636656',
  Sand: '#d6ccb9',
  Slate: '#676871',
  White: '#f3f3f7',
  'Heather Teal': '#6DC2C6',
  'Heather Pink': '#E5C6CB',
  Sage: '#CFC7B1',
  Mauve: '#C7A9AB',
  Ivory: '#EFE5D5',
  'Heather Steel': ' #D9D8D9',
  'Heather Oat': '#F3F2EB',
  'Heather Maroon': '#663A49',
  'Stone Navy': '#303841',
  Lakeshore: '#B0C2C4',
  Cashew: ' #DCCBB4',
  Mist: '#B7C4CD',
  Moss: '#B9C7C7',
  'Dusky Rose': '#D5B6B1',
  'Stone Blue': '#A8BBC9',
  Onyx: '#07090A',
  Rosemary: '#A0905A',
  Dusk: '#CED7E5',
  Rust: '#CA584D',
  Clover: '#94A283',
  'Dark Navy': '#1D2441',
  'Sport Blue': '#2975C3',
  Forest: '#646D68',
  'Heather Blue': '#87B1C6',
  'Heather Rose': '#CD9594',
  'Heather Jade': '#A5BFB5',
  'Brushed Mist': `url(${BrMist})`,
  'Brushed Maroon': `url(${BrMaroon})`,
  'Grey Camo': `url(${GCamo})`,
  'Silver Navy': `url(${SilverNavy})`,
  Mint: '#A7BAB4',
  'Military Khaki': '#795829',
  Taupe: '#BEB29F',
  Slate: '#4B4E55',
  Olive: '#847758',
  'Washed Black': '#262625',
  'Smokey Plum': '#574855',
  'Sky Blue': '#E0E7EE',
  Canyon: '#B0786D',
  Shadow: '#867871',
  // brushedmist: `url(${George})`,
};
