/**
 * @prettier
 */

/* eslint-disable max-lines */
import React, { useEffect, useState } from 'react';
import handleize from 'lodash/kebabCase';
import Tooltip from '@material-ui/core/Tooltip';

import { Box, Flex, Text } from '@ripperoni/components';
import { useUIContext } from '@ripperoni/core';
import { useMessageBusContext } from '@ripperoni/message-bus';

import { useProduct } from '@components/ProductContext';

import { colorMapping, getInputStyle } from './inputStyle';
import isSeasonal from '../../hooks/isSeasonal';

export const ProductOptions = ({ sizeGuideId }) => {
  const { toggleModal } = useUIContext();
  const { publish, topics } = useMessageBusContext();
  const { productState, setSelectedOptions } = useProduct();
  const { product, selectedVariant, variantOptions } = productState;
  const { title } = product || {};
  const allProductOptions = productState.product.optionValues;

  const selectOption = (optionName, optionValue) => {
    setSelectedOptions(optionName, optionValue);
  };

  useEffect(() => {
    publish(topics.OPTION_SELECTION, { selectedVariant });
  }, [selectedVariant]);

  const { seasonalProducts } = isSeasonal();
  const allSeasonalProductTitles = seasonalProducts.map((a) => a.title);
  const limitedEdition = allSeasonalProductTitles.includes(product.title);
  const selectedLimitedColors = seasonalProducts.filter(
    (a) => a.title === product.title
  )[0];
  const limitedColors = selectedLimitedColors?.colors.split(', ');
  const pdpCallout = selectedLimitedColors?.callout;
  // console.log('CALLOUTS', selectedLimitedColors.callout)

  return (
    <Box data-comp={ProductOptions.displayName}>
      {Object.keys(variantOptions).map((optionName, index) => {
        if (optionName === 'Singleoption') return null;
        return (
          <Box
            key={index}
            sx={{
              borderTop: '1px solid',
              borderColor: 'gray.1',
              ...(optionName != 'Color' && optionName != 'color'
                ? {
                    py: 5,
                  }
                : { pt: 3 }),
              ...(optionName === 'Inseam' ||
              optionName === 'inseam' ||
              optionName === 'style'
                ? {
                    border: 'none',
                    paddingTop: 0,
                    marginTop: '-10px',
                  }
                : {}),
            }}
          >
            <Flex between middle px={[8, null, null, null, 0]}>
              <Text
                sx={{ py: '2px' }}
                variant='styles.h6'
                fontWeight='semi'
                fontSize={3}
              >
                {optionName.includes('Color') && (
                  <>
                    <Text fontWeight='normal' sx={{ display: 'inline' }}>
                      {selectedVariant?.selectedOptionsMap &&
                        selectedVariant?.selectedOptionsMap[optionName]}
                    </Text>
                    <Text>
                      {limitedEdition === true && optionName === 'Color' && (
                        <Text
                          fontWeight='light'
                          sx={{ display: 'inline' }}
                          fontSize='13px'
                        >
                          Core Colors
                        </Text>
                      )}
                    </Text>
                    <Flex
                      sx={{
                        marginBottom: '5px',
                        ...(optionName.toLowerCase() !== 'color'
                          ? {
                              flexWrap: 'wrap',
                              pl: [1, null, null, null, 0],
                            }
                          : {
                              flexWrap: 'wrap',
                              px: [0, null, null, null, 0],
                            }),
                      }}
                    >
                      {allProductOptions[optionName].map(
                        (optionValue, index) => {
                          if (limitedEdition === false) {
                            return (
                              <>
                                <Tooltip
                                  title={optionValue}
                                  followCursor
                                  enterDelay={800}
                                  disableFocusListener='true'
                                  leaveDelay={10}
                                >
                                  <Box
                                    key={index}
                                    // TODO: for data layer click event testing
                                    className={`variant-option variant-option-${handleize(
                                      optionName
                                    )}`}
                                    sx={{
                                      ...getInputStyle(
                                        optionName,
                                        selectedVariant?.selectedOptionsMap &&
                                          selectedVariant?.selectedOptionsMap[
                                            optionName
                                          ] === optionValue
                                      ),
                                      ':before': {
                                        bg: colorMapping[optionValue],
                                      },
                                      ...(optionValue === 'XXL' &&
                                      selectedVariant?.selectedOptionsMap &&
                                      selectedVariant?.selectedOptionsMap[
                                        optionName
                                      ] === optionValue
                                        ? {
                                            fontSize: '14px',
                                          }
                                        : null),
                                      flexShrink: 0,
                                    }}
                                    onClick={() => {
                                      selectOption(optionName, optionValue);
                                    }}
                                    mr={4}
                                    mb={2}
                                    mt={2}
                                    data-tip={optionValue}
                                  >
                                    {optionName.toLocaleLowerCase() !==
                                      'color' && optionValue}
                                  </Box>
                                </Tooltip>
                              </>
                            );
                          } else if (
                            limitedColors.includes(optionValue) === true
                          ) {
                            return <></>;
                          } else if (
                            variantOptions[optionName].includes(optionValue)
                          ) {
                            return (
                              <>
                                <Tooltip
                                  title={optionValue}
                                  followCursor
                                  enterDelay={800}
                                  leaveDelay={10}
                                  disableFocusListener='true'
                                >
                                  <Box
                                    key={index}
                                    // TODO: for data layer click event testing
                                    className={`variant-option variant-option-${handleize(
                                      optionName
                                    )}`}
                                    sx={{
                                      ...getInputStyle(
                                        optionName,
                                        selectedVariant?.selectedOptionsMap &&
                                          selectedVariant?.selectedOptionsMap[
                                            optionName
                                          ] === optionValue
                                      ),
                                      ':before': {
                                        bg: colorMapping[optionValue],
                                      },
                                      ...(optionValue === 'XXL' &&
                                      selectedVariant?.selectedOptionsMap &&
                                      selectedVariant?.selectedOptionsMap[
                                        optionName
                                      ] === optionValue
                                        ? {
                                            fontSize: '14px',
                                          }
                                        : null),
                                      flexShrink: 0,
                                    }}
                                    onClick={() => {
                                      selectOption(optionName, optionValue);
                                    }}
                                    mr={4}
                                    mb={0.45}
                                    mt={1}
                                    data-tip={optionValue}
                                  >
                                    {optionName.toLocaleLowerCase() !==
                                      'color' && optionValue}
                                  </Box>
                                </Tooltip>
                              </>
                            );
                          }

                          return <></>;
                        }
                      )}
                    </Flex>
                  </>
                )}
                {limitedEdition === true && optionName === 'Color' && (
                  <Text
                    fontWeight='light'
                    sx={{ display: 'inline' }}
                    fontSize='13px'
                  >
                    {pdpCallout}
                  </Text>
                )}
                {optionName === 'Style' && <>Style</>}
                {optionName === 'Inseam' && <>Inseam</>}
                {optionName === 'Waist' && <>Waist</>}
                {optionName === 'Size' && <>Size</>}
                {optionName === 'Fit' && <>Fit</>}
                {optionName === 'Amount' && <>Amount</>}
                {optionName !== 'Color' && (
                  <>
                    <Text fontWeight='normal' sx={{ display: 'inline' }}>
                      :
                    </Text>
                    &nbsp;
                    {selectedVariant?.selectedOptionsMap &&
                    selectedVariant?.selectedOptionsMap[optionName] === '7 ½"'
                      ? `7.5"`
                      : selectedVariant?.selectedOptionsMap &&
                        selectedVariant?.selectedOptionsMap[optionName] ===
                          '9 ½"'
                      ? `9.5"`
                      : selectedVariant?.selectedOptionsMap &&
                        selectedVariant?.selectedOptionsMap[optionName] ===
                          '5 ½"'
                      ? `5.5"`
                      : selectedVariant?.selectedOptionsMap &&
                        selectedVariant?.selectedOptionsMap[optionName]}
                  </>
                )}
              </Text>

              {/* Weekend items New Logic */}
              {/* {title.includes('Weekend') &&
                (selectedVariant?.selectedOptionsMap[optionName] ===
                  'Stone Navy' ||
                  selectedVariant?.selectedOptionsMap[optionName] ===
                    'Heather Maroon' ||
                  selectedVariant?.selectedOptionsMap[optionName] ===
                    'Black') && (
                  <Box position='relative' margin='auto'>
                    <Text
                      position='absolute'
                      margin='auto'
                      bottom={4}
                      left={-7}
                      sx={{
                        border: '1px solid #333333',
                        borderRadius: '20px',
                        color: '#353535',
                        fontSize: '12px',
                        fontWeight: '400',
                        px: 7,
                        py: '2px',
                      }}
                    >
                      New
                    </Text>
                  </Box>
                )} */}
              {/* Go to Hoodie New logic */}
              {/* {title.includes('Go-To Hoodie') &&
                (selectedVariant?.selectedOptionsMap[optionName] ===
                  'Heather Silver Spoon' ||
                  selectedVariant?.selectedOptionsMap[optionName] ===
                    'Black') && (
                  <Box position='relative' margin='auto'>
                    <Text
                      position='absolute'
                      margin='auto'
                      bottom={4}
                      left={-7}
                      sx={{
                        border: '1px solid #333333',
                        borderRadius: '20px',
                        color: '#353535',
                        fontSize: '12px',
                        fontWeight: '400',
                        px: 7,
                        py: '2px',
                      }}
                    >
                      New
                    </Text>
                  </Box>
                )} */}
              {(optionName === 'Size' || optionName === 'Waist') && (
                <Text
                  onClick={() => toggleModal(`${sizeGuideId}::quick-chart`)}
                  fontWeight='semi'
                  textDecoration='underline'
                  fontSize={0}
                  sx={{
                    cursor: 'pointer',
                  }}
                  mb={2}
                >
                  Size Guide
                </Text>
              )}
            </Flex>

            <Flex
              sx={{
                marginBottom: '2px',
                ...(optionName.toLowerCase() !== 'color'
                  ? {
                      flexWrap: 'wrap',
                      pl: [8, null, null, null, 0],
                    }
                  : {
                      flexWrap: 'wrap',
                      px: [8, null, null, null, 0],
                    }),
              }}
            >
              {allProductOptions[optionName].map((optionValue, index) => {
                if (limitedEdition === false) {
                  return <></>;
                } else if (limitedColors.includes(optionValue) === false) {
                  return <></>;
                } else if (variantOptions[optionName].includes(optionValue)) {
                  return (
                    <>
                      <Tooltip
                        title={optionValue}
                        followCursor
                        enterDelay={800}
                        leaveDelay={10}
                        disableFocusListener='true'
                      >
                        <Box
                          key={index}
                          // TODO: for data layer click event testing
                          className={`variant-option variant-option-${handleize(
                            optionName
                          )}`}
                          css={{
                            ...getInputStyle(
                              optionName,
                              selectedVariant?.selectedOptionsMap &&
                                selectedVariant?.selectedOptionsMap[
                                  optionName
                                ] === optionValue
                            ),
                            ':before': {
                              background: colorMapping[optionValue],
                            },
                            ...(optionValue === 'XXL' &&
                            selectedVariant?.selectedOptionsMap &&
                            selectedVariant?.selectedOptionsMap[optionName] ===
                              optionValue
                              ? {
                                  fontSize: '14px',
                                }
                              : null),
                            flexShrink: 0,
                          }}
                          onClick={() => {
                            selectOption(optionName, optionValue);
                          }}
                          mr={4}
                          mb={1}
                          data-tip={optionValue}
                        >
                          {optionName.toLocaleLowerCase() !== 'color' &&
                            optionValue}
                        </Box>
                      </Tooltip>
                    </>
                  );
                }

                return <></>;
              })}
              {allProductOptions[optionName].map((optionValue, index) => {
                if (optionName === 'Color') {
                  return <></>;
                } else if (variantOptions[optionName].includes(optionValue)) {
                  return (
                    <>
                      {/* Renders SIZES of products */}
                      <Box
                        key={index}
                        // TODO: for data layer click event testing
                        className={`variant-option variant-option-${handleize(
                          optionName
                        )}`}
                        sx={{
                          ...getInputStyle(
                            optionName,
                            selectedVariant?.selectedOptionsMap &&
                              selectedVariant?.selectedOptionsMap[
                                optionName
                              ] === optionValue
                          ),
                          ':before': {
                            bg: colorMapping[optionValue],
                          },
                          ...(optionValue === 'XXL' &&
                          selectedVariant?.selectedOptionsMap &&
                          selectedVariant?.selectedOptionsMap[optionName] ===
                            optionValue
                            ? {
                                fontSize: '14px',
                              }
                            : null),
                          flexShrink: 0,
                        }}
                        onClick={() => {
                          selectOption(optionName, optionValue);
                        }}
                        mr={4}
                        mb={3}
                        mt={1}
                      >
                        {optionName.toLocaleLowerCase() !== 'color' &&
                        optionValue === '7 ½"'
                          ? `7.5"`
                          : optionName.toLocaleLowerCase() !== 'color' &&
                            optionValue === '9 ½"'
                          ? `9.5"`
                          : optionName.toLocaleLowerCase() !== 'color' &&
                            optionValue === '5 ½"'
                          ? `5.5"`
                          : optionName.toLocaleLowerCase() !== 'color' &&
                            optionValue}
                      </Box>
                    </>
                  );
                }
                return (
                  <Box
                    key={index}
                    // TODO: for data layer click event testing
                    className={`variant-option  variant-option-${handleize(
                      optionName
                    )}`}
                    sx={{
                      ...getInputStyle(
                        optionName,
                        selectedVariant?.selectedOptionsMap &&
                          selectedVariant?.selectedOptionsMap[optionName] ===
                            optionValue
                      ),
                      ':before': {
                        bg: colorMapping[optionValue],
                      },
                      ...(optionValue === 'XXL' &&
                      selectedVariant?.selectedOptionsMap &&
                      selectedVariant?.selectedOptionsMap[optionName] ===
                        optionValue
                        ? {
                            fontSize: '14px',
                          }
                        : null),
                      pointerEvents: 'none',
                      opacity: '.70',
                      color: '#AFAFAF',
                      position: 'relative',
                      top: 1,
                      flexShrink: 0,
                      '::after': {
                        content: '""',
                        display: 'block',
                        width: '44px',
                        height: '1px',
                        bg: '#E1E1E1',
                        position: 'absolute',
                        transform: 'translate(-50%, -50%) rotate(-39deg)',
                        top: '50%',
                        left: '50%',
                      },
                    }}
                    mr={4}
                    mb={4}
                  >
                    {optionName.toLocaleLowerCase() !== 'color' && optionValue}
                  </Box>
                );
              })}
            </Flex>
          </Box>
        );
      })}
    </Box>
  );
};

ProductOptions.displayName = 'ProductOptions';

export default ProductOptions;
