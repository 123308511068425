import React from 'react';
import PropTypes from 'prop-types';

import { Box, CmsContent, Heading, Text } from '@ripperoni/components';


export const FabricTile = ({
  heading,
  text,
  cta
}) => {
  return (
    <Box
      bg='gray.1'
      p={[8, null, null, 9]}
      position='relative'
      width='100%'
      sx={{
        '::before': {
          content: '""',
          display: 'block',
          paddingBottom: '65%'
        }
      }}
    >
      <Box
        position='absolute'
        left={[8, null, null, '50%']}
        top='50%'
        transform={['translate(0, -50%)', null, null, 'translate(-50%, -50%)']}
        width='100%'
      >
        <CmsContent
          as={Heading}
          variant='text.h5'
          mb={3}
          textAlign={['left', null, null, 'center']}
          content={heading}
        />

        <CmsContent
          as={Text}
          textAlign={['left', null, null, 'center']}
          content={text}
        />
      </Box>

      <CmsContent
        color='black'
        mt={10}
        width='full'
        textAlign={['left', null, null, 'center']}
        m={0}
        display='block'
        content={cta}
      />
    </Box>
  );
};

FabricTile.propTypes = {
  heading: PropTypes.array,
  text: PropTypes.array,
  cta: PropTypes.array,
};

export default FabricTile;
