/**
 * @prettier
 */
/* eslint-disable max-lines */
import { subscribe, topics } from '@ripperoni/message-bus';
import { getLegacyShopifyId, isBrowser } from '@ripperoni/utilities';

const pushData = (data) => {
  if (!isBrowser || !window.dataLayer) return;
  window.dataLayer.push(data);
};

const updateGlobalECart = (message, data) => {
  if (!isBrowser) return;
  window?.GEM_Components?.ExternalMethodsComponent?.UpdateCart(data);
  // console.log('updating global-e cart...', data);
};

const subscriberWrapper = (action) => (messsage, data) => {
  try {
    pushData(action(data));
    // console.log(`${messsage} - sending to dataLayer...`, action(data));
  } catch (error) {
    console.log('message:', messsage, '\ndata:', data, '\nerror:', error);
  }
};

const intialAccountStatus = (customer) => {
  return {
    visitorType: 'Guest',
    customer: customer,
  };
};

const customerLoggedIn = (customer) => {
  return {
    visitorType: 'Guest',
    customer: customer,
  };
};

const routeChange = ({ pathname = '' }) => {
  return {
    event: 'page-updated',
    pageType: pathname.split('/')[1] || 'home',
  };
};

const optionSelection = ({ selectedVariant = {} }) => {
  return {
    event: 'optionSelection',
    ecommerce: {
      detail: { ...selectedVariant?.selectedOptionsMap },
    },
  };
};

const addToCart = ({ items: lineItems = [], cart }) => {
  const items = lineItems.map(({ variantId }) => {
    return cart.lineItems.find(({ variant }) => {
      return variant.id === variantId;
    });
  });

  return {
    event: 'addToCart',
    ecommerce: {
      currencyCode: 'USD',
      add: {
        products: items.map((item) => ({
          quantity: 1,
          brand: 'Public Rec',
          name: item.variant.product.handle,
          id: getLegacyShopifyId(item.variant.id),
          productId: getLegacyShopifyId(item.variant.product.id),
          variantId: getLegacyShopifyId(item.variant.id),
          price: parseInt(item.variant.price) || 0,
          compareAtPrice: parseInt(item.variant.compareAtPrice) || 0,
        })),
      },
    },
  };
};

const cartView = ({ lineItems = [] }) => {
  return {
    ecommerce: {
      event: 'cartView',
      currencyCode: 'USD',
      actionField: {
        list: lineItems.filter((item) => item.variant)
          ? 'Inline Cart'
          : 'Empty Cart',
      },
      impressions:
        lineItems
          .filter((item) => item.variant)
          .map((item, index) => ({
            brand: 'Public Rec',
            position: index,
            id: item.variant.sku,
            productId: item.variant.product.id,
            variantId: item.variant.id,
            name: item.title.replace("'", ''),
            quantity: item.quantity,
            price: item.variant.price,
            variant: item.variant.title,
          })) || [],
    },
  };
};

const productView = ({
  location: { origin },
  product: { title, featuredImage },
  selectedVariant: variant,
}) => {
  const featImg =
    origin && featuredImage?.localFile
      ? `${origin}${featuredImage.localFile.publicURL}`
      : featuredImage.src;

  return {
    event: 'productDetailView',
    ecommerce: {
      currencyCode: 'USD',
      detail: {
        products: [
          {
            brand: 'Public Rec',
            handle: variant.foreignProductHandle,
            name: title.replace("'", ''),
            id: getLegacyShopifyId(variant.foreignId),
            productId: getLegacyShopifyId(variant.productForeignId),
            variantId: getLegacyShopifyId(variant.foreignId),
            price: variant.price,
            compareAtPrice: variant.compare_at_price,
            color: variant.selectedOptionsMap.Color,
            size: variant.selectedOptionsMap.Size,
            inventoryCount: variant.inventory,
            featImg,
          },
        ],
      },
    },
  };
};

const collectionView = (collection = {}) => {
  return {
    event: 'collectionView',
    id: collection.id,
    handle: collection.handle,
    title: collection.title,
    ecommerce: {
      currencyCode: 'USD',
      actionField: {
        list: 'Collection Page',
      },
      impressions: collection.variants.map(({ product }, index) => ({
        position: index,
        name: product.title,
        handle: product.handle,
      })),
    },
  };
};

export const bindSubscriptions = () => {
  // DataLayer
  subscribe(topics.INITIAL_LOAD, subscriberWrapper(routeChange));
  subscribe(topics.ROUTE_CHANGE, subscriberWrapper(routeChange));
  subscribe(topics.CUSTOMER_READY, subscriberWrapper(intialAccountStatus));
  subscribe(topics.CUSTOMER_LOGIN, subscriberWrapper(customerLoggedIn));
  subscribe(topics.OPTION_SELECTION, subscriberWrapper(optionSelection));
  subscribe(topics.VIEW_CART, subscriberWrapper(cartView));
  subscribe(topics.VIEW_PRODUCT, subscriberWrapper(productView));
  subscribe(topics.VIEW_COLLECTION, subscriberWrapper(collectionView));

  subscribe(topics.ADD_TO_CART, subscriberWrapper(addToCart));

  // GlobalE
  subscribe(topics.UPDATE_CART, updateGlobalECart);
  subscribe(topics.VIEW_CART, updateGlobalECart);
};
