import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Box, Flex, Text } from '@ripperoni/components';
import { useUIContext } from '@ripperoni/core';

import { useProduct } from '@components/ProductContext';

import isModelCopy from '../../hooks/isModelCopy';

export const ModelVariantToggle = ({ selectedOptionsMap, product, type }) => {
  const { setSelectedOptions } = useProduct();
  const [activeIndex, setActiveIndex] = useState(0);
  const [colorList, setColorList] = useState([]);
  const { toggleModal } = useUIContext();
  const { handle } = product;

  const colorModelFitMap = product.variants.reduce((acc, curr) => {
    const variantColor = curr.selectedOptionsMap.Color;
    const modelFitDesc = curr.metadata.modelFit;

    if (!acc[variantColor] && modelFitDesc) {
      acc[variantColor] = modelFitDesc;
    }

    return acc;
  }, {});

  const { modelCopyReplacements } = isModelCopy();
  // console.log('Model copy rep', modelCopyReplacements);

  useEffect(() => {
    const colorList = Object.keys(colorModelFitMap);
    setColorList(colorList);

    const activeIndex = colorList.indexOf(selectedOptionsMap.Color);
    setActiveIndex(activeIndex);
  }, [selectedOptionsMap]);

  useEffect(() => {
    if (activeIndex >= 0) {
      const color = colorList[activeIndex];
      setSelectedOptions('Color', color);
    }
  }, [activeIndex]);

  const nextVariant = (index) => {
    const colorListLength = colorList.length;

    if (index < 0) {
      setActiveIndex(colorListLength - 1);
    } else if (index === colorListLength) {
      setActiveIndex(0);
    } else {
      setActiveIndex(index);
    }
  };

  if (activeIndex < 0) return null;

  return (
    <Box>
      <Flex bottom between>
        <Box flexGrow={1} mr={6}>
          {colorModelFitMap[colorList[activeIndex]] &&
            colorModelFitMap[colorList[activeIndex]]
              .split('|')
              .map((msg, index) => {
                if (product?.title == 'Flex Short') {
                  const currentStyle = selectedOptionsMap.Style.toString();
                  const currentColor = selectedOptionsMap.Color.toString();
                  const currentInseam = selectedOptionsMap.Inseam.toString();
                  const newModelCopyFilter = modelCopyReplacements.filter(
                    (a) =>
                      a.color === currentColor &&
                      a.style === currentStyle &&
                      a.inseam === currentInseam
                  );
                  const newModelCopy = newModelCopyFilter[0]?.message;
                  return (
                    <Text key={index} fontSize={1}>
                      {newModelCopy}
                    </Text>
                  );
                }
                if (!msg) return null;

                return (
                  <Text key={index} fontSize={1}>
                    {msg}
                  </Text>
                );
              })}
        </Box>

        <Flex
          middle
          mb={4}
          display={['none', null, null, 'flex', null]}
          sx={{
            '.arrow': {
              border: 'solid black',
              borderWidth: '0 2px 2px 0',
              display: 'inline-block',
              padding: '4px',
              cursor: 'pointer',
            },
            '.arrow.right': {
              transform: 'rotate(-45deg)',
            },
            '.arrow.left': {
              transform: 'rotate(135deg)',
            },
          }}
        >
          <Box
            mr={4}
            className='arrow left'
            onClick={() => nextVariant(activeIndex - 1)}
          />
          <Box
            className='arrow right'
            onClick={() => nextVariant(activeIndex + 1)}
          />
        </Flex>
      </Flex>

      {type === "Women's Joggers" && (
        <Text
          textDecoration='underline'
          fontSize={1}
          onClick={() => toggleModal('full-size-guide-modal::other-sizes')}
          sx={{
            cursor: 'pointer',
          }}
        >
          See in other sizes
        </Text>
      )}
    </Box>
  );
};

ModelVariantToggle.propTypes = {};
