/**
 * @jsx jsx
 */
import { jsx } from 'theme-ui';
import React, { memo, useCallback } from 'react';

import { Flex, Text } from '@ripperoni/components';
import { isBrowser } from '@ripperoni/utilities';

import { useProduct } from '@components/ProductContext';

import useYotpo from './useYotpo';

export const ProductStars = memo(() => {
  const { productState } = useProduct();

  const { selectedVariant } = productState;

  const { productForeignId } = selectedVariant || {};

  const { MaybeInjectYoptoScript } = useYotpo();

  const scrollToReview = useCallback(() => {
    if (!isBrowser) return;
    const reviewsContainer = document.getElementById('YotpoProductReviews');
    reviewsContainer.scrollIntoView({ behavior: 'smooth' });
  });

  return (
    <>
      <Flex
        data-comp={ProductStars.displayName}
        css={yoptoStarsCss}
        mt={'10px'}
        sx={{ cursor: 'pointer' }}
        onClick={scrollToReview}
      >
        <div
          className='yotpo bottomLine'
          data-product-id={productForeignId?.replace(
            'gid://shopify/Product/',
            ''
          )}
        />
      </Flex>
      {MaybeInjectYoptoScript}
    </>
  );
});

const yoptoStarsCss = {
  '.yotpo.bottomLine .yotpo-bottomline .yotpo-icon-star, .yotpo.bottomLine .yotpo-bottomline .yotpo-icon-half-star, .yotpo.bottomLine .yotpo-bottomline .yotpo-icon-empty-star':
    {
      fontSize: '17px !important',
      color: '#141414 !important',
    },
  '.text-m': {
    fontSize: '12px !important',
    textDecoration: 'underline !important',
    fontFamily:
      'Messina Sans, Helvetica Neue, Helvetica, Arial, sans-serif !important',
    fontWeight: '600 !important',
    marginLeft: '6px !important',
  },
  '.text-m:hover': {
    textDecoration: 'underline !important',
    color: '#141414 !important',
  },
  'div:active': {
    outline: 0,
  },
  div: {
    pointerEvents: 'none',
  },
};

ProductStars.displayName = 'ProductStars';
