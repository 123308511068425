/**
 * @prettier
 */
import React from 'react';
import PropTypes from 'prop-types';

import {
  Box,
  CmsContent,
  Container,
  Flex,
  Grid,
  Heading,
} from '@ripperoni/components';

export const SizeIntro = ({
  heading,
  image,
  sizingBox1,
  sizingBox2,
  sizingBoxes,
  ...props
}) => {
  return (
    <Container.Full
      data-comp={SizeIntro.displayName}
      bg='gray.0'
      px={8}
      {...props}
    >
      <Container pt={[11, null, null, 15]} pb={[11, null, null, 0]}>
        <Grid
          areas={[
            `
              "intro-text"
              "intro-image"
              "intro-sizes"
            `,
            null,
            null,
            `
              "intro-text intro-text"
              "intro-image intro-sizes"
            `,
          ]}
          columns={['1fr', null, null, '1fr 1fr']}
          rows={['repeat(3, auto)', null, null, 'repeat(2, auto)']}
        >
          <Box gridArea='intro-text' mb={[11, null, null, 15]}>
            <CmsContent
              variant='heading.h2'
              as={Heading}
              mx='auto'
              textAlign='center'
              fontSize={[7, null, null, 9]}
              maxWidth='840px'
              content={heading}
            />
          </Box>

          <Box gridArea='intro-image' textAlign='center'>
            <CmsContent content={image} />
          </Box>

          <Flex
            gridArea='intro-sizes'
            mt={[9, null, null, 0]}
            pl={[0, null, 8, null, 15]}
          >
            <CmsContent mr={9} flexBasis='50%' content={sizingBoxes[0]} />

            <CmsContent flexBasis='50%' content={sizingBoxes[1]} />
          </Flex>
        </Grid>
      </Container>
    </Container.Full>
  );
};

SizeIntro.displayName = 'SizeIntro';

SizeIntro.propTypes = {
  heading: PropTypes.array,
  image: PropTypes.array,
  sizingBox1: PropTypes.array,
  sizingBox2: PropTypes.array,
};
