require('swiper/swiper-bundle.min.css');

const onClientEntry = require('./gatsby/browser/on-client-entry');
const onInitialClientRender = require('./gatsby/browser/on-initial-client-render');

exports.onInitialClientRender = onInitialClientRender;
exports.onClientEntry = onClientEntry;

exports.shouldUpdateScroll = ({
  prevRouterProps,
  routerProps,
  getSavedScrollPosition,
}) => {
  const scroll = `@@scroll|${routerProps.location.pathname}|${routerProps.location.key}`;
  const currentPosition = window.sessionStorage.getItem(scroll);

  // hack to override `shouldUpdateScroll` behavior to bypass useQueryParams in product context
  if (
    routerProps.location.search.includes('variant=') &&
    prevRouterProps &&
    prevRouterProps.location.pathname === routerProps.location.pathname
  ) {
    return false;
  }

  if (currentPosition) {
    setTimeout(() => {
      window.scrollTo(0, currentPosition || 0);
    }, 500);

    return false;
  }
};
