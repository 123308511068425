export const text = {
  layout: {
    meta: {
      email: {
        variant: 'text.default',
        fontWeight: 'light',
        fontSize: 3,
        textDecoration: 'none',
        color: 'gray.2'
      }
    },
    navigation: {
      color: 'text',
      fontSize: 1,
      fontWeight: 'semi',
    },
  },
  addressBook: {
    address: {
      name: {
        fontWeight: 'semi',
      },
    },
  },
  order: {
    header: {
      date: {
        variant: 'text.default',
        color: 'gray.2',
      },
    },
    total: {
      title: {
        variant: 'text.heading',
        fontSize: 3,
      },
      price: {
        variant: 'text.default'
      },
    }
  },
  orders: {
    table: {
      headerCell: {
        orderNumber: {
          variant: 'styles.h6',
        },
        date: {
          variant: 'styles.h6',
        },
        status: {
          variant: 'styles.h6',
        },
        price: {
          variant: 'styles.h6',
        },
      },
      cell: {
        orderNumber: {
          // variant: 'text.default',
        },
        date: {
          variant: 'text.default',
        },
        status: {
          variant: 'text.default',
        },
        price: {
          variant: 'text.default',
        },
      }
    }
  },
};
