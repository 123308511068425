/** @jsx jsx */
import { useEffect } from 'react';
import { jsx } from 'theme-ui';
import { AnimatePresence, motion } from 'framer-motion';
// import { OptimizelyProvider, createInstance } from '@optimizely/react-sdk';
import { v4 as uuidv4 } from 'uuid';
import { btoa } from 'abab';

import { Cart, useCart } from '@ripperoni/cart';
import { useCustomer } from '@ripperoni/account';
import { getLegacyShopifyId, isBrowser } from '@ripperoni/utilities';

import { Header } from '@components/Header';
import { Footer } from '@components/Footer';
import { Discount } from '@components/Discount';

export const Main = ({ children, location }) => {
  const cart = useCart();
  const customer = useCustomer();
  const shouldAnimate = location?.state?.animate !== false;

  // const optimizely = createInstance({
  //   sdkKey: '8LAYKfowg8RZtDknALVcn',
  // });

  // for global-e
  useEffect(() => {
    if (!isBrowser) return;

    if (customer?.id) {
      const id = getLegacyShopifyId(customer.id);
      window.localStorage.setItem('customer-id', id);
    } else if (customer === false) {
      window.localStorage.removeItem('customer-id');
    }
  }, [customer]);

  // for global-e
  useEffect(() => {
    if (!isBrowser) return;

    if (cart?.id) {
      window.localStorage.setItem('ShopifyCheckoutId', btoa(cart.id));
    }
  }, [cart]);

  return (
    <div data-comp={Main.displayName}>
      <style>{globalCss}</style>

      <Header />
      <Discount />

      <Cart
        checkoutParams={'&glCountry=US'}
        checkoutUrlCallback={(checkoutUrl) => {
          return (
            checkoutUrl &&
            checkoutUrl.replace(
              'public-rec-2.myshopify.com',
              'shop.publicrec.com'
            )
          );
        }}
        // to deal with https://stackoverflow.com/questions/37112218/css3-100vh-not-constant-in-mobile-browser
        pb='44px'
        zIndex='999999'
      />

      {shouldAnimate ? (
        <AnimatePresence>
          <motion.main
            key={location.pathname}
            variants={variants}
            initial='initial'
            animate='enter'
            exit='exit'
            sx={{
              bg: 'white',
              width: '100%',
              paddingTop: [73, null, null, null, 89],
            }}
          >
            {children}
          </motion.main>
        </AnimatePresence>
      ) : (
        <main
          key={location.pathname}
          sx={{
            bg: 'white',
            width: '100%',
            paddingTop: [73, null, null, null, 89],
          }}
        >
          {children}
        </main>
      )}

      <Footer />
    </div>
  );
};

Main.displayName = 'Public Rec Main Layout';

const variants = {
  initial: {
    opacity: 0,
  },
  enter: {
    opacity: 1,
    transition: {
      duration: 0.25,
      delay: 0.25,
      when: 'beforeChildren',
    },
  },
  exit: {
    opacity: 0,
    transition: {
      duration: 0.25,
    },
  },
};

// we need global css to override user agent styles from the browser.
// since many elements come from Contentful, it's difficult to override these styles.
// theme-ui doesn't provide an easy way to apply globabl styles (ex all a tags)
const globalCss = `
    #kustomer-ui-sdk-iframe {
      z-index: 100 !important;
    }
    a:-webkit-any-link {
        color: inherit;
        text-decoration: inherit;
    }
    a.discount {
      color: #9D3838 !important;
    }

    @media only screen and (max-width: 480px) {
      .mapboxgl-map {
        height: 42vh !important;
      }
    }
    .mapboxgl-map {
      height: 65vh;
      font-family: "Messina Sans", "Helvetica Neue", Helvetica, Arial, sans-serif !important;
    }
    .mapboxgl-popup-close-button {
      outline: none !important;
    }
    .mapboxgl-popup-content {
      border-radius: 6px !important;
      padding: 10px 20px 15px !important;
    }
    .mapboxgl-ctrl-bottom-left, .mapboxgl-ctrl-bottom-right {
      display: none;
    }
    .swiper-wrapper {
      align-items: center !important;
    }
    #c15afe38-19aa-5a2e-850f-185e90713337 > a:nth-child(7) {
      color: white !important;
    },
`;
