/* eslint-disable import/no-default-export */
import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import { ContentfulContent } from '@ripperoni/cms';

import { ImageCompare } from '@components/ImageCompare';


const About = props => {
  const {
    aboutSizing,
    fabricInfo,
    videoSlider,
    imageCompare
  } = useStaticQuery(StaticQuery);

  return (
    <>
      <ContentfulContent {...imageCompare} />
      <ContentfulContent {...aboutSizing} />
      <ContentfulContent {...fabricInfo} />
      <ContentfulContent {...videoSlider} />
    </>
  );
};

export default About;

const StaticQuery = graphql`
  {
    aboutSizing: contentfulMolecule (metaHandle: {eq: "about-sizing"}) {
      ...ContentfulMolecule
    }
    fabricInfo: contentfulMolecule (metaHandle: {eq: "aboutFabricInfo"}) {
      ...ContentfulMolecule
    }
    videoSlider: contentfulMolecule (metaHandle: {eq: "about-video-slider"}) {
      ...ContentfulMolecule
    }
    imageCompare: contentfulMolecule (metaHandle: {eq: "about-image-compare"}) {
      ...ContentfulMolecule
    }
  }
`;
