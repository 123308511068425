import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { FlexCol, Modal } from '@ripperoni/components';

import { EmailSignUp } from './EmailSignUp';
import { SmsSignUp } from './SmsSignUp';

export const BackInStockModal = (props) => {
  const [emailSubmitted, setEmailSubmitted] = useState(false);
  const { options } = props;

  useEffect(() => {
    setEmailSubmitted(false);
  }, [options.Size, options.Color]);

  return (
    <Modal modalId='back-in-stock-modal'>
      <FlexCol py={12} px={8} sx={{ alignItems: 'center' }}>
        {!emailSubmitted ? (
          <EmailSignUp
            setEmailSubmitted={() => setEmailSubmitted(true)}
            {...props}
          />
        ) : (
          <SmsSignUp
            setEmailSubmitted={() => setEmailSubmitted(false)}
            {...props}
          />
        )}
      </FlexCol>
    </Modal>
  );
};

BackInStockModal.propTypes = {
  variantId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  options: PropTypes.object.isRequired,
};
