import React, { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useResponsiveValue } from '@theme-ui/match-media';
import Swiper from 'react-id-swiper';

import { Box, Grid, InView } from '@ripperoni/components';

import ProductTile from '@components/ProductTile';
import { useCollectionProducts } from '@components/CollectionContext';

import CategoryTile from './CollectionCategoryTile';

function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  return windowSize;
}

export const CollectionSection = ({
  categoryImages,
  productCategory,
  categoryProducts,
}) => {
  const { imageType } = useCollectionProducts();

  const { width } = useWindowSize();
  const sliderRef = useRef(null);
  const sliderParams = {
    loop: false,
    slidesPerView: 1.25,
    spaceBetween: 20,
    centerSlides: true,
    observeSlideChildren: true,
    breakpoints: {
      768: {
        slidesPerView: 2.25,
      },
    },
  };

  const productTiles = useMemo(() => {
    return categoryProducts.map((variant, index) => {
      const variantIntId = parseInt(
        variant.id.split('Backpack__ProductVariant__')[1]
      );
      return (
        <Box
          key={`${index}-${btoa(variant.foreignId)}`}
          width='100%'
          sx={{
            '[data-comp="Product-Tile-Title"]': {
              display: ['none', null, null, null, 'block'],
            },
          }}
        >
          <InView
            seqIndex={variantIntId}
            triggerOnce={true}
            resetHeightTo='mounted'
            root={null}
            sx={{
              width: '100%',
              height: '100%',
            }}
          >
            <ProductTile imageType={imageType} variantId={variant.id} />
          </InView>
        </Box>
      );
    });
  }, [categoryProducts.length, imageType]);

  const categoryImage =
    categoryImages &&
    categoryImages.nodes &&
    categoryImages.nodes.filter(
      (image) => image.metaHandle === productCategory
    )[0];

  return (
    <Grid
      display={['block', null, null, null, 'grid']}
      columns={['1fr', null, 'repeat(2, 1fr)', null, 'repeat(3, 1fr)']}
      columnGap={8}
      rowGap={11}
      mb={11}
      sx={{
        '[data-comp^="InView-"], [data-comp="InViewChildrenRendered"]': {
          width: '100%',
          height: '100%',
        },
      }}
    >
      <CategoryTile
        image={categoryImage}
        title={
          categoryProducts[0].product.title === 'Workday Pant'
            ? 'Workday Pant 2.0'
            : categoryProducts[0].product.title === 'Workday Short'
            ? 'Workday Short 2.0'
            : categoryProducts[0].product.title
            ? categoryProducts[0].product.title.includes('Archive')
              ? categoryProducts[0].product.title.replace('Archive', '')
              : categoryProducts[0].product.title
            : categoryProducts[0].product.title
        }
        categoryPrice={categoryProducts[0].price}
        product={categoryProducts[0].product}
      />

      {width < 992 ? (
        <Swiper {...sliderParams} ref={sliderRef}>
          {productTiles}
        </Swiper>
      ) : (
        productTiles
      )}
    </Grid>
  );
};

CollectionSection.propTypes = {};

export default CollectionSection;
