import React from 'react';
import PropTypes from 'prop-types';

import { Box, CmsContent, Container, Flex, Grid, Heading, Text } from '@ripperoni/components';


export const AboutSizing = ({
  heading,
  text,
  image,
  sizingBoxes,
  ...props
}) => {
  return (
    <Container.Full
      data-comp={AboutSizing.displayName}
      bg='gray.0'
      px={8}
      {...props}
    >
      <Container
        py={[11, null, null, null, 15]}
      >
        <Grid
          areas={[
            `
              "sizing-text"
              "sizing-image"
              "sizing-sizes"
            `,
            null,
            null,
            null,
            `
              "sizing-image sizing-image sizing-text"
              "sizing-image sizing-image sizing-sizes"
            `
          ]}
          columns={['1fr', null, null, null, '2fr 1fr']}
          rows={['repeat(3, auto)', null, null, null, 'repeat(2, auto)']}
          columnGap={[null, null, null, 9, null, 13]}
        >
          <Box
            gridArea='sizing-image'
            mt={[7, null, null, null, 0]}
            mb={[9, null, null, null, 0]}
            alignSelf='center'
          >
            <CmsContent content={image} />
          </Box>

          <Flex.Col
            gridArea='sizing-text'
            bottom
          >
            <CmsContent
              as={Heading}
              textAlign={['left', null, null, 'center']}
              mb={2}
              content={heading}
            />

            <CmsContent
              as={Text}
              content={text}
              maxWidth={[null, null, null, '448px']}
              mx='auto'
            />
          </Flex.Col>

          <Flex
            between
            gridArea='sizing-sizes'
            mt={[0, null, null, null, 9]}
          >
            {
              sizingBoxes[0] &&
              <CmsContent
                width='100%'
                content={sizingBoxes[0]}
              />
            }

            {
              sizingBoxes[1] &&
              <CmsContent
                width='100%'
                content={sizingBoxes[1]}
              />
            }
          </Flex>

        </Grid>
      </Container>
    </Container.Full>
  );
};

AboutSizing.displayName = 'AboutSizing';

AboutSizing.propTypes = {
  heading: PropTypes.array,
  text: PropTypes.array,
  image: PropTypes.array,
  sizingBoxes: PropTypes.array,
};

export default AboutSizing;
