import React, { useEffect, useState } from 'react';

// import useGlobal from '@theme/store';
import { isBrowser } from '@ripperoni/utilities';

import {
  useCart,
  useUpdateCartAttributes,
  useCheckoutUrl,
  useCartContext,
} from '@packdigital/gatsby-theme-ripperoni-cart';

export const Discount = () => {
  const DISCOUNTCODEKEY = 'discount_code';
  const [discountCode, setDiscountCode] = useState('');
  const updateDiscountCode = useUpdateCartAttributes();

  useEffect(() => {
    if (isBrowser) {
      const urlParams = new URLSearchParams(window.location.search);
      const discountCodeParam = urlParams.get(DISCOUNTCODEKEY);
      const sessionDiscount = sessionStorage.getItem(DISCOUNTCODEKEY);

      if (sessionDiscount === null) {
        sessionStorage.removeItem(DISCOUNTCODEKEY);
      }

      if (discountCodeParam && sessionDiscount !== discountCodeParam) {
        sessionStorage.setItem(DISCOUNTCODEKEY, discountCodeParam);
        setDiscountCode(discountCodeParam);
      }
    }
  }, []);

  useEffect(() => {
    updateDiscountCode([
      { key: DISCOUNTCODEKEY, value: sessionStorage.getItem(DISCOUNTCODEKEY) },
    ]);
  }, [discountCode]);

  return null;
};
