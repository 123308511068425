import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types'
;

import { Box, Flex, Svg, Text } from '@ripperoni/components';

import ArrowDown from '@assets/svgs/arrow-down.svg';

function useOutsideClick(ref, callback) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        callback && callback();
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}


const Dropdown = ({ title, children }) => {
  const [active, setActive] = useState(false);
  const dropDownRef = useRef(null);
  useOutsideClick(dropDownRef, () => setActive(false));

  Dropdown.handleClickOutside = () => setActive(false);


  return (
    <Box
      position='relative'
      ml={7}
      ref={dropDownRef}
    >
      <Flex
        onClick={() => setActive(!active)}
        middle
        px={7}
        py={3}
        sx={{
          border: '1px solid',
          borderColor: 'gray.2',
          borderRadius: '20px',
          cursor: 'pointer'
        }}
      >
        <Text
          variant='text.h5'
          mr={3}
          textTransform='capitalize'
        >
          {title}
        </Text>

        <Svg
          as={ArrowDown}
          transform={active ? 'rotate(180deg)' : 'rotate(0)'}
          sx={{
            transition: 'transform .4s'
          }}
        />
      </Flex>

      <Box
        position='absolute'
        minWidth='175px'
        mt={3}
        left={0}
        sx={{
          transition: 'clip-path .4s',
          clipPath: active ? 'inset(-40px -40px -40px -40px)' : 'inset(0 0 100% 0)'
        }}
      >
        <Box
          bg='white'
          px={7}
          py={8}
          sx={{
            borderRadius: '2px',
            boxShadow: '0 5px 20px 0 rgba(0,0,0,0.08)',
          }}
        >
          {children}
        </Box>
      </Box>
    </Box>
  );
};

Dropdown.propTypes = {

};

export default Dropdown;
