import React from 'react';
import { CgArrowRight } from '@react-icons/all-files/cg/CgArrowRight';
import { TiArrowRightOutline } from '@react-icons/all-files/ti/TiArrowRightOutline';

import {
  Box,
  Button,
  Container,
  Flex,
  Grid,
  Heading,
  Image,
  Link,
  Text,
  Video,
} from '@ripperoni/components';

import { PeruVideo } from '@components/PeruVideo';

import { Seo } from 'src/@packdigital/gatsby-theme-ripperoni-marketing/components/Seo/Seo.jsx';
import IGSliderNew from 'src/components/IGSliderNew/IGSliderNew.jsx';

import { ADEDPantVideo } from '../components/ADEDPantVideo';
import { FlexShortVideo } from '../components/FlexShortVideo';

const Retailer = () => {
  return (
    <Container.Full bg='#141414' px={8} py={10}>
      <Seo pageTitle='Work With Us' logo={'/logo.png'} />
      <Box>
        <Heading
          color='white'
          height={['130px', null, '180px', null, null]}
          variant='heading.h1'
          maxWidth={'1100px'}
          mx='auto'
          fontSize={['21px', null, '60px', null, null]}
          // mt={[11, null, 13, null, null]}
          mb={10}
          mt={2}
          my={15}
          pt={[20, null, 30, null, null]}
        >
          Partner With Us
          <Grid
            columns={['2fr 1fr']}
            maxWidth={'1100px'}
            mx='auto'
            rows={['repeat(2,1)']}
            columnGap={2}
            alignItems='block'
          >
            <Text
              color='white'
              fontSize={['16px', null, '18px', null, null]}
              textAlign='left'
              lineHeight={['1.25', null, '1.3', null, null]}
              maxWidth={['90%', null, '70%', null, null]}
              display='block'
              mb={[10, null, 12, null, null]}
              my={10}
            >
              Public Rec is a digitally-native apparel brand on a mission to
              make comfort look good.
            </Text>
            <Box mx='auto' mb={[10, null, 12, null, null]} my={10}>
              <Text
                color='white'
                fontSize={['14px', null, '16px', null, null]}
                lineHeight={['1.25', null, '1.3', null, null]}
              >
                <Button
                  height={[null, null, '40px', null]}
                  px={5}
                  py={2}
                  variant='buttons.secondary'
                  sx={{
                    fontWeight: 'semi',
                  }}
                >
                  <Link href=' https://publicrec.kustomer.help/contact/wholesale-rkD8ZwBWc'>
                    <Grid columns={['90% 1fr']} maxWidth='280px'>
                      <Text>Become a Retailer &nbsp; &nbsp;</Text>
                      <Box
                        position='relative'
                        top='4px'
                        maxWidth='20px'
                        marginRight='5px'
                      >
                        <CgArrowRight />
                      </Box>
                    </Grid>
                  </Link>
                </Button>
              </Text>
            </Box>
          </Grid>
        </Heading>

        <Box mt={15} pt={10} mx='auto' maxWidth={'1100px'}>
          <Text
            color='white'
            variant='heading.h1'
            mx='auto'
            fontSize={['21px', null, '40px', null, null]}
            fontWeight={'500'}
          >
            Video
          </Text>
          <hr></hr>

          <Grid
            columns={['2fr 1fr']}
            maxWidth={'1100px'}
            rows={['repeat(2,1)']}
            columnGap={10}
            alignItems='block'
          >
            <Text
              color='white'
              fontSize={['16px', null, '25px', null, null]}
              textAlign='left'
              lineHeight={['1.25', null, '1.3', null, null]}
              maxWidth={['90%', null, '100%', null, null]}
              display='block'
              my={5}
            >
              Flex Short - Nationwide TV Commercial
            </Text>
            <Box
              position='relative'
              top='4px'
              maxWidth='20px'
              marginRight='5px'
            >
              <CgArrowRight fill='white' />
            </Box>
          </Grid>
        </Box>

        {/* <Arrow
								icon="arrowRight"
								classes="inline"
								viewBox="0 0 36 17"
								width="36"
								height="17"
								stroke="white"
								fill="white"
							/> */}

        <FlexShortVideo />
        <Box mx='auto' maxWidth={'1100px'}>
          <Grid
            columns={['80% 20%']}
            maxWidth={'1100px'}
            rows={['repeat(2,1)']}
            columnGap={2}
            alignItems='block'
          >
            <Text
              color='white'
              fontSize={['16px', null, '25px', null, null]}
              textAlign='left'
              lineHeight={['1.25', null, '1.3', null, null]}
              maxWidth={['90%', null, '100%', null, null]}
              display='block'
              mb={[2, null, 2, null, null]}
              my={5}
            >
              All Day Every Day Pant - Nationwide TV Commercial
            </Text>
            <Text> arrow -- </Text>
          </Grid>
        </Box>
        <ADEDPantVideo />
        <Box mx='auto' maxWidth={'1100px'}>
          <Text
            color='white'
            variant='heading.h1'
            mx='auto'
            fontSize={['21px', null, '30px', null, null]}
          >
            Instagram
          </Text>
          <hr></hr>
          <IGSliderNew />
        </Box>
      </Box>
    </Container.Full>
  );
};

export default Retailer;
