/**
 * @prettier
 */
import React from 'react';

import {
  Button,
  CmsContent,
  Container,
  Flex,
  Link,
} from '@ripperoni/components';

export const HggFooterHer = ({
  sizingBoxes,
  title,
  details,
  link,
  color,
  ...props
}) => {
  return (
    <>
      <Container mb={40}>
        <Flex
          bg={'#3B3E41'}
          minHeight={'350px'}
          maxWidth='1400px'
          px='15px'
          py='20px'
          margin='auto'
          alignItems='center'
          verticalAlign='center'
          flexDirection='column'
          sx={{
            alignContent: 'center',
            justifyContent: 'center',
          }}
        >
          <CmsContent
            color='white'
            lineHeight={['1.25', null]}
            fontSize={['36px', null, null, '40px']}
            content={details}
          />

          <Button
            mt='25px'
            color='white'
            fontSize='16px'
            letterSpacing='0.1em'
            bg='transparent'
            sx={{
              width: '190px',
              border: '1px solid #fff',
              borderRadius: 30,

              '&:hover': {
                bg: 'transparent',

                '& a:link': {
                  color: '#3B3E41',
                },
              },
            }}
          >
            {link}
          </Button>
        </Flex>
      </Container>
    </>
  );
};

HggFooterHer.propTypes = {};

HggFooterHer.displayName = 'Hgg Footer Her';
