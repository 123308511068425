export const addressBook = {
  address: {
    name: {
      mb: 5,
    }
  },
  row: {
    mb: 8,
    py: 10,
    px: [7, null, 9, null, 11],
    border: t => t.account.borders.default,
    borderColor: t => t.account.colors.border,
  },
  form: {
    border: t => t.account.borders.default,
    borderColor: t => t.account.colors.border,
  },
};
