/**
 * @jsx jsx
 * @prettier
 */

/* eslint-disable max-lines */
import { Input, jsx } from 'theme-ui';
import { motion } from 'framer-motion';
import { useWindowHeight } from '@react-hook/window-size';

import { ContentfulContent } from '@ripperoni/cms';
import { useUIContext } from '@ripperoni/core';
import { Box, Button, Flex, Link, Svg } from '@ripperoni/components';

// import SearchIcon from '@assets/svgs/search-mobile.svg';

const AnimatedBox = motion.custom(Box);
const AnimatedFlex = motion.custom(Flex);

export const SecondaryNavigationMobile = ({
  headings,
  navGroups,
  promobarVisible,
  ...props
}) => {
  const windowHeight = useWindowHeight();
  const {
    showMobileNavigation,
    hideMobileNavigation,
    state: { mobileNavigation },
  } = useUIContext();

  const navigationOpen = !!mobileNavigation;
  const navigationHeight = windowHeight * 2 + 200;
  const activeLinks = navGroups[mobileNavigation]?.links;
  const headingWithRightArrowCss = {
    '> a:first-of-type': {
      '&::after': {
        content: `url("data:image/svg+xml; utf8, ${arrowRight}")`,
        display: 'inline-block',
        width: '6px',
        marginLeft: '7px',
      },
    },
  };

  return (
    <motion.div
      initial='closed'
      animate={navigationOpen ? 'open' : 'closed'}
      exit='closed'
      variants={navigationVariants}
      custom={navigationHeight}
      sx={{
        position: 'fixed',
        zIndex: 3,
        top: 0,
        left: 0,
        bottom: 0,
        width: 'calc(100vw - 20px)',
        height: '100%',
        overflow: 'scroll',
        paddingTop: '100px',
        paddingRight: 8,
        paddingLeft: 8,
        backgroundColor: 'background',
      }}
      {...props}
    >
      {/* <AnimatedFlex
        as='form'
        mb={9}
        borderBottom='default'
        borderColor='#d8d8d8'
        variants={navigationBlockVariants}
      >
        <Button variant='plain'>
          <Svg as={SearchIcon} height='25px' />
        </Button>

        <Input
          type='text'
          sx={{
            border: 0,
            fontSize: 2,
          }}
          placeholder='Search'
        />
      </AnimatedFlex> */}

      {headings.length > 1 && (
        <AnimatedFlex variants={navigationBlockVariants} mb={8}>
          {headings.map(({ text, id }, index) => (
            <Button
              key={id}
              width={1 / headings.length}
              outline='none'
              borderTopLeftRadius={index === 0 ? 'button' : 'none'}
              borderBottomLeftRadius={index === 0 ? 'button' : 'none'}
              borderTopRightRadius={
                index === headings.length - 1 ? 'button' : 'none'
              }
              borderBottomRightRadius={
                index === headings.length - 1 ? 'button' : 'none'
              }
              variant={mobileNavigation === text ? 'primary' : 'secondary'}
              sx={{
                '&:hover': {
                  bg: '#000',
                  outline: 'none',
                },
                '&:focus': {
                  bg: '#000',
                  outline: 'none',
                },
              }}
              onClick={() => showMobileNavigation(text)}
            >
              {text}
            </Button>
          ))}
        </AnimatedFlex>
      )}

      <AnimatedBox px={8} py={2} bg='gray.0' variants={navigationBlockVariants}>
        {activeLinks?.map((linkGroup, index) => (
          <ContentfulContent
            {...linkGroup}
            px={8}
            pt={6}
            pb={3}
            borderColor='border'
            borderTop={index === 0 ? 'none' : 'default'}
            _sx={headingWithRightArrowCss}
            key={index}
            onClick={hideMobileNavigation}
          />
        ))}
      </AnimatedBox>

      <AnimatedBox py={8} px={10} variants={navigationBlockVariants}>
        <Flex around>
          <Link
            to='/pages/about/'
            sx={{
              fontSize: 1,
              fontWeight: 'bold',
            }}
            onClick={() => {
              hideMobileNavigation();
            }}
          >
            About
          </Link>
          <Link
            to='/account/'
            sx={{
              fontSize: 1,
              fontWeight: 'bold',
            }}
            onClick={() => {
              hideMobileNavigation();
            }}
          >
            Account
          </Link>
          <Link
            href='https://publicrec.kustomer.help/'
            aria-label='FAQ'
            sx={{
              fontSize: 1,
              fontWeight: 'bold',
            }}
            onClick={() => {
              hideMobileNavigation();
            }}
          >
            Help
          </Link>
        </Flex>
      </AnimatedBox>
    </motion.div>
  );
};

SecondaryNavigationMobile.displayName = 'Mobile Secondary Navigation';

const navigationVariants = {
  open: (navigationHeight) => ({
    clipPath: `circle(${navigationHeight}px at 31.5px 35px)`,
    transition: {
      type: 'spring',
      stiffness: 20,
      restDelta: 2,
    },
  }),
  closed: {
    clipPath: 'circle(0px at 31.5px 35px)',
    transition: {
      delay: 0.1,
      type: 'spring',
      stiffness: 400,
      damping: 40,
    },
  },
};

const navigationBlockVariants = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  closed: {
    y: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
    },
  },
};

const arrowRight =
  "<svg viewBox='0 0 16 24' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><g stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'><g transform='translate(-1282.000000, -2112.000000)' fill='%23979797'><g transform='translate(0.000000, 1715.000000)'><g transform='translate(142.000000, 397.000000)'><g transform='translate(1136.000000, 4.000000)'><g transform='translate(12.000000, 8.000000) rotate(-90.000000) translate(-12.000000, -8.000000) '><path d='M0.289293954,3.25732979 C-0.0964313179,2.85124358 -0.0964313179,2.19027348 0.289293954,1.77986721 L1.68779532,0.304564656 C2.07352059,-0.101521552 2.70057142,-0.101521552 3.08629669,0.304564656 L11.3007493,9.83375052 L20.9137033,0.304564656 C21.2994286,-0.101521552 21.9264794,-0.101521552 22.3122047,0.304564656 L23.710706,1.78202724 C24.0964313,2.18811345 24.0964313,2.85124358 23.710706,3.25516976 L12.1854451,15.6954353 C11.7997198,16.1015216 11.172669,16.1015216 10.7869437,15.6954353 L0.289293954,3.25732979 Z'></path></g></g></g></g></g></g></svg>";
