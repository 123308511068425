/**
 * @prettier
 */
import React from 'react';

import { Box, Button, Grid, Link } from '@ripperoni/components';

export const WomenswearSection = ({ womensLeft, womensRight, ...props }) => {
  return (
    <Grid
      {...props}
      position='relative'
      gap={0}
      columns={['1fr', null, null, '60% 40%']}
      mb={[15, null, null, 63]}
      mt={[5, null, null, 5]}
    >
      <Box
        mb={[10, null, null, null]}
        px={[8, null, null, 2]}
        lineHeight='33px'
      >
        {womensLeft}
      </Box>
      <Box position='relative'>
        <Box
          position='absolute'
          bottom={['auto', null, null, '11']}
          right={['auto', null, null, '0']}
          fontSize={['20px', null, null, 1]}
          px={[8, null, null, 2]}
        >
          <Button
            variant='plain'
            sx={{
              color: '#313033',
            }}
            fontSize={['13.14px', null, null, '20px']}
            fontWeight='600'
            lineHeight='130%'
            borderBottom='1.02px solid #1A1A1A'
            paddingBottom='2px'
          >
            <a href='https://publicrec.conciergeteam.co/' className='button'>
              Sign Up For Texts
            </a>
          </Button>
        </Box>
      </Box>
    </Grid>
  );
};

WomenswearSection.propTypes = {};

WomenswearSection.displayName = 'Womenswear Section';
