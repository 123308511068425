import React from 'react';
import { Flex } from 'theme-ui';

import { Svg } from '@ripperoni/components';

import Nordstrom from '@assets/images/nordstrom.svg';
import NeimanMarcus from '@assets/images/neimanMarcus.svg';
import StitchFix from '@assets/images/stitchFix.svg';
import YogaOutlet from '@assets/images/EverydayYoga_Logo.svg';
import Scheels from '@assets/images/Scheels.svg';
import Verishop from '@assets/images/verishop.svg';

const logoList = [
  Nordstrom,
  NeimanMarcus,
  StitchFix,
  YogaOutlet,
  Scheels,
  Verishop,
];

const Logos = () => {
  return (
    <Flex
      mb={[8, null, 12, null, null]}
      mx='auto'
      px={['5px', null, '20px', null, null]}
      sx={{
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        flexWrap: 'wrap',
        rowGap: '.25rem',
        maxWidth: '1100px',
      }}
    >
      {logoList.map((logo) => {
        return (
          <Svg
            key={logo}
            px={[4]}
            py={[2]}
            sx={{
              flex: ['50%', null, '25%', null, null],
              maxWidth: ['135px', null, null, null, null],
              maxHeight: ['50px', null, 'none', null, null],
              minHeight: '32px',
              justifyContent: 'space-evenly',
            }}
            as={logo}
          ></Svg>
        );
      })}
    </Flex>
  );
};
export default Logos;
