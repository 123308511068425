/**
 * @prettier
 */
import React from 'react';

import { Box, Grid } from '@ripperoni/components';

export const MenswearTextSection = ({
  mensTextLeft,
  mensTextRight,
  ...props
}) => {
  return (
    <Grid
      {...props}
      position='relative'
      gap={[1, null, null, 10]}
      columns={['1fr', null, null, '1fr 1fr']}
      mb={[12, null, null, 10]}
    >
      <Box position='relative' my={[20, null, null, 25]}>
        <Box
          mt={[2, null, null, 46]}
          // bg={'#b4b63e'}
          px={[10, null, null, 2]}
          position='relative'
          bottom={['auto', null, null, -4]}
          left={['auto', null, null, 0]}
        >
          {mensTextLeft}
        </Box>
      </Box>
      <Box mt={[2, null, null, 62]} px={[10, null, null, 1]}>
        {mensTextRight}
      </Box>
    </Grid>
  );
};

MenswearTextSection.propTypes = {};

MenswearTextSection.displayName = 'Meenswear Text Section';
