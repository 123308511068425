/* eslint-disable max-lines */
const SCRIPTS_INJECTION_SEQUENTIAL = true;
const SCRIPTS_INJECTION_INTERVAL = 500; // inject a script every x ms

const APP_RENDER_THIRD_PARTIES = true;
const isBrowser = typeof window !== 'undefined';

const THIRD_PARTIES = [
  {
    name: 'Yotpo',
    active: true,
    id: 'yotpo-script',
    cycle: null,
    inline: null,
    src: 'https://staticw2.yotpo.com/JXn5eaLRNGLDdPLgOwIC1ca4Y9v2znghdWiOf8cW/widget.js',
    local: false,
  },
  {
    name: 'Sezzle',
    active: true,
    id: 'sezzle-script',
    cycle: null,
    inline: null,
    src: 'https://widget.sezzle.com/v1/javascript/price-widget?uuid=62239fc0-9479-4a3c-8b48-7e4b5d807629',
    local: false,
  },
  {
    name: 'Global-E',
    active: true,
    id: 'global-e-preloader',
    cycle: null,
    src: null,
    inline: () => {
      (function () {
        var s = document.createElement('script');
        s.type = 'text/javascript';
        s.async = true;
        s.src = '//gepi.global-e.com/includes/js/714';
        document.getElementsByTagName('head')[0].appendChild(s);
      })();
    },
  },
];

const activeThirdPartyScripts = () =>
  THIRD_PARTIES.filter((thirdP) => thirdP.active).map((aThirdP) =>
    injectOrRunScript(aThirdP)
  );

/**
 * Set a mutation observer on the <body/> data-load-3ps attribute value
 * which is set @ triggerThirdPartiesInjection on ../on-initial-client-render.js
 * When the value changes from true to false this function executes
 * all the callbacks that inject each third party script.
 * @param {[callbacks Array]} cbs
 */
const initThirdPartiesMutationObserver = (cbs, onReadyCallback) => {
  let observer = null;
  const observedTarget = document.body;
  const observerOptions = {
    attributes: true,
    attributeOldValue: true,
    attributeFilter: ['data-load-3ps'],
  };

  const stop = (obs, target) => {
    obs.disconnect(target);
  };

  const start = (obs, target, opts) => {
    obs.observe(target, opts);
  };

  const onMutation = (mutations) => {
    // loop trought all mutations
    for (let mutation of mutations) {
      if (mutation.type === 'attributes') {
        // data-load-3ps
        const isThirdPartyMutation =
          mutation.attributeName === 'data-load-3ps' &&
          mutation.oldValue === 'false';

        if (isThirdPartyMutation) {
          // run data-apps callbacks
          if (cbs) {
            cbs.forEach((cb, i) => {
              if (cb && typeof cb === 'function') {
                const cbTo = window.requestTimeout(
                  () => {
                    cb();
                    window.clearRequestTimeout(cbTo);
                  },
                  SCRIPTS_INJECTION_SEQUENTIAL
                    ? (i + 1) * SCRIPTS_INJECTION_INTERVAL
                    : 0
                );
              }

              // Injected all scripts, stop observing the data atribute on body.
              if (i === cbs.length - 1) {
                stop(observer);
                onReadyCallback && onReadyCallback();
              }
            });
          }
        }
      }
    }
  };

  // Instanciate an observer
  observer = new MutationObserver(onMutation);

  // Observe the body element
  start(observer, observedTarget, observerOptions);
};

/**
 * Creates and injects a script or simply runs some code needed by a script.
 * @param {THIRD_PARTY Object} config
 */
const injectOrRunScript = (config) => {
  const { id, cycle, src, inline } = config;

  // Inline code that needs to be executed
  // no need to inject a script tag.
  if (inline && typeof inline === 'function') {
    // run script
    return inline;
  } else {
    // create and inject a script tag
    return () => {
      const script = document.createElement('script');
      // script.type = "text/javascript";
      // script.type = "text/babel"
      script.id = id;
      if (cycle === 'defer') script.defer = 1;
      if (cycle === 'async') script.async = 1;
      if (src) script.src = src;
      document.body.appendChild(script);
    };
  }
};

export const loadThirdParties = (onReadyCallback) => {
  if (APP_RENDER_THIRD_PARTIES) {
    initThirdPartiesMutationObserver(
      activeThirdPartyScripts(),
      onReadyCallback
    );
  }
};

/**
 * We set this to false to delay the execution until
 * '../on-initial-client-render/triggerThirdPartiesInjection'
 * triggers it on react ready.
 */
export const setThirdPartiesAllowedInitialState = () => {
  // if set to true here, apps would be injected without waiting for page to be ready.
  document.body.setAttribute('data-load-3ps', false);
};
