/** @jsx jsx */
import { jsx } from 'theme-ui';
import PropTypes from 'prop-types';
import ReactCompareImage from 'react-compare-image';

import { Container, CmsContent, Flex, Svg, Text } from '@ripperoni/components';

import ArrowRight from '@assets/svgs/arrow-right.svg';


export const ImageCompare = ({
  caption,
  leftImage,
  rightImage
}) => {
  return (
    <Container>
      {
        (leftImage && rightImage) ? (
          <ReactCompareImage
            aspectRatio='wider'
            sliderLineWidth={6}
            handle={<Handle />}
            leftImage={leftImage[0]?.props?.primaryImage?.fluid?.src}
            rightImage={rightImage[0]?.props?.primaryImage?.fluid?.src}
          />
        ) : null
      }

      {
        caption &&
        <CmsContent
          content={caption}
          mt={7}
          color='gray.2'
        />
      }
    </Container>
  );
};

const Handle = () => {
  return (
    <Flex
      between
      sx={{
        width: '45px',
        height: '23px',
        bg: 'white',
        borderRadius: '25px',
        position: 'absolute',
        bottom: 7,
        left: '50%',
        transform: 'translateX(-50%)',
        boxShadow: '0 2px 40px 0 rgba(0,0,0,0.08)',
        px: '5px',
        py: '1px'
      }}
    >
      <Svg
        as={ArrowRight}
        width='9px'
        sx={{
          transform: 'rotate(180deg)',
          '.fill': {
            fill: 'primary',
          }
        }}
      />

      <Svg
        as={ArrowRight}
        width='9px'
        sx={{
          '.fill': {
            fill: 'primary',
          }
        }}
      />
    </Flex>
  );
};

ImageCompare.propTypes = {

};

export default ImageCompare;
