/* eslint-disable import/no-default-export */
import React from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import { Box, Container, Flex, Grid, Heading } from '@ripperoni/components';
import { Seo } from '@ripperoni/marketing';

import ContentfulRichTech from 'src/components/contentful-rich-text';

const Blog = ({ data: { atomBlog }, ...props }) => {
  return (
    <>
      <Seo
        pageTitle={atomBlog.metaTitle}
        description={atomBlog.metaDescription.metaDescription}
      />
      <Container my={[20, null, null, 60]} px={[10, null, null, 15]}>
        <Box maxWidth={[null, null, null, '900px', null]} mx='auto'>
          <GatsbyImage image={getImage(atomBlog.banner)} />
          <Heading as='h1' fontSize={[6, null, null, 14, null]} my={8}>
            {atomBlog.metaTitle}
          </Heading>
          <ContentfulRichTech richBody={atomBlog.richBody} />
        </Box>
      </Container>
    </>
  );
};

Blog.propTypes = {};

export default Blog;

export const query = graphql`
  query ($handle: String!) {
    atomBlog: contentfulBlogPost(slug: { eq: $handle }) {
      banner {
        gatsbyImageData
      }
      richBody {
        raw
        references {
          ... on ContentfulAsset {
            contentful_id
            title
            description
            gatsbyImageData(width: 1000)
            __typename
          }
        }
      }
      metaTitle
      metaDescription {
        metaDescription
      }
      banner {
        fluid {
          srcWebp
        }
      }
    }
  }
`;
