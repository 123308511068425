import React from 'react';
import PropTypes from 'prop-types';

import { Box, CmsContent, Container, Flex, Heading } from '@ripperoni/components';


export const ProductFeatures = ({
  heading,
  image,
  accordion,
  ...props
}) => {
  return (
    <Container.Full
      data-comp={ProductFeatures.displayName}
      bg='gray.0'
      px={8}
      {...props}
    >
      <Container
        py={[11, null, null, null, 15]}
      >
        <Flex
          flexDirection={['column-reverse', null, null, 'row']}
          middle
          between
        >
          <Box
            flexBasis='50%'
            width='100%'
            maxWidth='400px'
            mr={[0, null, null, 8]}
          >
            <CmsContent
              as={Heading}
              textAlign='left'
              fontSize={[8, null, null, 9]}
              mb={[7, null, null, 10]}
              content={heading}
            />

            <Box>
              {
                accordion.map( (accordion, index) => {
                  return (
                    <CmsContent
                      key={index}
                      content={accordion}
                    />
                  );
                })
              }
            </Box>
          </Box>

          <Box
            flexBasis='50%'
            width='100%'
            maxWidth='600px'
          >
            <CmsContent content={image} />
          </Box>
        </Flex>
      </Container>
    </Container.Full>
  );
};

ProductFeatures.displayName = 'ProductFeatures';

ProductFeatures.propTypes = {

};

export default ProductFeatures;
