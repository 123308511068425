import React from 'react';
import PropTypes from 'prop-types';

import { Box, Flex, Text } from '@ripperoni/components';

import {
  useProductVariant,
} from '@components/ProductVariantContext/ProductVariantContext';
import { colorMapping } from '@components/ProductOptions/inputStyle';


const ProductTileSwatches = ({ visible }) => {
  const { productVariantState, setVariantOption } = useProductVariant();

  const {
    product,
    selectedOptionsMap,
  } = productVariantState;

  const {
    optionValues
  } = product || {};


  if (!optionValues || !optionValues.Color) return null;

  return (
    <Box
      height='22px'
      flexShrink={0}
      sx={{
        cursor: 'pointer',
      }}
    >
        <Flex
          middle
          mt={1}
          pl={1}
        >
        {
          optionValues.Color.map((color, i) => {
            console.log('GRAB SPECIFIC', colorMapping[color]);
            if (colorMapping[color]?.includes('url')) {
              return (
                // if color is a svg file do this
                <Box
                  onClick={() => {
                    setVariantOption('Color', color);
                  }}
                  key={i}
                  width='14px'
                  height='14px'
                  
                  mr={4}
                  position='relative'
                  css={{
                    background: colorMapping[color]
                  }}
                    sx={{
                    borderRadius: '50%',
                    display: [
                      'block',
                      null,
                      null,
                      null,
                      visible ? 'block' : 'none',
                    ],
                    '::before': {
                      content: '""',
                      p: '3px',
                      pointerEvents: ' none',
                      borderRadius: '50%',
                      position: 'absolute',
                      width: '100%',
                      height: '100%',
                      border: 'thin solid',
                      borderColor:
                        selectedOptionsMap?.Color === color
                          ? 'black'
                          : 'transparent',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                      transition: 'border-color .2s ease-in',
                    },
                    '&:hover:before': {
                      borderColor: 'gray.2',
                    },
                  }}
                />
                
              )
            }
              return (
                <Box
                  onClick={() => {
                    setVariantOption('Color', color);
                  }}
                  key={i}
                  width='14px'
                  height='14px'
                  bg={colorMapping[color] || 'gray.2'}
                  mr={4}
                  position='relative'
                  sx={{
                    borderRadius: '50%',
                    display: [
                      'block',
                      null,
                      null,
                      null,
                      visible ? 'block' : 'none',
                    ],
                    '::before': {
                      content: '""',
                      p: '3px',
                      pointerEvents: ' none',
                      borderRadius: '50%',
                      position: 'absolute',
                      width: '100%',
                      height: '100%',
                      border: 'thin solid',
                      borderColor:
                        selectedOptionsMap?.Color === color
                          ? 'black'
                          : 'transparent',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                      transition: 'border-color .2s ease-in',
                    },
                    '&:hover:before': {
                      borderColor: 'gray.2',
                    },
                  }}
                />
              );
            })
          }
        </Flex>
        <Text
          italic
          fontWeight='light'
          color='gray.3'
          fontSize={3}
          sx={{
            cursor: 'pointer',
            display: ['none', null, null, null, visible ? 'none' : 'block'],
          }}
        >
          {optionValues.Color.length} colors available
        </Text>
    </Box>
  );
};

ProductTileSwatches.propTypes = {

};

export default ProductTileSwatches;

