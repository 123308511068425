/* eslint-disable max-lines */
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Box, Container, Flex, Svg, Text } from '@ripperoni/components';

import { useCollectionFilter } from '@components/CollectionContext/CollectionContext.jsx';
import { useCollectionProducts } from '@components/CollectionContext';

import ArrowDown from '@assets/svgs/arrow-down.svg';

import { Breadcrumb } from './Breadcrumb';

export const CollectionFilterMobile = ({ breadcrumbs }) => {
  const [filterActive, setFilterActive] = useState(false);
  const { filters, activeFilters, setActiveFilter, clearAllFilters } =
    useCollectionFilter();
  const { productCount } = useCollectionProducts();
  return (
    <Container.Full data-comp='CollectionFilterMobile'>
      <Flex middle between={true} pt={6} px={8} mx='auto'>
        <Flex middle>
          <Text variant='text.h5'>
            <Breadcrumb breadcrumbs={breadcrumbs} />
          </Text>
        </Flex>
      </Flex>

      <Flex middle between={true} pt={3} mb={5} px={8} mx='auto'>
        <Flex between={true} middle>
          <Text
            color='gray.2'
            variant='text.h5'
            fontWeight='light'
            fontSize={4}
            display={['block', null, null, null, 'none']}
          >
            {productCount} Products
          </Text>
        </Flex>
        <Flex
          onClick={() => setFilterActive(!filterActive)}
          middle
          px={8}
          py={3}
          mt={1}
          sx={{
            border: '1px solid',
            borderColor: 'gray.2',
            borderRadius: '20px',
            cursor: 'pointer',
          }}
        >
          <Text variant='text.h5' fontSize={4} mr={4}>
            Filter{' '}
            {Object.keys(activeFilters).length
              ? `(${Object.keys(activeFilters).length})`
              : ''}
          </Text>

          <Svg
            as={ArrowDown}
            transform={filterActive ? 'rotate(180deg)' : 'rotate(0)'}
            sx={{
              transition: 'transform .4s',
            }}
          />
        </Flex>
      </Flex>
      <Box
        borderTop='thin solid'
        borderColor='gray.2'
        sx={{
          transition: 'all .4s',
          p: filterActive ? 8 : 0,
          maxHeight: filterActive ? 'fit-content' : 0,
          overflow: filterActive ? 'visible' : 'hidden',
          opacity: filterActive ? 1 : 0,
        }}
      >
        {filters.map(({ title, options }, index) => {
          return (
            <Box key={index} sx={{ mb: '25px' }}>
              <Text variant='text.h6' mb={4} textTransform='capitalize'>
                {title}
              </Text>

              {options.map((value, index) => {
                const active = activeFilters[title] === value;

                return (
                  <Flex
                    key={index}
                    between={true}
                    middle
                    mt={index !== 0 ? 4 : 0}
                    sx={{
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      setActiveFilter(title, value);
                      setFilterActive(false);
                    }}
                  >
                    <Text
                      color={active ? 'black' : 'gray.2'}
                      textTransform='capitalize'
                    >
                      {value}
                    </Text>

                    <Box
                      width='12px'
                      height='12px'
                      border='thin solid'
                      borderColor={active ? 'black' : 'gray.2'}
                      position='relative'
                      bg={active ? 'black' : 'white'}
                      sx={{
                        borderRadius: '50%',
                        '::before': {
                          content: '""',
                          borderRadius: '50%',
                          display: 'block',
                          position: 'absolute',
                          width: '6px',
                          height: '6px',
                          bg: 'white',
                          top: '50%',
                          left: '50%',
                          transform: 'translate(-50%, -50%)',
                        },
                      }}
                    />
                  </Flex>
                );
              })}
            </Box>
          );
        })}

        {Object.keys(activeFilters).length > 0 && (
          <Flex
            as={Text}
            middle
            center
            onClick={() => {
              clearAllFilters();
              setFilterActive(!filterActive);
            }}
            textAlign='center'
            mt={3}
            color='error'
            sx={{
              cursor: 'pointer',
            }}
          >
            Clear All
            <Text fontSize={8} lineHeight={0} display='inline' ml={1}>
              &times;
            </Text>
          </Flex>
        )}
      </Box>
    </Container.Full>
  );
};

CollectionFilterMobile.propTypes = {};
