export const forms = {
  label: {
    mb: 1,
    fontSize: 1,
    fontWeight: 'semi',
    width: 'auto',
    '&.checkbox': {
      fontWeight: 'light',
    },
  },
  input: {
    p: 7,
    fontSize: 3,
    color: 'text',
    bg: 'background',
    lineHeight: 'input',
    borderRadius: 'input',
    borderColor: 'gray.2',
    fontWeight: 'light',
    '&:hover, &:active, &:focus': {
      outline: 'none',
      borderColor: 'primary',
    },
    '&:disabled': {
      bg: 'gray.1',
      borderColor: 'gray.1',
    },
    '.error': {
      borderColor: 'error',
    },
  },
  select: {
    variant: 'forms.input',
  },
  textarea: {
    variant: 'forms.input',
  },
  checkbox: {
    color: 'gray.2',
    borderWidth: 'thin',
    'input:hover ~ &, input:active ~ &, input:focus ~ &, input:checked ~ &': {
      color: 'white',
      backgroundColor: 'transparent',
    },
    'input:disabled ~ &': {
      color: 'gray.1',
      bg: 'gray.1',
    },
  },
  radio: {
    variant: 'forms.checkbox',
  },
  fieldGroup: {
    mb: 8,
  },
};
