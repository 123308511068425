import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import { Box, CmsContent } from '@ripperoni/components';

export const NavigationFeatureImage = ({
  image,
  cta
}) => {
  return (
    <Box
      display={['none', null, null, null, 'block']}
      width='100%'
      maxWidth='425px'
      ml={8}
      sx={{
        borderRadius: '3px',
      }}
    >
      <Link to={cta ? cta[0]?.props?.url : ''}>
        <CmsContent display='block' width='100%' content={image} />
      </Link>
    </Box>
  )
}

NavigationFeatureImage.propTypes = {

}
