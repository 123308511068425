import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Input, Textarea } from 'theme-ui';
import { btoa } from 'abab';

import { useCart, useUpdateCartAttributes } from '@ripperoni/cart';
import { Box, Flex, Heading, Link, Price, Text } from '@ripperoni/components';

export const CartTotals = ({ subtotal, checkoutUrl, ...props }) => {
  const [giftMessageRows, setGiftMessageRows] = useState(1);
  const { customAttributes: attributes } = useCart();
  const message = attributes.find(({ key }) => key === 'giftMessage');
  const [giftMessage, setGiftMessage] = useState(message?.value || '');
  const updateGiftMessage = useUpdateCartAttributes();

  const discountCodeFromAttributes = attributes.find(
    ({ key }) => key === 'discount_code'
  );
  const [discountCode] = useState(discountCodeFromAttributes?.value || '');
  const [refersionId] = useState(localStorage.getItem('rfsn_v4_id') || '');
  const [refersionAid] = useState(localStorage.getItem('rfsn_v4_aid') || '');
  const [refersionCs] = useState(localStorage.getItem('rfsn_v4_cs') || '');
  const [textAreaCount, setTextAreaCount] = React.useState(0);

  const recalculate = (e) => {
    console.log('event value:', e);
    setTextAreaCount(e.target.value.length);
  };

  const appendedDiscountUrl =
    discountCode == ''
      ? checkoutUrl
      : `${checkoutUrl}&discount=${discountCode}`;

  const appenedRefersionUrl =
    refersionId == ''
      ? appendedDiscountUrl
      : `${appendedDiscountUrl}&rfsn_v4_id=${refersionId}&rfsn_v4_aid=${refersionAid}&rfsn_v4_cs=${refersionCs}`;

  // if (GEM_Components && GEM_Components.ExternalMethodsComponent) {
  //   GEM_Components.ExternalMethodsComponent.IsOperatedByGlobalE(function (
  //     isOperated
  //   ) {
  //     if (isOperated) {
  //       const encodedRefersionUrl = btoa(appenedRefersionUrl);
  //       appenedRefersionUrl = encodedRefersionUrl;
  //     }
  //   });
  // }
  useEffect(() => {
    updateGiftMessage([{ key: 'giftMessage', value: giftMessage }]);
  }, [giftMessage]);

  return (
    <Box data-comp={CartTotals.displayName} p='22px' {...props}>
      <Flex between mb={8} sx={{ alignItems: 'center' }}>
        <Heading size='18px'>Subtotal</Heading>

        <Price size='18px'>{subtotal}</Price>
      </Flex>

      <Link.Button href={appenedRefersionUrl}>Checkout</Link.Button>
      <Box mt={10}>
        <Heading mb={5} size='15px'>
          Is this a gift? Leave a message.
        </Heading>

        <Textarea
          maxLength='500'
          rows={giftMessageRows}
          onBlur={() => setGiftMessageRows(1)}
          onFocus={() => setGiftMessageRows(2)}
          onChange={(event) => setGiftMessage(event.target.value)}
          placeholder='Your message'
          value={giftMessage}
          sx={{
            fontFamily: 'Messina Sans',
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
            border: '1px solid #C9C9C9',
          }}
        />
      </Box>
    </Box>
  );
};

CartTotals.displayName = 'Cart Totals';

CartTotals.propTypes = {
  subtotal: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  checkoutUrl: PropTypes.string.isRequired,
};
