import { graphql, useStaticQuery } from 'gatsby';

export default function seoTitle() {
  const data = useStaticQuery(
    graphql`
      query {
        googleSheet {
          SeoTitle {
            title
            newTitle
          }
        }
      }
    `
  );

  const seoTitleReplacements = data.googleSheet.SeoTitle.map((node) => {
    const { title, newTitle } = node;
    return {
      title,
      newTitle,
    };
  });
  return {
    seoTitleReplacements,
  };
}
