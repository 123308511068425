import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Swiper, SwiperSlide } from 'swiper/react';

import { Box, CmsContent, Container, Link, Text } from '@ripperoni/components';

import { PrevArrow } from './PrevArrow';
import { NextArrow } from './NextArrow';

export const PairGallery = ({ images, link, ...props }) => {
  const [activeIndex, setSlideIndex] = useState(0);
  const sliderRef = useRef(null);
  const sliderParams = {
    loop: true,
    slidesPerView: 1.25,
    spaceBetween: 20,
    centerSlides: true,
    observeSlideChildren: true,
    breakpoints: {
      768: {
        slidesPerView: 2,
      },
    },
  };

  useEffect(() => {
    if (sliderRef.current !== null && sliderRef.current.swiper !== null) {
      sliderRef.current.swiper.on('slideChange', (swiper) => {
        setSlideIndex(swiper.realIndex);
      });
    }
  }, [sliderRef]);

  const goNext = useCallback(() => {
    if (sliderRef.current !== null && sliderRef.current.swiper !== null) {
      sliderRef.current.swiper.slideNext();
    }
  });

  const goPrev = useCallback(() => {
    if (sliderRef.current !== null && sliderRef.current.swiper !== null) {
      sliderRef.current.swiper.slidePrev();
    }
  });

  return (
    <Container.Full
      data-comp={PairGallery.displayName}
      bg='white'
      px={8}
      {...props}
    >
      <Container py={[11, null, null, null, 15]}>
        <Box
          position='relative'
          sx={{
            img: {
              opacity: '1 !important;',
            },
          }}
        >
          <Swiper {...sliderParams} ref={sliderRef}>
            {images?.map((image, index) => {
              return (
                <SwiperSlide key={index}>
                  <CmsContent content={image} width='100%' />
                </SwiperSlide>
              );
            })}
          </Swiper>

          {/* {
            activeIndex !== 0 &&
            <PrevArrow onClick={goPrev} />
          }

          {
            activeIndex !== images?.length - 2 &&
            <NextArrow onClick={goNext} />
          } */}
          <PrevArrow onClick={goPrev} />

          <NextArrow onClick={goNext} />
        </Box>

        {link && (
          <Text mt={[5, null, null, 8]}>
            Paired With&nbsp;
            <CmsContent as={Link} content={link} color='black' />
          </Text>
        )}
      </Container>
    </Container.Full>
  );
};

PairGallery.displayName = 'PairGallery';

PairGallery.propTypes = {};

export default PairGallery;
