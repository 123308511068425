import React from 'react';
import PropTypes from 'prop-types';

import { Box, Container, Flex, Text } from '@ripperoni/components';

import CollectionFilter from '@components/CollectionFilter';
import { CollectionSection } from '@components/CollectionSection';
import { useCollectionProducts } from '@components/CollectionContext';

export const Collection = ({ categoryImages = [], breadcrumbs }) => {
  const { products, productCount, setImageType } = useCollectionProducts();

  return (
    <Container.Full>
      <Box mt={[null, null, null, 4]} mb={[null, null, null, 4]}>
        <CollectionFilter breadcrumbs={breadcrumbs} />
      </Box>

      <Container p={8} pr={[0, null, null, null, 8]}>
        {Object.keys(products).length > 0 ? (
          Object.keys(products).map((productCategory) => {
            return (
              <CollectionSection
                key={`${productCategory} Section`}
                categoryImages={categoryImages}
                productCategory={productCategory}
                categoryProducts={products[productCategory]}
              />
            );
          })
        ) : (
          <Flex top center minHeight='500px'>
            <Text textAlign='center' fontWeight='semi'>
              No Products
            </Text>
          </Flex>
        )}
      </Container>
    </Container.Full>
  );
};

Collection.propTypes = {};

// eslint-disable-next-line
export default Collection;
