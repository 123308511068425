/**
 * @prettier
 */
import React from 'react';
import { Container } from 'theme-ui';

import { Box, CmsContent, Flex, Grid } from '@ripperoni/components';

export const BlogEnd = ({
  endImageLeft,
  endImageRight,
  lastTextSection,
  ...props
}) => {
  return (
    <Container>
      <Flex>
        <Grid
          {...props}
          position='relative'
          gap={0}
          columns={['1fr', null, null, '1fr 1fr']}
          sx={{ '& *': { height: '100%' } }}
        >
          <Box>{endImageLeft}</Box>
          <Box>{endImageRight}</Box>
        </Grid>
        <CmsContent content={lastTextSection} />
      </Flex>
    </Container>
  );
};

BlogEnd.propTypes = {};

BlogEnd.displayName = 'Blog End';
