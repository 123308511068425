export const order = {
  content: {
    py: [10, null, null, null, 13],
    px: [7, null, 9, null, 11],
    border: t => t.account.borders.default,
    borderColor: t => t.account.colors.border,
  },
  addresses: {
    px: [7, null, 10, null, 14],
    pb: 13,
    shipping: {
      header: {
        mb: 6,
      },
    },
    fulfillment: {
      header: {
        mb: 6,
      },
      trackOrder: {
        variant: 'links.text'
      },
    },
  },
  lineItems: {
    mx: [6, null, 8, null, 10],
  },
  lineItem: {
    py: 10,
    px: [5, null, 7, null, 10],
    '&:last-of-type': {
      border: 0,
    },
    badge: {
      color: 'secondary',
    },
    meta: {
      mx: 10,
      flexDirection: 'column-reverse',
    },
  },
  totals: {
    mt: 0,
    pt: 10,
    px: [7, null, 9, null, 11],
    borderTop: t => t.account.borders.default,
    borderColor: t => t.account.colors.border,
  },
};
