/**
 * @prettier
 */
import React from 'react';

import {
  Box,
  CmsContent,
  Container,
  Flex,
  Grid,
  Text,
} from '@ripperoni/components';

export const HolidayGiftGuide = ({
  title,
  icon,
  image,
  details,
  caption,
  link,
  ...props
}) => {
  return (
    <>
      <Container.Full px={[0, null, null, 8]}></Container.Full>
    </>
  );
};

HolidayGiftGuide.propTypes = {};

HolidayGiftGuide.displayName = 'Holiday Gift Guide';
