/* eslint-disable max-lines */
import React, { memo, useEffect, useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { AnimatePresence, motion } from 'framer-motion';

import { ContentfulContent } from '@ripperoni/cms';
import { Box, Container, Flex, Grid, Svg, Text } from '@ripperoni/components';

import { ProductVariant } from '@components/ProductVariantContext/ProductVariantContext.jsx';

import ArrowDown from '@assets/svgs/arrow-down.svg';

import { CompareTile } from 'src/components/CompareTile';

const ComparePants = () => {
  const [filterActive, setFilterActive] = useState(false);
  const [products, setProducts] = useState({});
  const [activeProducts, setActiveProducts] = useState([]);
  const { compareData, pageContainer } = useStaticQuery(staticQuery);

  useEffect(() => {
    const comparisonProducts = compareData.comparisonTiles.reduce(
      (acc, curr) => {
        acc[curr.productTitle] = {
          ...curr,
          foreignId: curr.productsVariantId[0].content,
        };

        return acc;
      },
      {}
    );

    setProducts(comparisonProducts);

    const initialActive = Object.values(comparisonProducts)
      // .slice(0, 3)
      .map((product) => product.productTitle);

    setActiveProducts(initialActive);
  }, []);

  const toggleProducts = (title) => {
    const newActiveProducts = [...activeProducts];

    if (newActiveProducts.includes(title)) {
      const index = newActiveProducts.indexOf(title);
      if (index > -1) {
        newActiveProducts.splice(index, 1);
      }
    } else {
      newActiveProducts.push(title);
    }

    setActiveProducts(newActiveProducts);
  };

  return (
    <Container.Full>
      <ContentfulContent {...pageContainer} />

      <Container px={8} py={[11, null, null, null, 15]}>
        <Flex middle between mb={[8, null, null, 10]}>
          <Text variant='text.h3' display={['none', null, null, 'block']}>
            Compare Our Pants
          </Text>

          <Box
            width='100%'
            maxWidth={[null, null, null, '369px']}
            position='relative'
            zIndex={1}
          >
            <Flex
              middle
              between
              onClick={() => setFilterActive(!filterActive)}
              border='thin solid'
              borderColor='gray.2'
              py={7}
              px={8}
              sx={{
                borderRadius: 3,
                cursor: 'pointer',
              }}
            >
              <Text>Select Styles To Compare ({activeProducts.length})</Text>

              <Svg
                as={ArrowDown}
                transform={filterActive ? 'rotate(180deg)' : 'rotate(0)'}
                sx={{
                  transition: 'transform .4s',
                }}
              />
            </Flex>

            <Box
              position='absolute'
              top='100%'
              width='100%'
              boxShadow='0 5px 20px 0 rgba(0,0,0,0.08)'
              bg='white'
              mt={1}
            >
              <AnimatePresence>
                {filterActive && (
                  <motion.main
                    style={{ overflow: 'hidden' }}
                    initial={{ height: 0 }}
                    animate={{ height: 'auto' }}
                    exit={{ height: 0 }}
                    transition={{ duration: 0.15 }}
                  >
                    <Box
                      sx={{
                        border: 'thin solid',
                        borderColor: 'gray.2',
                        borderRadius: 3,
                      }}
                    >
                      {Object.values(products).map((product) => {
                        const isActiveProduct = activeProducts.some(
                          (activeProduct) => {
                            return activeProduct === product.productTitle;
                          }
                        );

                        return (
                          <Flex
                            middle
                            between
                            key={Math.random()}
                            px={8}
                            py={5}
                            onClick={() => toggleProducts(product.productTitle)}
                            sx={{
                              cursor: 'pointer',
                            }}
                          >
                            <Text>{product.productTitle}</Text>

                            <Text>
                              {
                                <Box
                                  width='12px'
                                  height='12px'
                                  border='thin solid'
                                  borderColor={
                                    isActiveProduct ? 'black' : 'gray.2'
                                  }
                                  position='relative'
                                  bg={isActiveProduct ? 'black' : 'white'}
                                  sx={{
                                    borderRadius: '50%',
                                    '::before': {
                                      content: '""',
                                      borderRadius: '50%',
                                      display: 'block',
                                      position: 'absolute',
                                      width: '6px',
                                      height: '6px',
                                      bg: 'white',
                                      top: '50%',
                                      left: '50%',
                                      transform: 'translate(-50%, -50%)',
                                    },
                                  }}
                                />
                              }
                            </Text>
                          </Flex>
                        );
                      })}
                    </Box>
                  </motion.main>
                )}
              </AnimatePresence>
            </Box>
          </Box>
        </Flex>

        <Grid
          mt={7}
          columns={['1fr', null, null, '1fr 1fr', '1fr 1fr 1fr']}
          columnGap={9}
          justifyContent='center'
          rowGap={9}
          mx='auto'
        >
          <CompareProducts
            products={products}
            activeProducts={activeProducts}
          />
        </Grid>
      </Container>
    </Container.Full>
  );
};

const CompareProducts = memo(({ products, activeProducts }) => {
  return activeProducts.map((activeProduct) => {
    const compareProduct = products[activeProduct];

    return (
      <ProductVariant
        key={compareProduct.foreignId + Math.random()}
        variantId={compareProduct.foreignId}
      >
        <CompareTile compareProduct={compareProduct} />
      </ProductVariant>
    );
  });
});

CompareProducts.displayName = 'CompareProducts';

export default ComparePants;

const staticQuery = graphql`
  {
    pageContainer: contentfulPageContainer(slug: { eq: "/compare-pants" }) {
      ...ContentfulPageContainer
    }
    compareData: contentfulProductCompare(metaHandle: { eq: "compare-pants" }) {
      comparisonTiles {
        ... on ContentfulCompareTile {
          productImage {
            ...ContentfulAtomImage
          }
          productTitle
          description
          productsVariantId {
            content
          }
          activity
          fit
          weight
          fabric
          stretch
          waist
          fly
        }
      }
    }
  }
`;
