import React from 'react';
import { Helmet } from 'react-helmet';

import { Box, Container, FlexCol, Text } from '@ripperoni/components';
import { isBrowser } from '@ripperoni/utilities';
import { Seo } from '@ripperoni/marketing';

const Reviews = () => {
  if (!isBrowser) return null;

  return (
    <Container.Full>
      <Seo />
      <Helmet>
        <script
          type='text/javascript'
          src='https://staticw2.yotpo.com/JXn5eaLRNGLDdPLgOwIC1ca4Y9v2znghdWiOf8cW/widget.js'
          id='https://staticw2.yotpo.com/JXn5eaLRNGLDdPLgOwIC1ca4Y9v2znghdWiOf8cW/widget.js'
        />
      </Helmet>
      <FlexCol alignItems='center' sx={{ minHeight: '100vh' }}>
        <Text
          variant='text.h1'
          sx={{
            fontSize: [8, null, null, 10],
            mt: [13],
            mb: ['36px'],
            mx: [8],
          }}
        >
          Public Rec Reviews
        </Text>
        <Box css={yotpoCss}>
          <div id='yotpo-testimonials-custom-tab' />
        </Box>
      </FlexCol>
    </Container.Full>
  );
};

const yotpoCss = {
  width: '100%',
  maxWidth: '1156px',
  marginBottom: '32px',
  '.yotpo div, .yotpo span, .yotpo p, .yotpo a, .yotpo img, .yotpo i,.yotpo strong, .yotpo sup, .yotpo ul, .yotpo li, .yotpo form, .yotpo label': {
    fontFamily:
      'Messina Sans, Helvetica Neue, Helvetica, Arial, sans-serif !important',
    fontWeight: '400',
    '&:active': {
      outline: 0,
    },
    '&:focus': {
      outlineWidth: 0,
    },
    '&:focus-visible': {
      outlineWidth: 1,
    },
  },
  '.content-title, .avg-score': {
    color: '#141414 !important',
    fontWeight: '600 !important',
  },
  '.yotpo-nav-wrapper': {
    span: {
      color: '#141414 !important',
      fontWeight: '600 !important',
    },
  },
  '.yotpo-label': {
    display: 'none !important',
  },
  '.yotpo-popup-box-medium': {
    borderTopWidth: '0 !important',
  },
};

export default Reviews;
