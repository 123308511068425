/* eslint-disable react/prop-types */
/* eslint-disable max-lines */
/** @jsx jsx */
import { useState } from 'react';
import { jsx } from 'theme-ui';

import { Box, Flex, Text } from '@ripperoni/components';

import { UnitToggle } from './UnitToggle';

const renderTable = (tableData, units) => {
  return tableData ? (
    <table
      sx={{
        textAlign: 'center',
        width: '100%',
        borderCollapse: 'collapse',
        fontSize: [2, null, 3],
        'tr:nth-of-type(even)': {
          backgroundColor: 'gray.0',
        },
        'tr:first-of-type td': {
          fontWeight: 'semi',
        },
        'td:first-of-type': {
          fontWeight: 'semi',
        },
        td: {
          fontSize: 1,
          border: '1px solid',
          borderColor: 'gray.1',
          padding: ['10px 8px', null, '10px'],
        },
      }}
    >
      <tbody>
        {tableData.filter(Boolean).map((row, rowIndex) => {
          return (
            <tr key={`row-${rowIndex}`}>
              {row.filter(Boolean).map((col, colIndex) => {
                if (col === '-') return <td />;

                if (rowIndex !== 0 && colIndex !== 0) {
                  const [left, right] = col.split('-');

                  return (
                    <td key={`data-${colIndex}`}>
                      <span>
                        {units === 'in'
                          ? left?.trim()
                          : Math.round(left / 0.3937)}
                        {/* {units === 'in' ? '"' : 'cm'} */}
                      </span>
                      {' - '}
                      <span>
                        {units === 'in'
                          ? right?.trim()
                          : Math.round(right / 0.3937)}
                        {/* {units === 'in' ? '"' : 'cm'} */}
                      </span>
                    </td>
                  );
                }

                return <td key={`data-${colIndex}`}> {col} </td>;
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  ) : null;
};

export const CompleteSizeChart = ({ description, tableData, x, y, z }) => {
  const [units, setUnits] = useState('in');

  return (
    <Box>
      <Flex
        between
        mb={[5, null, null, null, 10]}
        flexDirection={['column', null, null, null, 'row']}
      >
        <Text mr={[0, null, null, null, 10]} mb={[6, null, null, null, 0]}>
          {description}
        </Text>

        <UnitToggle
          option1='in'
          option2='cm'
          onToggle={(option) => setUnits(option)}
        />
      </Flex>

      <Box
        pt='10px'
        sx={{
          overflowX: 'scroll',
          paddingBottom: '1px',
          '-ms-overflow-style': 'none',
          'scrollbar-width': 'none',
          '::-webkit-scrollbar': {
            display: 'none',
          },
        }}
      >
        <Box minWidth='500px'>{renderTable(tableData, units)}</Box>
      </Box>
    </Box>
  );
};
