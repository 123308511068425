import React from 'react'
import PropTypes from 'prop-types';

import { Box } from '@ripperoni/components';

import { TileSlider } from '@components/TileSlider';

export const ShopFeatureSlider = ({ children }) => {
  return (
    <Box
      bg='gray.0'
      py={[11, null, null, 15]}
    >
      <TileSlider
        slides={2}
        slideOnDesktop={true}
        arrows={true}
      >
        { children }
      </TileSlider>
    </Box>
  )
}

ShopFeatureSlider.propTypes = {

}
