import React from 'react';

import { useUIContext } from '@ripperoni/core';
import { Box, Button, Link, Text } from '@ripperoni/components';

export const CartEmptyState = (props) => {
  const { toggleCart } = useUIContext();

  return (
    <Box data-comp={CartEmptyState.displayName} p='16px' {...props}>
      <Text textAlign='center' fontSize='20px' mb='25px'>
        There are no items in your bag.
      </Text>

      <Button
        as={Link}
        variant='buttons.primary'
        sx={{
          width: '70%',
          margin: '0 auto',
          fontWeight: 'semi',
          fontSize: '16px',
          color: 'white',
          transition: 'color 300ms',
        }}
        to='/collections/men'
        onClick={() => toggleCart()}
      >
        Shop Men's
      </Button>
      <br />
      <br />
      <Button
        as={Link}
        variant='buttons.primary'
        sx={{
          width: '70%',
          margin: '0 auto',
          fontWeight: 'semi',
          fontSize: '16px',
          color: 'white',
          transition: 'color 300ms',
        }}
        to='/collections/women'
        onClick={() => toggleCart()}
      >
        Shop Women's
      </Button>
    </Box>
  );
};

CartEmptyState.displayName = 'Cart Empty State';
