import React, { useCallback, useState } from 'react';
import { subscribe } from 'klaviyo-subscribe';

import { isBrowser } from '@ripperoni/utilities';
import {
  Box,
  Button,
  Flex,
  FlexCol,
  Loader,
  Modal,
  Text,
} from '@ripperoni/components';

export const EmailSignUp = ({
  variantId,
  title,
  options,
  setEmailSubmitted,
}) => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [acceptedMarketing, setAcceptedMarketing] = useState(false);

  const onStockSubmit = useCallback(async () => {
    const data = `variant=${parseInt(
      variantId.split('/').pop()
    )}&email=${email}`;
    const url = `/api/back-in-stock?${data}`;

    setError('');
    setLoading(true);
    const response = await fetch(url);

    if (response.ok) {
      setLoading(false);
      setEmail('');
      setEmailSubmitted();
    } else {
      setLoading(false);
      setError('Error trying to submit your email. Please try again later.');
    }
  });

  const onMarketingSubmit = useCallback(async () => {
    if (!acceptedMarketing) {
      onStockSubmit();
      return;
    }

    subscribe('SfZzvC', email).then(async ({ data }) => {
      if (!data.is_subscribed || data.is_subscribed) {
        onStockSubmit();
      } else {
        setError('Error trying to submit your email. Please try again later.');
      }
    });
  });

  const handleSubmit = () => {
    if (!isBrowser || loading) return;
    const isValid = email.match(/^\S+@\S+\.\S+$/);
    if (!isValid) {
      setError('Please enter a valid email.');
      return;
    }
    onMarketingSubmit();
  };

  return (
    <FlexCol data-comp={EmailSignUp.displayName} sx={{ alignItems: 'center' }}>
      <Text
        variant='text.h3'
        mb={3}
        sx={{
          textAlign: 'center',
        }}
      >
        {title}
      </Text>

      <Text
        maxWidth='450px'
        mb={12}
        lineHeight='text'
        textAlign='center'
        sx={{
          span: {
            fontWeight: 'bold',
          },
        }}
      >
        Enter your email and we'll let you know when
        <Text
          dangerouslySetInnerHTML={{
            __html: '',
          }}
          display={['none', 'block']}
        />{' '}
        {!options.Size && !options.Color ? (
          'the product'
        ) : !options.Size ? (
          <>
            the product in <span fontWeight='bold'>{options?.Color}</span>
          </>
        ) : !options.Color ? (
          <span fontWeight='bold'>Size {options?.Size}</span>
        ) : (
          <>
            <span fontWeight='bold'>Size {options?.Size}</span> in{' '}
            <span fontWeight='bold'>{options?.Color}</span>
          </>
        )}{' '}
        is back in stock.
      </Text>

      <Flex
        sx={{
          width: '100%',
          maxWidth: '450px',
        }}
      >
        <Box
          as='input'
          type='email'
          value={email}
          onChange={(event) => setEmail(event.target.value)}
          placeholder='Your email'
          sx={{
            p: 6,
            flex: 2,
            minWidth: '100%',
            fontSize: 3,
            fontFamily: 'text',
            '&::placeholder': {
              fontFamily: 'text',
              fontWeight: 300,
            },
          }}
        />
      </Flex>

      <Flex
        sx={{
          justifyContent: 'center',
          mt: 5,
          mb: 12,
        }}
      >
        <Box
          as='input'
          id='back-in-stock-email-checkbox'
          type='checkbox'
          onChange={() => {
            const checked = document.getElementById(
              'back-in-stock-email-checkbox'
            ).checked;
            setAcceptedMarketing(checked ? true : false);
          }}
          sx={{
            mr: 2,
            mt: 1,
          }}
        />
        <Text text="Sign up for Public Rec's email newsletter." />
      </Flex>

      <Button
        aria-label='Submit Back In Stock'
        sx={{
          width: '100%',
          maxWidth: '356px',
          fontSize: 3,
          height: '46px',
          py: 0,
          cursor: !loading ? 'pointer' : 'auto',
          '&:hover': {
            bg: !loading ? null : 'black',
          },
        }}
        onClick={handleSubmit}
      >
        {!loading ? (
          'Submit'
        ) : (
          <Loader
            sx={{
              position: 'block',
              height: '100%',
              color: 'white',
              my: 0,
              py: 3,
            }}
          />
        )}
      </Button>

      <Box mt={3}>
        <Text
          sx={{
            fontSize: 3,
            color: 'error',
            textAlign: 'center',
          }}
        >
          {error}
        </Text>
      </Box>
    </FlexCol>
  );
};

EmailSignUp.displayName = 'EmailSignUp';
