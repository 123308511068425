import React from 'react';

import { Box } from '@ripperoni/components';


export const ActiveIcon = props => {
  return (
    <Box
      width='15px'
      transform='rotate(-135deg)'
      {...props}
    >
      &#8735;
    </Box>
  );
};
