import React, { useEffect, useRef } from 'react';
import mapboxgl from 'mapbox-gl';

const Marker = ({ map, location }) => {
  const markerRef = useRef();

  useEffect(() => {
    const marker = new mapboxgl.Marker({ color: 'black' })
      .setLngLat([location.longitude, location.latitude])
      .addTo(map)
      .setPopup(
        new mapboxgl.Popup().setHTML(
          '<div style="line-height: .55; outline: none !important">' +
            '<p style="line-height: 1.25; font-weight: bold; font-size: 1.5em">' +
            location.title +
            '<div style="line-height: .55; outline: none !important">' +
            '<p style="line-height: 1.25; font-weight: bold; font-size: 1.3em">' +
            location.name +
            '</p>' +
            '<p style="font-size: .9em">' +
            location.address1 +
            '</p>' +
            '<p style="font-size: .9em">' +
            location.city +
            ', ' +
            location.stateAbbrv +
            ' ' +
            location.zipCode +
            '</p>' +
            '<hr>' +
            '<p style="line-height: .5; font-weight: bold; font-size: 1em">' +
            'Store Hours:' +
            '</p>' +
            '<p style="line-height: .25;font-size: .9em">' +
            'Mon-Sat: ' +
            location.normalHrs +
            '</p>' +
            '<p style="line-height: .25;font-size: .9em">' +
            'Sun: ' +
            location.sundayHrs +
            '</p>' +
            '<p style="font-size: .9em">' +
            '<p style="font-size: .9em">' +
            location.phone +
            '</p>' +
            '</div>'
        )
      );

    return () => marker.remove();
  });

  return <div ref={markerRef} />;
};

const Markers = ({ map, locations }) => {
  return (
    <>
      {locations &&
        locations.map((location) => (
          <Marker key={location.name} map={map} location={location} />
        ))}
    </>
  );
};

export default Markers;
