/**
 * @prettier
 */
import React from 'react';
import PropTypes from 'prop-types';

import { Box, CmsContent, Flex } from '@ripperoni/components';

export const SizingBox = ({
  title,
  measurement1,
  measurement2,
  cta,
  ...props
}) => {
  return (
    <Box {...props}>
      <CmsContent
        pb={8}
        mb={8}
        borderBottom='1px solid'
        borderColor='gray.2'
        content={title}
      />

      <Flex between pb={8} mb={8} borderBottom='1px solid' borderColor='gray.2'>
        {measurement1[0]}
        {measurement1[1]}
      </Flex>

      <Flex between pb={8} mb={8} borderBottom='1px solid' borderColor='gray.2'>
        {measurement2[0]}
        {measurement2[1]}
      </Flex>

      <CmsContent
        mt={10}
        width='full'
        textTransform='uppercase'
        content={cta}
      />
    </Box>
  );
};

SizingBox.displayName = 'Sizing Box';

SizingBox.propTypes = {
  title: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  measurement1: PropTypes.array,
  measurement2: PropTypes.array,
  cta: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};
