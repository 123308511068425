/* eslint-disable react/prop-types */
/* eslint-disable max-lines */
/** @jsx jsx */
import { useState } from 'react';
import { jsx } from 'theme-ui';
import { Link } from 'gatsby';

import { Box, Flex, Image, Text } from '@ripperoni/components';
import { useUIContext } from '@ripperoni/core';

import { AccordionItem } from '@components/AccordionItem';

import { QuickSizeChart } from './QuickChart';
import { CompleteSizeChart } from './CompleteChart';

export const FullSizeGuideTable = ({ data, type }) => {
  const { toggleModal } = useUIContext();
  const [activeAcc, setActiveAcc] = useState(type);

  return (
    <Box
      py={[10, null, null, null, 12]}
      px={[10, null, null, null, 13]}
      position='relative'
    >
      <Box
        onClick={toggleModal}
        display='block'
        fontSize='30px'
        textAlign='right'
        sx={{
          cursor: 'pointer',
          position: 'absolute',
          top: 0,
          right: '8px',
        }}
      >
        &times;
      </Box>

      <Flex
        middle
        between
        flexDirection={['column', null, null, null, 'row']}
        alignItems={['flex-start', 'between']}
        mb={6}
      >
        <Text variant='text.h3'>{data.sizeGuideTitle}</Text>

        <Text fontSize={1}>
          Need Help?&nbsp;
          <Link
            to='https://support.publicrec.com/hc/en-us/requests/new'
            target='_blank'
            variant='a'
            sx={{
              color: 'black',
              fontSize: 1,
            }}
          >
            Contact Us
          </Link>
        </Text>
      </Flex>

      <Box>
        {data.quickSizeChartDescription && (
          <AccordionItem
            grouped={true}
            open={activeAcc === 'quick-chart'}
            onClick={() => setActiveAcc('quick-chart')}
            title='Quick Size Charts'
            description={
              <QuickSizeChart
                description={
                  data.quickSizeChartDescription.quickSizeChartDescription
                }
                setActiveAcc={setActiveAcc}
                table1={data.quickSizeChart1}
                table2={data.quickSizeChart2}
              />
            }
          />
        )}

        {data.seeItInOtherSizes && (
          <AccordionItem
            grouped={true}
            open={activeAcc === 'other-sizes'}
            onClick={() => setActiveAcc('other-sizes')}
            title='See it in Other Sizes'
            description={<Image.Contentful {...data.seeItInOtherSizes} />}
          />
        )}

        {data.howToTakeYourMeasurements && (
          <AccordionItem
            grouped={true}
            open={activeAcc === 'how-to-measurements'}
            onClick={() => setActiveAcc('how-to-measurements')}
            title='How to Take Your Measurements'
            description={
              <Image.Contentful {...data.howToTakeYourMeasurements} />
            }
          />
        )}

        {data.completeSizeGuideChart && (
          <AccordionItem
            grouped={true}
            open={activeAcc === 'complete-chart'}
            onClick={() => setActiveAcc('complete-chart')}
            title='Complete Size Chart'
            description={
              <CompleteSizeChart
                description={
                  data.completeSizeChartDescription.completeSizeChartDescription
                }
                tableData={data.completeSizeGuideChart.tableData}
                x={data.completeSizeGuideChartXAxis}
                y={data.completeSizeGuideChartYAxis}
                z={data.completeSizeGuideChartZAxis}
              />
            }
          />
        )}
      </Box>
    </Box>
  );
};
