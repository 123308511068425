import React from 'react';
import PropTypes from 'prop-types';

import { CmsContent, Container, Grid, Heading, Text } from '@ripperoni/components';


export const FabricInfo = ({
  heading,
  text,
  fabricBoxes
}) => {
  return (
    <Container.Full
      bg='white'
      px={8}
      py={[11, null, null, null, 15]}
    >
      <Container>
        <CmsContent
          as={Heading}
          mb={5}
          textAlign={['left', null, null, 'center']}
          content={heading}
        />

        <CmsContent
          as={Text}
          maxWidth='920px'
          mx='auto'
          mb={13}
          textAlign={['left', null, null, 'center']}
          content={text}
        />

        <Grid
          mt={7}
          columns={['1fr', null, null, '1fr 1fr']}
          columnGap={9}
          rowGap={9}
          maxWidth='920px'
          mx='auto'
        >
          {
            fabricBoxes.map(tile => {
              return (
                <CmsContent
                  key={tile.key}
                  content={tile}
                />
              );
            })
          }
        </Grid>
      </Container>
    </Container.Full>
  );
};

FabricInfo.propTypes = {
  heading: PropTypes.array,
  text: PropTypes.array,
  fabricBoxes: PropTypes.array,
};

export default FabricInfo;
