import { graphql, useStaticQuery } from 'gatsby';

export default function isRetired() {
  const data = useStaticQuery(
    graphql`
      query {
        googleSheet {
          Retired {
            foreignId
          }
        }
      }
    `
  );

  const retiredProducts = data.googleSheet.Retired.map((node) => {
    const { foreignId } = node;
    return {
      foreignId,
    };
  });
  return {
    retiredProducts,
  };
}
