/**
 * @prettier
 */
/** @jsx jsx */
import React, { memo } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { jsx } from 'theme-ui';

import { Box, Container } from '@ripperoni/components';
import { ContentfulContent } from '@ripperoni/cms';

// import { TestimonialMediaBlock } from '@components/TestimonialMediaBlock';
import { TestimonialVideo } from '@components/TestimonialVideo';

const TestimonialsPage = memo((props) => {
  const { pageContainer } = useStaticQuery(TestimonialsStaticQuery);

  return (
    <Container.Full>
      <TestimonialVideo />
      <ContentfulContent {...pageContainer} />
    </Container.Full>
  );
});

export default TestimonialsPage;

const TestimonialsStaticQuery = graphql`
  {
    pageContainer: contentfulPageContainer(slug: { eq: "/testimonial" }) {
      ...ContentfulPageContainer
    }
  }
`;
