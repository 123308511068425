/**
 * @jsx jsx
 */
/* eslint-disable max-lines */
import React, { useEffect, useState } from 'react';
import { jsx } from 'theme-ui';
import { Helmet } from 'react-helmet';
import { btoa } from 'abab';
import handleize from 'lodash/kebabCase';
import fetch from 'isomorphic-fetch';

import { isBrowser } from '@ripperoni/utilities';
import {
  Box,
  Button,
  Container,
  Flex,
  Heading,
  Link,
  Price,
  Text,
} from '@ripperoni/components';
import { useUIContext } from '@ripperoni/core';
import { useAddItemToCart } from '@ripperoni/cart';
import { useInventory } from '@ripperoni/store';

import ProductImages from '@components/ProductImages';
import { useProduct } from '@components/ProductContext';
import { BackInStockModal } from '@components/BackInStockModal';
import { ProductOptions } from '@components/ProductOptions';
import { ProductStars as YotpoProductStars } from '@components/YotpoProductReviews';
import { ModelVariantToggle } from '@components/ModelVariantToggle';

import isNotOnline from '../../hooks/isNotOnline';
import isSeasonal from '../../hooks/isSeasonal';
import isRetired from '../../hooks/isRetired';
const breadcrumbs = (metadata) => {
  const { gender, superclass, subclass } = metadata;
  const breadcrumbs = [gender, superclass, subclass]
    .filter((crumb) => crumb)
    .map((crumb) => handleize(crumb));

  const bcPaths = breadcrumbs.reduce(
    (acc, curr, index) => {
      if (index < breadcrumbs.length - 1) {
        const next = [acc[index], breadcrumbs[index + 1]].join('-');
        console.log('heres the breadcrumbs', breadcrumbs[2]);
        acc = [...acc, next];
      }

      return acc;
    },
    [breadcrumbs[0]]
  );

  return breadcrumbs.map((handle, index) => {
    return (
      <React.Fragment key={`${index}.${Math.random()}`}>
        <Link
          to={`/collections/${bcPaths[index]}`}
          textDecoration='none'
          color='gray.2'
          variant='a'
          sx={{
            // display: index === 0 ? 'none' : 'inline-block',
            fontWeight: 'regular',
            fontSize: [0, null, null, null, 2],
            ':hover': {
              textDecoration: 'underline',
            },
          }}
        >
          {handle.slice(0) === 'womens-bags'
            ? handle.slice(0).replace('womens-bags', 'Bags')
            : handle.slice(1) === 'utton-downs'
            ? handle.charAt(0).toUpperCase() + 'utton Downs'
            : handle.charAt(0).toUpperCase() + handle.slice(1)}

          {/* {handle.charAt(0).toUpperCase() +
            handle.slice(1)} */}
        </Link>

        <Text as='span' color='gray.2'>
          {' / '}
        </Text>
      </React.Fragment>
    );
  });
};

const ProductMain = ({ sizeGuideId }) => {
  const { productState } = useProduct();
  const addItemToCart = useAddItemToCart();
  const { toggleCart, toggleModal } = useUIContext();
  const { product, selectedVariant } = productState;
  const { title, handle, descriptionHtml, metadata, type } = product || {};
  const { gender, superclass } = metadata || {};
  const { id, foreignId, selectedOptionsMap } = selectedVariant || {};
  const liveInventory = useInventory(id || '');
  console.log('liveInventory', liveInventory);

  // const [liveInventory, setLiveInventory] = useState();
  // const [isInventoryLoading, setIsInventoryLoading] = useState(false);
  // useEffect(() => {
  //   setIsInventoryLoading(true);
  //   const fetchInventory = async () => {
  //     const res = await fetch(`/api/inventory?id=${encodedForeignId}`, {
  //       method: 'POST',
  //     });
  //     const json = await res.json();

  //     console.log('Res:', res);
  //     setLiveInventory({
  //       inventory: json.data.productVariant.inventory,
  //       available: json.data.productVariant.available,
  //     });
  //     setIsInventoryLoading(false);
  //   };
  //   fetchInventory();
  // }, [selectedVariant]);

  const handleBackInStock = () => toggleModal('back-in-stock-modal');
  const handleAddToCart = () => {
    const quantity = 1;
    const variantId = btoa(foreignId);
    const customAttributes = [
      {
        key: '_url', // Tip: _ prefix hides it at checkout
        value: window.location.pathname + window.location.search,
      },
      {
        key: '_addedAt', // Tip: _ prefix hides it at checkout
        value: `${Date.now()}`,
      },
    ];

    toggleCart();
    addItemToCart(variantId, quantity, customAttributes);
  };

  const { retiredProducts } = isRetired();
  const allRetiredIds = retiredProducts.map((a) => a.foreignId);
  const trimmedId = parseInt(
    selectedVariant.productForeignId.toString().replace(/[^0-9]/g, '')
  );

  const { seasonalProducts } = isSeasonal();
  const selectedLimitedColors = seasonalProducts.filter(
    (a) => a.title === product.title
  )[0];
  const archiveSale = selectedLimitedColors?.colors.includes(
    selectedVariant.selectedOptionsMap.Color
  );
  const archiveSaleCallout = selectedLimitedColors?.callout;
  const { notOnlineProducts } = isNotOnline();
  const seletecNotOnlineProducts = notOnlineProducts.filter(
    (a) => a.sku === selectedVariant.sku
  )[0];

  const cancelATC = seletecNotOnlineProducts?.sku?.includes(
    selectedVariant.sku
  );
  console.log('this variant options', archiveSaleCallout?.includes('Sale'));

  console.log('Notify', liveInventory);
  console.log('Notify', selectedVariant);
  return (
    <>
      <Helmet>
        <meta
          content={allRetiredIds.includes(trimmedId) ? 'retired' : 'active'}
          name='isRetired'
        />
      </Helmet>
      <Container.Full data-comp={ProductMain.displayName}>
        {/* {console.log('Answer to back in stock', backInStockSku)}
      {console.log('SKU query', selectedVariant.sku)} */}
        <Container
          pt={[4, null, null, null, 12]}
          px={[0, null, null, null, 8]}
          pb={12}
        >
          <Box
            pt={[4, null, null, null, 0]}
            pb={8}
            px={[8, null, null, null, 0]}
            display={['none', null, null, null, 'block']}
          >
            {breadcrumbs(metadata)}

            <Text
              display='inline'
              fontWeight='regular'
              fontSize={[0, null, null, null, 2]}
            >
              {title === 'Workday Pant'
                ? 'Workday Pant 2.0'
                : title === 'Workday Short'
                ? 'Workday Short 2.0'
                : title}
            </Text>
          </Box>

          <Flex
            alignItems={['center', null, null, null, 'flex-start']}
            flexDirection={['column', null, null, null, 'row']}
          >
            <Box
              flexBasis='75%'
              position='relative'
              width={['100%', null, null, null, 'auto']}
              maxWidth={['100%', null, null, null, '756px']}
              mb={[4, null, null, null, 0]}
            >
              <ProductImages
                product={product}
                selectedVariant={selectedVariant}
              />
            </Box>

            <Box
              flexBasis='25%'
              minWidth={['100%', null, null, null, '350px']}
              width='100%'
              ml={[0, null, null, null, 14, null, 16]}
              sx={{
                position: [null, null, null, null, 'sticky'],
                top: '110px',
                height: 'auto',
              }}
            >
              <Box mb={8} px={[8, null, null, null, 0]}>
                {archiveSaleCallout?.includes('Sale') && archiveSale === true && (
                  <Text
                    sx={{
                      border: '1px solid #9E3838',
                      backgroundColor: '#9D3838',
                      borderRadius: '30px',
                      fontWeight: '600',
                      fontSize: '11px',
                      lineHeight: '20px',
                      textAlign: 'center',
                      color: '#FFFFFF',
                      px: 3,
                      py: '2px',
                      mx: 0,
                      my: 2,
                      width: 90,
                    }}
                  >
                    Final Sale
                  </Text>
                )}
                {title.includes('Workday Pant Slim') && (
                  <Text
                    sx={{
                      border: '1px solid #9E3838',
                      backgroundColor: '#9D3838',
                      borderRadius: '30px',
                      fontWeight: '600',
                      fontSize: '11px',
                      lineHeight: '20px',
                      textAlign: 'center',
                      color: '#FFFFFF',
                      px: 3,
                      py: '2px',
                      mx: 0,
                      my: 2,
                      width: 90,
                    }}
                  >
                    Final Sale
                  </Text>
                )}
                {title.includes('Workday Pant Straight') && (
                  <Text
                    sx={{
                      border: '1px solid #9E3838',
                      backgroundColor: '#9D3838',
                      borderRadius: '30px',
                      fontWeight: '600',
                      fontSize: '11px',
                      lineHeight: '20px',
                      textAlign: 'center',
                      color: '#FFFFFF',
                      px: 3,
                      py: '2px',
                      mx: 0,
                      my: 2,
                      width: 90,
                    }}
                  >
                    Final Sale
                  </Text>
                )}

                {title.includes('Performance Polo') && (
                  <Text
                    sx={{
                      border: '1px solid #9E3838',
                      backgroundColor: '#9D3838',
                      borderRadius: '30px',
                      fontWeight: '600',
                      fontSize: '11px',
                      lineHeight: '20px',
                      textAlign: 'center',
                      color: '#FFFFFF',
                      px: 3,
                      py: '2px',
                      mx: 0,
                      my: 2,
                      width: 90,
                    }}
                  >
                    Final Sale
                  </Text>
                )}
                {title.includes('Archive') && (
                  <Text
                    sx={{
                      border: '1px solid #9E3838',
                      backgroundColor: '#9D3838',
                      borderRadius: '30px',
                      fontWeight: '600',
                      fontSize: '11px',
                      lineHeight: '20px',
                      textAlign: 'center',
                      color: '#FFFFFF',
                      px: 3,
                      py: '2px',
                      mx: 0,
                      my: 2,
                      width: 90,
                    }}
                  >
                    Final Sale
                  </Text>
                )}

                <Heading as='h1' fontSize='20px' mb={2}>
                  {title === 'Workday Pant'
                    ? 'Workday Pant 2.0'
                    : title === 'Workday Short'
                    ? 'Workday Short 2.0'
                    : title
                    ? title.includes('Archive')
                      ? title.replace('Archive', '')
                      : title
                    : title}
                </Heading>

                {isBrowser && window?.GlobalE?.Country === 'US' ? (
                  <>
                    <section
                      key='price-container'
                      style={{
                        display: 'flex',
                        fontWeight: '400',
                        fontSize: '20px',
                        lineHeight: '1.125',
                        marginBottom: '5px',
                      }}
                    >
                      {selectedVariant?.compareAtPrice ? (
                        <>
                          <div
                            key='price-1'
                            style={{
                              color: '#4F4F4F',
                              textDecoration: 'line-through',
                              fontWeight: '400',
                              marginRight: '10px',
                            }}
                          >
                            ${Math.round(selectedVariant.compareAtPrice / 100)}
                          </div>

                          <div
                            id='sezzle-price'
                            sx={{
                              color: '#9E3838',
                            }}
                            key='price-2'
                          >
                            ${Math.round(selectedVariant.price / 100)}
                          </div>
                        </>
                      ) : (
                        <div id='sezzle-price' key='price-3'>
                          ${selectedVariant?.price / 100}
                        </div>
                      )}
                    </section>
                    <YotpoProductStars />
                  </>
                ) : (
                  <>
                    {selectedVariant?.compareAtPrice ? (
                      <>
                        <Flex
                          sx={{
                            display: 'flex',
                            marginBottom: '5px',
                          }}
                        >
                          <Price
                            mr={'10px'}
                            sx={{
                              color: '#4F4F4F',
                              textDecoration: 'line-through',
                              fontWeight: '400',
                              fontSize: '20px',
                              lineHeight: '1.125',
                            }}
                          >
                            {Math.round(selectedVariant.compareAtPrice / 100)}
                          </Price>
                          <Price
                            sx={{
                              color: '#9E3838',
                              fontWeight: '400',
                              fontSize: '20px',
                              lineHeight: '1.125',
                            }}
                            mr={1}
                          >
                            {Math.round(selectedVariant.price / 100)}
                          </Price>
                        </Flex>
                        <YotpoProductStars />
                      </>
                    ) : (
                      <>
                        <Price
                          id='sezzle-price'
                          sx={{
                            fontWeight: '400',
                            fontSize: '20px',
                            lineHeight: '1.125',
                          }}
                        >
                          {Math.round(selectedVariant.price / 100)}
                        </Price>
                        <YotpoProductStars />
                      </>
                    )}
                  </>
                )}

                {/* <AfterPay selectedVariant={selectedVariant} /> */}
                {/* <YotpoProductStars /> */}

                <Text
                  mt={7}
                  fontSize={'16px'}
                  dangerouslySetInnerHTML={{ __html: descriptionHtml }}
                />
                <br></br>
                {archiveSaleCallout?.includes('Sale') && archiveSale === true && (
                  <Text fontSize={'12px'} color='grey.2' fontStyle='italic'>
                    This product is final sale and cannot be returned or
                    exchanged after purchase.
                  </Text>
                )}
              </Box>
              <ProductOptions sizeGuideId={sizeGuideId} />

              <Box px={[8, null, null, null, 0]}>
                {/*
                If the inventory is negative and there's a tag to enable preorder (preorder | ship date), then Add to Cart should read "Pre-order"
              */}
                {liveInventory?.loading ? (
                  <>
                    <Button
                      variant='buttons.cart'
                      // onClick={handleAddToCart}
                      sx={{
                        width: '100%',
                        // textTransform: 'uppercase',
                        fontWeight: 'semi',
                        color: 'white',
                        transition: 'color 100ms',
                      }}
                    >
                      Add to Bag
                    </Button>
                  </>
                ) : cancelATC ? (
                  <>
                    <Button
                      variant='buttons.secondary'
                      onClick={handleBackInStock}
                      sx={{
                        width: '100%',
                        // textTransform: 'uppercase',
                        fontWeight: 'semi',
                      }}
                    >
                      Notify Me When Available
                    </Button>
                  </>
                ) : liveInventory?.available &&
                  liveInventory?.inventory <= 0 &&
                  metadata.preorder ? (
                  <>
                    <Button
                      variant='buttons.cart'
                      onClick={handleAddToCart}
                      sx={{
                        width: '100%',
                        textTransform: 'uppercase',
                        fontWeight: 'semi',
                      }}
                    >
                      Pre-Order
                    </Button>
                    <Text textAlign='center' mt={2} fontSize={2} color='error'>
                      Expected to ship within 7-10 days
                    </Text>
                  </>
                ) : liveInventory?.inventory <= 0 &&
                  title.includes('Workday Pant Slim') ? (
                  <>
                    <Button
                      mb={8}
                      style={{ cursor: 'not-allowed' }}
                      variant='buttons.disabled'
                      sx={{
                        backgroundColor: '#C4C4C4',
                        color: '#FFFFFF',
                        width: '100%',
                        fontWeight: 'semi',
                        py: '15px',
                      }}
                    >
                      No Longer Available
                      <Text
                        px='20px'
                        fontSize='12px'
                        position='absolute'
                        my='20px'
                      >
                        This product is discontinued and will not be restocked.
                      </Text>
                    </Button>
                  </>
                ) : liveInventory?.inventory <= 0 &&
                  title.includes('Workday Pant Straight') ? (
                  <>
                    <Button
                      mb={8}
                      style={{ cursor: 'not-allowed' }}
                      variant='buttons.disabled'
                      sx={{
                        backgroundColor: '#C4C4C4',
                        color: '#FFFFFF',
                        width: '100%',
                        fontWeight: 'semi',
                        py: '15px',
                      }}
                    >
                      No Longer Available
                      <Text
                        px='20px'
                        fontSize='12px'
                        position='absolute'
                        my='20px'
                      >
                        This product is discontinued and will not be restocked.
                      </Text>
                    </Button>
                  </>
                ) : // ARCHIVE SALE Button
                // ) : liveInventory?.inventory <= 0 && archiveSale ? (
                //   <>
                //     <Button
                //       mb={8}
                //       style={{ cursor: 'not-allowed' }}
                //       variant='buttons.disabled'
                //       sx={{
                //         backgroundColor: '#C4C4C4',
                //         color: '#FFFFFF',
                //         width: '100%',
                //         fontWeight: 'semi',
                //         py: '15px',
                //       }}
                //     >
                //       No Longer Available
                //       <Text
                //         px='20px'
                //         fontSize='12px'
                //         position='absolute'
                //         my='20px'
                //       >
                //         This product is discontinued and will not be restocked.
                //       </Text>
                //     </Button>
                //   </>
                liveInventory?.inventory <= 0 && title.includes('Archive') ? (
                  <>
                    <Button
                      style={{ cursor: 'not-allowed' }}
                      variant='buttons.disabled'
                      sx={{
                        backgroundColor: '#C4C4C4',
                        color: '#FFFFFF',
                        width: '100%',
                        fontWeight: 'semi',
                        py: '15px',
                      }}
                    >
                      No Longer Available
                      <Text
                        px='20px'
                        fontSize='12px'
                        position='absolute'
                        my='20px'
                      >
                        This product is discontinued and will not be restocked.
                      </Text>
                    </Button>
                  </>
                ) : liveInventory?.inventory > 0 ||
                  handle.includes('gift-card') ? (
                  <>
                    <Button
                      variant='buttons.cart'
                      onClick={handleAddToCart}
                      sx={{
                        width: '100%',
                        // textTransform: 'uppercase',
                        fontWeight: 'semi',
                        color: 'white',
                        transition: 'color 100ms',
                      }}
                    >
                      Add to Bag
                    </Button>
                  </>
                ) : (
                  <>
                    <Button
                      variant='buttons.secondary'
                      onClick={handleBackInStock}
                      sx={{
                        width: '100%',
                        // textTransform: 'uppercase',
                        fontWeight: 'semi',
                      }}
                    >
                      Notify Me When Available
                    </Button>
                  </>
                )}

                {isBrowser && window?.GlobalE?.Country === 'US' ? (
                  <Box
                    key='shipping-notice-container'
                    mt={9}
                    mb={7}
                    pb={8}
                    borderBottom='thin solid'
                    borderColor='gray.1'
                  >
                    <p
                      key='shipping-notice'
                      sx={{
                        fontSize: 2,
                        mb: 1,
                        fontWeight: 'semi',
                      }}
                    >
                      Free shipping on orders over $75&nbsp;
                      <Link
                        to='https://publicrec.kustomer.help/en_us/categories/shipping-tracking-SJNRNCmgc'
                        target='_blank'
                        aria-label='Free Shipping Information (opens in a new tab)'
                        variant='a'
                        sx={{
                          color: 'black',
                          fontSize: 2,
                          borderBottom: '1px solid black',
                        }}
                      >
                        Learn More
                      </Link>
                    </p>
                  </Box>
                ) : (
                  <>
                    <br />
                  </>
                )}

                <ModelVariantToggle
                  type={type}
                  product={product}
                  selectedOptionsMap={selectedOptionsMap}
                />

                {metadata?.model &&
                  metadata.title !== 'Workday Pant 2.0' &&
                  metadata.title !== 'Workday Pant' && (
                    <>
                      <Text
                        fontSize={2}
                        color='gray.2'
                        fontWeight='regular'
                        mb={1}
                      >
                        {metadata?.model}
                      </Text>
                    </>
                  )}
              </Box>
            </Box>
          </Flex>
          <BackInStockModal
            variantId={foreignId}
            selectedVariant={selectedVariant}
            title={title}
            options={selectedOptionsMap}
          />
        </Container>
      </Container.Full>
    </>
  );
};

ProductMain.displayName = 'ProductMain';

export default ProductMain;
