/* eslint-disable import/no-default-export */
import deepmerge from 'deepmerge';

import { theme as coreTheme } from '@ripperoni/core';
import { theme as cartTheme } from '@ripperoni/cart';
import { theme as accountTheme } from '@ripperoni/account';
import { theme as componentsTheme } from '@ripperoni/components';

import { account } from '../theme/account';
import { borders } from '../theme/borders';
import { borderStyles } from '../theme/borderStyles';
import { borderWidths } from '../theme/borderWidths';
import { components } from '../theme/components';
import { fonts } from '../theme/fonts';
import { forms } from '../theme/forms';
import { layout } from '../theme/layout';
import { letterSpacings } from '../theme/letterSpacings';
import { opacities } from '../theme/opacities';
import { pages } from '../theme/pages';
import { styles } from '../theme/styles';
import { zIndices } from '../theme/zIndices';
import buttons from '../theme/buttons.json';
import colors from '../theme/colors.json';
import fontSizes from '../theme/fontSizes.json';
import fontWeights from '../theme/fontWeights.json';
import lineHeights from '../theme/lineHeights.json';
import links from '../theme/links.json';
import radii from '../theme/radii.json';
import shadows from '../theme/shadows.json';
import sizes from '../theme/sizes.json';
import space from '../theme/space.json';
import text from '../theme/text.json';

const mergedRipperoniThemes = deepmerge.all([
  coreTheme,
  cartTheme,
  accountTheme,
  componentsTheme,
]);

const theme = {
  account,
  borders,
  borderStyles,
  borderWidths,
  buttons,
  colors,
  components,
  fonts,
  fontSizes,
  fontWeights,
  forms,
  layout,
  letterSpacings,
  lineHeights,
  links,
  opacities,
  pages,
  radii,
  shadows,
  sizes,
  space,
  styles,
  text,
  zIndices,
};

const arrayMerge = (destination, source) => {
  return Object.keys(source).length ? source : destination;
};

const mergedThemes = deepmerge.all([mergedRipperoniThemes, theme], {
  arrayMerge,
});

// console.log('mergedThemes', mergedThemes);

export default mergedThemes;
