/**
 * @prettier
 */
import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import { Box, Container, Flex, Heading, Text } from '@ripperoni/components';

import { FooterLinkBlocks } from './FooterLinkBlocks';
import { FooterEmailSignup } from './FooterEmailSignup';

export const Footer = (props) => {
  const { linkBlocks } = useStaticQuery(staticQuery);

  return (
    <Box bg='primary'>
      <Container
        as={Flex}
        py={[12, null, null, 13, 15]}
        px={[9]}
        sx={{
          flexDirection: ['column', null, null, 'row'],
          justifyContent: 'space-between',
        }}
      >
        <Box as={Flex} sx={{ flexDirection: ['column', null, null, 'row'] }}>
          <FooterLinkBlocks linkBlocks={linkBlocks} />
        </Box>

        <FooterEmailSignup />
      </Container>

      <Box borderTop='1px solid' borderColor='gray.3'>
        <Container pt={[3, null, null, 4]} px={[9]} pb={[4, null, null, 7]}>
          <Text
            color='secondary'
            fontSize={['12px', null, null, '10px']}
            fontWeight='semi'
            textAlign={['center', null, null, 'left']}
          >
            © {new Date().getFullYear()} PUBLIC REC. ALL RIGHTS RESERVED.
          </Text>
          <Heading.h6 />
          <Text
            color='secondary'
            fontSize={['12px', null, null, '10px']}
            fontWeight='semi'
            textAlign={['center', null, null, 'left']}
          >
            <a
              className='truevault-polaris-dns-link'
              href='https://privacy.publicrec.com/opt-out'
              noreferrer
              noopener
            >
              Do Not Sell or Share My Personal Information
            </a>
          </Text>
        </Container>
      </Box>
    </Box>
  );
};

const staticQuery = graphql`
  {
    linkBlocks: allContentfulMolecule(
      filter: { metaTags: { in: ["footer"] } }
    ) {
      nodes {
        ...ContentfulMolecule
      }
    }
  }
`;
