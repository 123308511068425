import React from 'react';
import PropTypes from 'prop-types';

import { Box, CmsContent, Container, Grid, Image, Text, } from '@ripperoni/components';

export const FeatureImageProducts = (props) => {
  const { grids, slotsNodes } = props.children.props;

  return (
    <Container.Large>
        <Grid
          px={8}
          py={[11, null, null, 15]}
          columns={['1fr 1fr', null, null, '1fr 1fr 1fr']}
          rows={['repeat(2, auto)', null, null, 'repeat(1, auto)']}
          columnGap={8}
          alignItems='center'
          justifyItems='center'
          areas={[
            `
              'A A'
              'B C'
            `,
            null,
            null,
            (grids && grids[0]) ? grids[0].grid : `'A' 'products' 'products'`
          ]}
        >

          {
            slotsNodes.map( node => {
              if (node.props.gridArea === 'A') {
                return (
                  <Box
                    key={Math.random()}
                    maxWidth={[null, null, null, '472px']}
                    width='100%'
                    gridArea='A'
                  >
                    <CmsContent content={node.props.children} />

                    <Text
                      display={['block', null, null, 'none']}
                      variant='text.h3'
                      textAlign='center'
                      py={8}
                    >
                      Shop The Look
                    </Text>
                  </Box>
                );
              }

              return (
                node.props.children.map( product => {
                  return (
                    <CmsContent
                    key={Math.random()}
                      content={product}
                      maxWidth='370px'
                      width='100%'
                    />
                  );
                })
              );
            })
          }
        </Grid>
      </Container.Large>
  )
}

FeatureImageProducts.propTypes = {

}
