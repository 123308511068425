/** @jsx jsx */
import React from 'react';
import { jsx } from 'theme-ui';
import PropTypes from 'prop-types';
import { AnimatePresence } from 'framer-motion';

import { useUIContext } from '@ripperoni/core';
import { Box, Overlay } from '@ripperoni/components';

export const Modal = ({ modalId, hideClose = false, children }) => {
  const { state: uiState, toggleModal } = useUIContext();

  let id = false;
  let type = false;

  if (uiState.modal) {
    [id, type] = uiState.modal && uiState.modal.split('::');
  }

  Modal.propTypes = {
    modalId: PropTypes.string.isRequired,
    children: PropTypes.any,
  };

  return id === modalId ? (
    <React.Fragment>
      <AnimatePresence>
        {uiState.modal && <Overlay onClick={toggleModal} />}
      </AnimatePresence>

      <Box
        px='20px'
        py='80px'
        position='fixed'
        top='50%'
        left='50%'
        transform='translate(-50%, -50%)'
        zIndex='2'
        overflowY='scroll'
        maxWidth='960px'
        maxHeight='100vh'
        width='100%'
        sx={{
          '-ms-overflow-style': 'none',
          'scrollbar-width': 'none',
          '::-webkit-scrollbar': {
            display: 'none',
          },
        }}
      >
        {!hideClose && (
          <Box
            onClick={toggleModal}
            display='block'
            fontSize='48px'
            textAlign='right'
            sx={{ cursor: 'pointer' }}
          >
            &times;
          </Box>
        )}

        <Box bg='white' width='100%' height='100%' zIndex={3}>
          {React.Children.map(children, (child) => {
            if (React.isValidElement(child)) {
              return React.cloneElement(child, { type });
            }
            return child;
          })}
        </Box>
      </Box>
    </React.Fragment>
  ) : null;
};
